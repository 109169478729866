import styled from '@emotion/styled'

import entranceBg from '@/assets/entrance/entrance-bg.jpg'

export const Main = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
`

export const Content = styled.div`
	flex: auto;
	background: #fff;

	.inner {
		width: 1200px;
		height: 100%;
		margin: auto;
		position: relative;
		background: url(${entranceBg}) 0 75% no-repeat;
		background-size: 65%;

		.logo-content {
			padding: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .new-icon-logo-inner {
        position: relative;
        padding-left: 30px;

        .feat-logo {
          width: 30px;
          position: absolute;
          left: 0px;
          top: 0px;
        }
      }
		}

		.form {
			position: absolute;
			top: 15%;
			right: 20px;
			box-shadow: 0 0 3px #e0e0e0;
			z-index: 1;
		}
	}
`

export const Footer = styled.div`
	flex: 0 0 300px;
	background: #1e2635;
	color: #fff;

	& > section {
		width: 1200px;
		height: 100%;
		margin: auto;
	}

	ul.list {
		display: flex;
		justify-content: space-between;
		padding: 20px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);

		& > li {
			font-size: 16px;
		}

		.anticon {
			font-size: 18px;
		}
	}

	.desc {
		position: relative;
		height: 180px;

		.qrcode {
			position: absolute;
			top: 0;
			left: 0;
			width: 167px;
			height: 207px;
		}

		.context {
			text-align: center;
			h1 {
				font-size: 40px;
				font-weight: bold;
				color: #ffffff;
				line-height: 75px;
			}
			p {
				font-size: 18px;
				font-weight: 400;
				color: #a5a8ae;
				line-height: 28px;
			}
		}
	}

	.copyright {
		text-align: center;
		color: #e0e0e0;
		margin-bottom: 5px;
	}
`
