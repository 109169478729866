import { useAMapEvents, useAMapOverlayInputs } from '@/libs/react-amap/components/common/hooks'
import { useEffect, useImperativeHandle, useState, PropsWithChildren, forwardRef } from 'react'
import { OverlayPropsEvents } from '../types'

export const pointSimplifierEvents = ['pointClick', 'pointMouseover', 'pointMouseout'] as const

export const pointSimplifierEventsInputProps = []

type pointSimplifierEventsProps = typeof pointSimplifierEvents[number]

export type PointSimplifierEventsProps = OverlayPropsEvents<pointSimplifierEventsProps> &
	PropsWithChildren<any> & {
		onInit?: (distCluster: any) => void
	}

export const AMapPointSimplifier = forwardRef<any, PointSimplifierEventsProps>((options, ref) => {
	const { children, onInit, map, getPosition, showPoints, renderOptions, data, isIcon, ...others } = options
	const [pointSimplifier, setPointSimplifier] = useState<any>()

	useEffect(() => {
		if (!map) return
		let pointSimplifierInstance: any
		if (!pointSimplifier) {
			window.AMapUI.loadUI(['misc/PointSimplifier'], function (PointSimplifier: any) {
				if (isIcon) {
					const picUrl = renderOptions.pointStyle.content
					renderOptions.pointStyle.content = PointSimplifier.Render.Canvas.getImageContent(
						picUrl,
						function onload() {
							pointSimplifierInstance.renderLater()
						},
						function onerror(e: any) {
							alert('图片加载失败！')
						}
					)
				}
				pointSimplifierInstance = new PointSimplifier({
					map,
					data,
					getPosition: getPositionDefault,
					renderOptions,
					...others
				})
				if (!showPoints) {
					pointSimplifierInstance.hide()
				}
				setPointSimplifier(pointSimplifierInstance)
				onInit?.(pointSimplifierInstance)
			})
		}

		return () => {
			if (pointSimplifierInstance) {
				setPointSimplifier(undefined)
				pointSimplifierInstance.setData([])
			}
		}
	}, [])

	const getPositionDefault = (item: any) => {
		return item.position
	}

	useEffect(() => {
		if (showPoints) {
			pointSimplifier?.show()
		} else {
			pointSimplifier?.hide()
		}
	}, [showPoints])

	useAMapOverlayInputs<any>(pointSimplifier!, pointSimplifierEventsInputProps, options)

	useAMapEvents<any>(pointSimplifier!, options, pointSimplifierEvents, true)

	useImperativeHandle(ref, () => pointSimplifier!)

	return children || null
})
