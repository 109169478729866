import { useEffect, useState } from 'react'
import { Vehicle } from '@/services/vehicle/vehicle.model'
import getAppConfig from '@/env-configs'
import { VehicleMoveStatus } from '@/utils/constants'
const vehicleIcon = require('../assets/vehicle-icon.png').default

if (!window.VehicleIconsMap) {
	window.VehicleIconsMap = new Map()
}

if (!window.VehicleIconsMapBase64) {
	window.VehicleIconsMapBase64 = new Map()
}

function buildVehicleIconKey(vehicle: Vehicle) {
	const { icon, sportStatus, exceptions } = vehicle
	if (exceptions && exceptions.length > 0) {
		return `vehicleIcon${icon}_${sportStatus}_exceptions`
	}

	return `vehicleIcon${icon}_${sportStatus}`
}

function buildVehicleIconBase64Key(vehicle: Vehicle) {
	const { icon, sportStatus, exceptions } = vehicle
	if (exceptions && exceptions.length > 0) {
		return `vehicleIcon${icon}_${sportStatus}_exceptions`
	}

	return `vehicleIcon${icon}_${sportStatus}`
}

const appConfig = getAppConfig()

export const useVehicleIcon = (vehicle: Vehicle | null) => {
	const [icon, setIcon] = useState(null)
	const [MapProvider] = useState(appConfig.getMapProvider())

	useEffect(() => {
		if (!vehicle) {
			return
		}
		const key = buildVehicleIconKey(vehicle)
		if (window.VehicleIconsMap.get(key)) {
			const iconCache = window.VehicleIconsMap.get(key)
			if (iconCache) {
				setIcon(iconCache)
			}

			return
		}

		const toSetIcon = (key: string, img: string, offsetX: number, offsetY: number) => {
			const markerIcon = new MapProvider.Icon({
				image: img,
				size: new MapProvider.Size(20, 40),
				imageOffset: new MapProvider.Pixel(offsetX, offsetY)
			})
			setIcon(markerIcon as any)
			window.VehicleIconsMap.set(key, markerIcon)
		}

		const iconCol = vehicle.sportStatus > 0 ? vehicle.sportStatus : VehicleMoveStatus.Offline
		if (vehicle.exceptions.length > 0) {
			createExceptionIcon(20, 40, iconCol * 20, (vehicle.icon - 1) * 40).then(img => {
				toSetIcon(key, img, 0, 0)
			})
		} else {
			toSetIcon(key, vehicleIcon, 0 - iconCol * 20, 0 - (vehicle.icon - 1) * 40)
		}
	}, [vehicle])

	return icon
}

async function createExceptionIcon(width: number, height: number, offsetX: number, offsetY: number) {
	const vehicleImg = await base64ToImg(vehicleIcon)
	const canvas = document.createElement('canvas')
	const ctx = canvas.getContext('2d')
	canvas.width = width
	canvas.height = height
	ctx!.drawImage(vehicleImg, offsetX == 80 ? 80 : 40, offsetY, width, height, 0, 0, width, height)

	return canvas.toDataURL()
}

async function createIcon(width: number, height: number, offsetX: number, offsetY: number) {
	const vehicleImg = await base64ToImg(vehicleIcon)
	const canvas = document.createElement('canvas')
	const ctx = canvas.getContext('2d')
	canvas.width = width
	canvas.height = height
	ctx!.drawImage(vehicleImg, offsetX, offsetY, width, height, 0, 0, width, height)

	return canvas.toDataURL()
}

function base64ToImg(base64: string): Promise<HTMLImageElement> {
	const img = new Image()

	return new Promise(function (resolve, reject) {
		if (typeof base64 === 'string') {
			img.src = base64
		} else {
			console.error('input string not valid')
		}
		img.onload = function () {
			resolve(img)
		}
		img.onerror = function (e) {
			reject(e)
		}
	})
}

export const storageAllIcons = async () => {
	for (let i = 0; i <= 4; i++) {
		for (let j = 0; j <= 4; j++) {
			const exceptionKey = `vehicleIcon${j}_${i}_exceptions`
			const exceptionIcon = await createExceptionIcon(20, 40, i * 20, (j - 1) * 40)
			window.VehicleIconsMapBase64.set(exceptionKey, exceptionIcon)
			const key = `vehicleIcon${j}_${i}`
			const icon = await createIcon(20, 40, i * 20, (j - 1) * 40)
			window.VehicleIconsMapBase64.set(key, icon)
		}
	}
}

export const getVehicleIcon = async (vehicle: Vehicle) => {
	let icon = null
	const key = buildVehicleIconBase64Key(vehicle)

	if (window.VehicleIconsMapBase64.get(key)) {
		const iconCache = window.VehicleIconsMapBase64.get(key)
		if (iconCache) {
			icon = iconCache
		}
		return icon
	} else {
		let key = null
		const iconCol = vehicle.sportStatus > 0 ? vehicle.sportStatus : VehicleMoveStatus.Offline
		if (vehicle.exceptions.length > 0) {
			key = `vehicleIcon${vehicle.icon}_${iconCol}_exceptions`
			icon = await createExceptionIcon(20, 40, iconCol * 20, (vehicle.icon - 1) * 40)
		} else {
			key = `vehicleIcon${vehicle.icon}_${iconCol}`
			icon = await createIcon(20, 40, iconCol * 20, (vehicle.icon - 1) * 40)
		}
		window.VehicleIconsMapBase64.set(key, icon)
		console.log('无图标', icon)
		return icon
	}
}
