import { ApiAddress } from '@/services/apis'
import request from '@/services/request'
import { ControlPermissionRequest, PasswordChangeModel, UserData, UserModel } from '@/services/user/user.model'
import md5 from 'blueimp-md5'

export function getOverSpeed() {
	return sessionStorage.getItem('overSpeed')
}

export function getUser(): Promise<UserData> {
	return request.get<any, UserData>(ApiAddress.CURRENT_USER).then(userInfo => {
		sessionStorage.setItem('overSpeed', String(userInfo.speed_limit))
		return userInfo
	})
}

export function updateUser(data: UserModel) {
	return request.put(ApiAddress.CURRENT_USER, data)
}

export function changePassword(data: PasswordChangeModel) {
	data.old = md5(data.old)
	data.new = md5(data.new)

	return request.put(ApiAddress.USER_PASSWORD, data)
}

export function authFuelControl(data: ControlPermissionRequest) {
	return request.post(ApiAddress.FUEL_SMS_VALIDATION, data)
}
