import { useAMapEvents } from '@/libs/react-amap/components/common/hooks'
import { useEffect, useImperativeHandle, useState, forwardRef } from 'react'
import { OverlayPropsEvents } from '../types'

export const PolygonEvents = [
	'onHide',
	'onShow',
	'onClick',
	'onDblclick',
	'onRightClick',
	'onMousedown',
	'onMouseup',
	'onMouseover',
	'onMouseout',
	'onChange',
	'onTouchstart',
	'onTouchmove',
	'onTouchend'
] as const

export type PolygonEventsProps = typeof PolygonEvents[number]

export type PolygonProps = AMap.Polygon.Options &
	OverlayPropsEvents<PolygonEventsProps> & {
		onInit?: (polygon: AMap.Polygon) => void
		isEditor?: boolean
	}

export const AMapPolygon = forwardRef<AMap.Polygon, PolygonProps>((options, ref) => {
	const { map, onInit, isEditor } = options
	const {
		zIndex,
		path,
		bubble,
		cursor,
		strokeColor,
		strokeOpacity,
		strokeWeight,
		fillColor,
		fillOpacity,
		strokeStyle,
		extData,
		strokeDasharray
	} = options
	const [polygon, setPolygon] = useState<AMap.Polygon>()
	const [polygonEditor, setPolygonEditor] = useState<AMap.PolyEditor>()

	useEffect(() => {
		if (!map) return
		const instance = new AMap.Polygon({
			zIndex,
			path,
			bubble,
			cursor,
			strokeColor,
			strokeOpacity,
			strokeWeight,
			fillColor,
			fillOpacity,
			strokeStyle,
			extData,
			strokeDasharray
		})
		instance.setMap(map)
		setPolygon(instance)
		onInit?.(instance)

		return () => {
			instance.setMap(null)
		}
	}, [])

	useEffect(() => {
		polygon?.setOptions({
			zIndex,
			path,
			bubble,
			cursor,
			strokeColor,
			strokeOpacity,
			strokeWeight,
			fillColor,
			fillOpacity,
			strokeStyle,
			extData,
			strokeDasharray
		})
	}, [
		zIndex,
		path,
		bubble,
		cursor,
		strokeColor,
		strokeOpacity,
		strokeWeight,
		fillColor,
		fillOpacity,
		strokeStyle,
		extData,
		strokeDasharray
	])

	useEffect(() => {
		if (!polygon) return

		if (isEditor) {
			if (polygonEditor) {
				polygonEditor.open()
			} else {
				map?.plugin('AMap.PolyEditor', () => {
					const editor = new AMap.PolyEditor(map, polygon!)
					setPolygonEditor(editor)
					editor.open()
				})
			}
		} else {
			polygonEditor?.close()
		}

		return () => {
			polygonEditor?.close()
		}
	}, [isEditor, polygon, polygonEditor])

	useAMapEvents<AMap.Polygon>(polygon!, options, PolygonEvents)

	useImperativeHandle(ref, () => polygon!)

	return null
})
