import {
	AMapLoader,
	AMapMap,
	AMapMarker,
	AMapDistrictCluster,
	AMapPointSimplifier,
	AMapMarkerCluster,
	AMapInfoWindow,
	AMapAdCodeBounds,
	AMapAddress,
	AMapTrafficLayer,
	AMapPolyline,
	AMapLabel
} from '@/libs/react-amap'
import AMapSatelliteLayer from '@/libs/react-amap/components/SatelliteLayer'

const NBMap = {
	Loader: AMapLoader,
	Map: AMapMap,
	Marker: AMapMarker,
	DistCluster: AMapDistrictCluster,
	PointSimplifier: AMapPointSimplifier,
	MarkerCluster: AMapMarkerCluster,
	InfoWindow: AMapInfoWindow,
	Address: AMapAddress,
	TrafficLayer: AMapTrafficLayer,
	Polyline: AMapPolyline,
	Label: AMapLabel,
	SatelliteLayer: AMapSatelliteLayer,
	AdCodeBounds: AMapAdCodeBounds
}

export default NBMap
