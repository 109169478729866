import { UserData } from '@/services/user/user.model'
import { getUser } from '@/services/user/user.service'
import { RootState } from '@/store'
import { PermissionMap } from '@/utils/constants'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UserStore {
	userInfo: UserData | null
	permissions: string[]
}

const initialState: UserStore = {
	userInfo: null,
	permissions: []
}

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
	return await getUser()
})

const onSetUser = (state: UserStore, user: UserData) => {
	state.userInfo = user
	const permissions = user.privilege?.split(',').map(item => PermissionMap[item])
	if (user.user_type === 1) {
		permissions.push('MainUser')
	}
	state.permissions = permissions
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<UserData>) => {
			onSetUser(state, action.payload)
		},
		updateUser: (state, action: PayloadAction<UserData>) => {
			onSetUser(state, { ...state.userInfo, ...action.payload })
		}
	},
	extraReducers: builder => {
		builder.addCase(fetchUser.fulfilled, (state, action) => {
			onSetUser(state, action.payload)
		})
	}
})

export const { setUser, updateUser } = userSlice.actions

export const selectUser = (state: RootState) => state.user
