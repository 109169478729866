import { Corporation } from '@/services/corporation/corporation.modal'
import { loadCorporation } from '@/services/corporation/corporation.service'
import { RootState } from '@/store'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CorpStore {
	corpInfo: Corporation
}

const initialState: CorpStore = {
	corpInfo: {}
}

export const getCorp = createAsyncThunk('/corporation', async () => {
	return await loadCorporation()
})

const onSetCorp = (state: CorpStore, corp: Corporation) => {
	state.corpInfo = corp
}

export const corpSlice = createSlice({
	name: 'corp',
	initialState,
	reducers: {
		setCorp: (state, action: PayloadAction<Corporation>) => {
			onSetCorp(state, action.payload)
		}
	},
	extraReducers: builder => {
		builder.addCase(getCorp.fulfilled, (state, action) => {
			onSetCorp(state, action.payload)
		})
	}
})

export const { setCorp } = corpSlice.actions

export const selectCorp = (state: RootState) => state.corp
