import request from '@/services/request'
import { ApiAddress } from '@/services/apis'
import { cloneDeep, groupBy, uniq, uniqBy } from 'lodash-es'
import { ReplayData, TraceData, TraceItem, Track } from './replay.model'
// @ts-ignore
import coordtransform from 'coordtransform'

export async function loadVehicleTrack(id: string, params: any): Promise<TraceData | undefined> {
	const data: ReplayData = await request.get(ApiAddress.VEHICLE_TRACK, { params: { id, ...params } })
	if (data.track?.length === 0 && data.wireless?.length === 0) {
		return undefined
	}

  let track: any[] = []
  let wireless: any[] = []
  let trackDecodeData: any = {
    allTrack: [],
		track: [],
		stops: [],
		totalMileage: 0,
		totalDriveTime: 0,
		totalStopTime: 0,
		startTime: 0,
		endTime: 0
  }

  if(data.track?.length > 0) {
    const trackGroup = groupBy(data.track, item => item.tid)
    track = Object.values(trackGroup).sort((a, b) => b.length - a.length)[0]
    track = track.map(item => {
      const gcj02 = coordtransform.wgs84togcj02(item.longitude / 3600000, item.latitude / 3600000)

      return {
        ...item,
        longitude: gcj02[0],
        latitude: gcj02[1]
      }
    })
    trackDecodeData = {...processTrackData(track)}
  }

  if(data.wireless?.length > 0) {
    wireless = data.wireless.sort((a, b) => b.timestamp - a.timestamp).reverse().map((item, index) => {
      const gcj02 = coordtransform.wgs84togcj02(item.longitude / 3600000, item.latitude / 3600000)
      return {
        ...item,
        $trackIndex: index,
        longitude: gcj02[0],
        latitude: gcj02[1]
      }
    })
  }

	return {...trackDecodeData, wireless}
}

function processTrackData(track: Track[]): TraceData {
	const allTrack = cloneDeep(track)
	const stops = extractStops(track)
	track = uniqBy(track, 'mileage')
	const result = calculateDurationTime(stops)

	return {
		allTrack,
		track,
		stops,
		totalMileage: calMiles(stops, track),
		totalDriveTime: result.totalDriveTime,
		totalStopTime: result.totalStopTime,
		startTime: stops[0].timestamp,
		endTime: stops[stops.length - 1].timestamp
	}
}

function extractStops(track: TraceItem[]) {
	let lastType: number | null
	const stops: TraceItem[] = []

	clearRedundantTrackPoint(track)

	track.forEach((point, index, list) => {
		if (point.point_type !== lastType) {
			// 如果是静止点，将前面一个移动点视为开始停留点
			if (point.point_type === 2 && index !== 0) {
				point = list[index - 1]
				point.point_type = 2
				point.$trackIndex = index - 1
			} else {
				point.$trackIndex = index
			}

			stops.push(point)
			lastType = point.point_type
		}
	})

	processEndPoint(stops, track)

	return uniq(stops)
}

function clearRedundantTrackPoint(track: TraceItem[]) {
	let isPrevStop = false
	let isStop
	for (let i = 0; i < track.length; i++) {
		// 替换震荡点为停留点
		if (i > 0 && i < track.length - 1) {
			if (
				track[i].point_type === 1 &&
				track[i].point_type !== track[i - 1].point_type &&
				track[i].point_type !== track[i + 1].point_type
			) {
				track[i].point_type = 2
				track[i].is_shock = true
			}
		}
		isStop = track[i].point_type === 2
		if (isPrevStop && isStop) {
			track.splice(i, 1)
			i--
		}
		isPrevStop = !!isStop
	}
}

function processEndPoint(stops: TraceItem[], track: TraceItem[]) {
	const end = track[track.length - 1]
	end.$trackIndex = track.length - 1

	// 如果stops尾元素是停留点且track尾元素也是停留点，截断
	if (stops[stops.length - 1].point_type === 2 && end.point_type === 2) {
		track.splice(stops[stops.length - 1].$trackIndex! + 1)

		return
	}

	// 如果stops尾元素不是track的尾元素，插入终点
	if (!(stops.length > 1 && stops[stops.length - 1] && stops[stops.length - 1].$trackIndex === track.length - 1)) {
		stops.push(end)
	}
}

/**
 * 计算总里程，首尾相减
 * @param stops
 * @param track
 * @returns {number}
 */
function calMiles(stops: TraceItem[], track: TraceItem[]) {
	calStopDriveMiles(stops, track)

	return stops[stops.length - 1].mileage! - stops[0].mileage!
}

/**
 * 计算行驶里程
 * @param stops
 * @param track
 */
function calStopDriveMiles(stops: TraceItem[], track: TraceItem[]) {
	stops.forEach((stop, index, list) => {
		if (list.length === 1) {
			stop.driveMiles = stop.mileage

			return
		}

		if (stop.point_type === 1) {
			if (index === 0) {
				stop.driveMiles = list[index + 1].mileage! - stop.mileage!
			} else if (list[index + 1]) {
				stop.driveMiles = list[index + 1].mileage! - list[index - 1].mileage!
			} else {
				stop.driveMiles = track[track.length - 1].mileage! - list[index - 1].mileage!
			}
		}
	})
}

/**
 * 计算总时间
 * @param stops
 * @returns {{}}
 */
function calculateDurationTime(stops: TraceItem[]) {
	// 抽取timestamp属性构成时间序列
	const timeArray = stops.map(item => item.timestamp)

	let result
	let temp

	// totalA跟totalB表示停留跟行驶时间，但尚未确定谁是谁
	let totalA = 0

	let totalB = 0

	// 计算时间序列奇偶段分别的总时间
	for (let i = 1; i < timeArray.length; i++) {
		temp = timeArray[i] - timeArray[i - 1]
		if (i % 2) {
			// 奇数段
			totalA += temp
		} else {
			// 偶数段
			totalB += temp
		}
	}

	if (stops[0].point_type === 1) {
		// 时间序列由启动点开始
		result = {
			totalDriveTime: totalA,
			totalStopTime: totalB
		}
	} else {
		// 时间序列由停留点开始
		result = {
			totalDriveTime: totalB,
			totalStopTime: totalA
		}
	}

	return result
}
