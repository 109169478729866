import { PositionInfo } from '@/services/modes'
import { VehicleStatus, VehicleMoveStatus } from '@/utils/constants'
import { FuelAction } from '@/services/terminal/terminal.model'
import { Vehicle } from '@/services/vehicle/vehicle.model'
import dayjs from 'dayjs'
import { getCanControlVehicles, hasMonitorDevice, hideMoveStatusInfo, timeDuration, timeDurationSimple } from '@/utils/utils'
import _ from 'lodash'

export function doorLock(value: number | null) {
	let text = '设备不支持'
	switch (value) {
		case 0: {
			text = '开'
			break
		}
		case 1: {
			text = '关'
			break
		}
		case 3: {
			text = '设备不支持'
			break
		}
		default: {
		}
	}

	return text
}

export function carKey(val: number) {
	let text = '-'
	switch (val) {
		case 0: {
			text = '车钥匙LOCK'
			break
		}
		case 1: {
			text = '车钥匙ACC'
			break
		}
		case 2: {
			text = '车钥匙ON'
			break
		}
		case 3: {
			text = '车钥匙START'
			break
		}
		default: {
		}
	}

	return text
}

export function gear(value: number) {
	let text = '-'
	switch (value) {
		case 0: {
			text = 'P'
			break
		}
		case 1: {
			text = 'R'
			break
		}
		case 2: {
			text = 'N'
			break
		}
		case 3: {
			text = 'D'
			break
		}
		case 4: {
			text = 'S'
			break
		}
		case 5: {
			text = 'L'
			break
		}
		case 6: {
			text = 'M'
			break
		}
		case 255: {
			text = '不支持'
			break
		}
		default: {
		}
	}

	return text
}

export function lamp(value: number): any {
	let text = '-'
	switch (value) {
		case 0: {
			text = '开'
			break
		}
		case 1: {
			text = '关'
			break
		}
		case 3: {
			text = '不支持'
			break
		}
		default: {
		}
	}

	return text
}

export function trunk(value: number): any {
	let text = '-'
	switch (value) {
		case 0: {
			text = '关'
			break
		}
		case 1: {
			text = '开'
			break
		}
		case 3: {
			text = '不支持'
			break
		}
		default: {
		}
	}

	return text
}

export function brake(value: number): any {
	let text = '-'
	switch (value) {
		case 0: {
			text = '未刹车'
			break
		}
		case 255: {
			text = '不支持'
			break
		}
		default: {
		}
	}

	if (value >= 1 && value <= 10) {
		text = '刹车'
	}

	return text
}

export function parking(value: number): any {
	let text = '-'
	switch (value) {
		case 0: {
			text = '未驻车'
			break
		}
		case 1: {
			text = '已驻车'
			break
		}
		case 255: {
			text = '不支持'
			break
		}
		default: {
		}
	}

	return text
}

export function nullFormat(value: any, unit?: string): string {
	let text = '-'
	if (value !== null && value !== undefined) {
		if (unit) {
			text = `${value}${unit}`
		} else {
			text = value
		}
	}

	return text
}

/**
 *
 * @param value VehicleStatus
 * @param isLogin
 */
export function moveState(value: number, isLogin: boolean) {
	if (!isLogin) {
		return '-'
	}

	switch (value) {
		case VehicleStatus.Stop: {
			return '停留'
		}
		case VehicleStatus.Run: {
			return '行驶'
		}
		default: {
			return '-'
		}
	}
}

export function moveStateWithSpeed(vehicle: Vehicle, simpleType?: boolean) {
	const isLogin = vehicle.login
	const hideStatusInfo = hideMoveStatusInfo(vehicle)
	const statusChangeStr =
		vehicle.statusChangeTime == 0
			? '-'
			: simpleType
			? timeDurationSimple(dayjs(new Date()).unix() - vehicle.statusChangeTime!)
			: timeDuration(dayjs(new Date()).unix() - vehicle.statusChangeTime!)
	if (!isLogin) {
		return `离线 ${statusChangeStr ? '(' + statusChangeStr + ')' : ''}`
	}
	switch (vehicle.sportStatus) {
		case VehicleMoveStatus.Unknown: {
			return `离线 ${statusChangeStr ? '(' + statusChangeStr + ')' : ''}`
		}
		case VehicleMoveStatus.Offline: {
			return `离线 ${statusChangeStr ? '(' + statusChangeStr + ')' : ''}`
		}
		case VehicleMoveStatus.Stop: {
			return hideStatusInfo ? `-` : `停留 ${statusChangeStr ? '(' + statusChangeStr + ')' : ''}`
		}
		case VehicleMoveStatus.Normal: {
			return hideStatusInfo ? `-` : '行驶中' + ` ${vehicle.position.speed}/KM`
		}
		case VehicleMoveStatus.OverSpeed: {
			return hideStatusInfo ? `-` : '超速' + ` ${vehicle.position.speed}/KM`
		}
		default: {
			return '-'
		}
	}
}

const statusText = ['供油', '断油']
const statusActionText = ['恢复车辆油电', '断开车辆油电', '断开车辆油电']
export function fuelStatus(value: number, action = false): string {
	let text
	if (action) {
		text = statusActionText[value]
	} else {
		text = statusText[value]
	}

	return text || '-'
}

export function isZeroPoint(locationInfo: PositionInfo) {
	return isFalsy(locationInfo.latitude) || isFalsy(locationInfo.longitude)
}

export function enginePipe(val: number): string {
	switch (val) {
		case 0:
			return '该终端不支持引擎状态显示'
		case 1:
			return '已发动'
		case 2:
			return '已熄火'
		default:
			return '-'
	}
}

// dormant: 暗锁断油, wired: 有线断油
export const FuelTextMap = {
	dormant: '无线暗锁',
	wired: '有线继电器'
}

export function fuelCutTypePipe(ops: FuelAction[]): string {
	const defaultStr = '-'
	if (!ops) {
		return defaultStr
	}

	return (
		ops
			.filter(op => op.status === 1)
			.map(item => {
				const key = item.type

				return FuelTextMap[key]
			})
			.join('+') || defaultStr
	)
}

export function fuelActionType(value: 'dormant' | 'wired'): string {
	if (!value) {
		return '-'
	}

	return FuelTextMap[value]
}

function isFalsy(val: any) {
	return val === 0 || val === undefined || val === null
}
// 车辆GPS信号强弱
export function formatGPS(gps: number): string {
	if (gps >= 35) {
		return '信号强'
	}
	if (gps >= 15 && gps <= 34) {
		return '信号中'
	}
	if (gps < 15) {
		return '信号弱'
	}
	if (gps === 0) {
		return '无信号'
	}

	return '无信号'
}

export function filterVehicleByFuncType(vehicles: Vehicle[], filter: string[]) {
	let filterVehicle: Vehicle[] = []
	const filterLevel = filter.length
	switch (filterLevel) {
		case 1:
			filterVehicle = getCanControlVehicles(vehicles) || vehicles.filter(v => hasMonitorDevice(v))
			break
		case 2:
			if (filter[1] == 'carControl') {
				//IV100设备，可控制车辆开关锁
				filterVehicle = vehicles.filter(v => v.fuelDevice && v.fuelDevice.full_type === 'IV100')
			}
			if (filter[1] == 'fuelControl') {
				//有控油设备，且控油设备开关打开能使用
				filterVehicle = vehicles.filter(v => v.fuelDevice && v.fuelDevice.fuel_control_switch)
			}
			if (filter[1] == 'monitor') {
				//视频设备
				filterVehicle = vehicles.filter(v => hasMonitorDevice(v))
			}
			break
		default:
			break
	}

	return filterVehicle
}

export function filterVehicleByCarStatus(vehicles: Vehicle[], filter: string[]) {
	let filterVehicle: Vehicle[] = []
	const filterLevel = filter.length
	switch (filterLevel) {
		case 1:
			filterVehicle = vehicles.filter(
				v =>
					v.sportStatus === VehicleMoveStatus.Normal ||
					v.sportStatus === VehicleMoveStatus.OverSpeed ||
					(v.sportStatus === VehicleMoveStatus.Stop && v.statusChangeTime) ||
					v.terminals.find(t => t.fuel_status === 1 || t.fuel_status === 0)
			)
			break
		case 2:
			if (filter[1] === 'moveStatus') {
				//行驶状态
				filterVehicle = vehicles.filter(
					v => v.sportStatus === VehicleMoveStatus.Normal || v.sportStatus === VehicleMoveStatus.OverSpeed
				)
			}
			if (filter[1] === 'stopStatus') {
				//停留状态
				filterVehicle = vehicles.filter(v => v.sportStatus === VehicleMoveStatus.Stop && v.statusChangeTime)
			}
			if (filter[1] === 'fuelStatus') {
				//油路状态
				filterVehicle = vehicles.filter(v => v.terminals.find(t => t.fuel_status === 1 || t.fuel_status === 0))
			}
			break
		case 3:
			if (filter[2] === 'normalMove') {
				//正常行驶
				filterVehicle = vehicles.filter(v => v.sportStatus === VehicleMoveStatus.Normal)
			}
			if (filter[2] === 'overSpeedMove') {
				//超速行驶
				filterVehicle = vehicles.filter(v => v.sportStatus === VehicleMoveStatus.OverSpeed)
			}
			if (filter[2] === 'stopOverOneHour') {
				//停留超过1小时
				filterVehicle = vehicles.filter(
					v =>
						v.sportStatus === VehicleMoveStatus.Stop &&
						v.statusChangeTime &&
						timeDurationSimple(dayjs(new Date()).unix() - v.statusChangeTime!) === '超过1小时'
				)
			}
			if (filter[2] === 'stopOverThreeHour') {
				//停留超过3小时
				filterVehicle = vehicles.filter(
					v =>
						v.sportStatus === VehicleMoveStatus.Stop &&
						v.statusChangeTime &&
						timeDurationSimple(dayjs(new Date()).unix() - v.statusChangeTime!) === '超过3小时'
				)
			}
			if (filter[2] === 'stopOverOneDay') {
				//停留超过1天
				filterVehicle = vehicles.filter(
					v =>
						v.sportStatus === VehicleMoveStatus.Stop &&
						v.statusChangeTime &&
						timeDurationSimple(dayjs(new Date()).unix() - v.statusChangeTime!) === '超过1天'
				)
			}
			if (filter[2] === 'stopOverThreeDays') {
				//停留超过3天
				filterVehicle = vehicles.filter(
					v =>
						v.sportStatus === VehicleMoveStatus.Stop &&
						v.statusChangeTime &&
						timeDurationSimple(dayjs(new Date()).unix() - v.statusChangeTime!) === '超过3天'
				)
			}
			if (filter[2] === 'stopOverSevenDays') {
				//停留超过7天
				filterVehicle = vehicles.filter(
					v =>
						v.sportStatus === VehicleMoveStatus.Stop &&
						v.statusChangeTime &&
						timeDurationSimple(dayjs(new Date()).unix() - v.statusChangeTime!) === '超过7天'
				)
			}
			if (filter[2] === 'fuelCutOff') {
				//断油
				filterVehicle = vehicles.filter(v => v.terminals.find(t => t.fuel_status === 1))
			}
			if (filter[2] === 'fuelSupply') {
				//供油
				filterVehicle = vehicles.filter(v => v.terminals.find(t => t.fuel_status === 0))
			}
			break
		default:
			break
	}

	return filterVehicle
}

export function filterVehicleByDeviceType(vehicles: Vehicle[], filter: string[]) {
	let filterVehicle: Vehicle[] = []
	const filterLevel = filter.length
	switch (filterLevel) {
		case 1:
			filterVehicle = vehicles.filter(v =>
				v.terminals.find(
					t => t.work_mode === 1 || t.work_mode === 2 || t.dev_status === 4 || t.dev_status === 0 || t.dev_status === 3
				)
			)
			break
		case 2:
			if (filter[1] == 'deviceNormal') {
				//设备正常
				filterVehicle = vehicles.filter(v => v.terminals.find(t => t.dev_status === 0))
			}
			if (filter[1] == 'deviceLowPower') {
				//设备低电
				filterVehicle = vehicles.filter(v => v.terminals.find(t => t.dev_status === 3))
			}
			if (filter[1] == 'toBeEmergent') {
				//待紧急模式
				filterVehicle = vehicles.filter(v => v.terminals.find(t => t.work_mode === 1))
			}
			if (filter[1] == 'isEmergent') {
				//紧急模式
				filterVehicle = vehicles.filter(v => v.terminals.find(t => t.work_mode === 2 || t.dev_status === 4))
			}
			break
		default:
			break
	}

	return filterVehicle
}

export function filterVehicleByCarStatusOffline(vehicles: Vehicle[], filter: string[]) {
	let filterVehicle: Vehicle[] = []
	const filterLevel = filter.length
	switch (filterLevel) {
		case 1:
			filterVehicle = vehicles.filter(
				v =>
					((!v.login || v.sportStatus === VehicleMoveStatus.Offline || v.sportStatus === VehicleMoveStatus.Unknown) &&
						v.statusChangeTime) ||
					v.terminals.find(t => t.fuel_status === 1 || t.fuel_status === 0)
			)
			break
		case 2:
			if (filter[1] === 'offlineStatus') {
				//离线天数
				filterVehicle = vehicles.filter(
					v =>
						(!v.login || v.sportStatus === VehicleMoveStatus.Offline || v.sportStatus === VehicleMoveStatus.Unknown) &&
						v.statusChangeTime
				)
			}
			if (filter[1] === 'fuelStatus') {
				//油路状态
				filterVehicle = vehicles.filter(v => v.terminals.find(t => t.fuel_status === 1 || t.fuel_status === 0))
			}
			break
		case 3:
			if (filter[2] === 'offlineOverOneDay') {
				//离线超过1天
				filterVehicle = vehicles.filter(
					v =>
						(!v.login || v.sportStatus === VehicleMoveStatus.Offline || v.sportStatus === VehicleMoveStatus.Unknown) &&
						v.statusChangeTime &&
						timeDurationSimple(dayjs(new Date()).unix() - v.statusChangeTime!) === '超过1天'
				)
			}
			if (filter[2] === 'offlineOverThreeDays') {
				//离线超过3天
				filterVehicle = vehicles.filter(
					v =>
						(!v.login || v.sportStatus === VehicleMoveStatus.Offline || v.sportStatus === VehicleMoveStatus.Unknown) &&
						v.statusChangeTime &&
						timeDurationSimple(dayjs(new Date()).unix() - v.statusChangeTime!) === '超过3天'
				)
			}
			if (filter[2] === 'offlineOverSevenDays') {
				//离线超过7天
				filterVehicle = vehicles.filter(
					v =>
						(!v.login || v.sportStatus === VehicleMoveStatus.Offline || v.sportStatus === VehicleMoveStatus.Unknown) &&
						v.statusChangeTime &&
						timeDurationSimple(dayjs(new Date()).unix() - v.statusChangeTime!) === '超过7天'
				)
			}
			if (filter[2] === 'fuelCutOff') {
				//断油
				filterVehicle = vehicles.filter(v => v.terminals.find(t => t.fuel_status === 1))
			}
			if (filter[2] === 'fuelSupply') {
				//供油
				filterVehicle = vehicles.filter(v => v.terminals.find(t => t.fuel_status === 0))
			}
			break
		default:
			break
	}

	return filterVehicle
}

export function filterVehicleByDeviceTypeOffline(vehicles: Vehicle[], filter: string[]) {
	let filterVehicle: Vehicle[] = []
	const filterLevel = filter.length
	switch (filterLevel) {
		case 1:
			filterVehicle = vehicles.filter(v =>
				v.terminals.find(t => t.dev_status === 1 || t.dev_status === 2 || t.dev_status === 4 || t.dev_status === 5)
			)
			break
		case 2:
			if (filter[1] == 'deviceErrOffline') {
				//异常离线
				filterVehicle = vehicles.filter(v => v.terminals.find(t => t.dev_status === 1))
			}
			if (filter[1] == 'deviceUnplugging') {
				//设备拔出
				filterVehicle = vehicles.filter(v => v.terminals.find(t => t.dev_status === 4))
			}
			if (filter[1] == 'deviceOff') {
				//设备未开机
				filterVehicle = vehicles.filter(v => v.terminals.find(t => t.dev_status === 5))
			}
			if (filter[1] == 'deviceNoPbat') {
				//电量耗尽
				filterVehicle = vehicles.filter(v => v.terminals.find(t => t.dev_status === 2))
			}
			break
		default:
			break
	}

	return filterVehicle
}

/**
 *
 * @param v VehicleList
 * @param filterArr filter Rule Arr
 */
export function vehicleFilter(v: Vehicle[], filterArr: string[][]): Vehicle[] {
	if (filterArr.length === 0) return v

	let returnVehicles: Vehicle[] = []
	filterArr.forEach(f => {
		switch (f[0]) {
			case 'funcType':
				returnVehicles = returnVehicles.concat(filterVehicleByFuncType(v, f))
				break
			case 'carStatus':
				returnVehicles = returnVehicles.concat(filterVehicleByCarStatus(v, f))
				break
			case 'deviceType':
				returnVehicles = returnVehicles.concat(filterVehicleByDeviceType(v, f))
				break
			case 'carStatusOffline':
				returnVehicles = returnVehicles.concat(filterVehicleByCarStatusOffline(v, f))
				break
			case 'deviceTypeOffline':
				returnVehicles = returnVehicles.concat(filterVehicleByDeviceTypeOffline(v, f))
				break
		}
	})
	returnVehicles = _.uniqBy(returnVehicles, 'id')

	return returnVehicles
}
