import { useAMapEvents, useAMapOverlayInputs } from '@/libs/react-amap/components/common/hooks'
import { useEffect, useImperativeHandle, useState, forwardRef, PropsWithChildren } from 'react'
import { OverlayPropsEvents } from '@/libs/react-amap/types'

export const LabelEvents = [
	'onClick',
	'onDblclick',
	'onRightclick',
	'onMousemove',
	'onMouseover',
	'onMouseout',
	'onMousedown',
	'onMouseup',
	'onDragstart',
	'onDragging',
	'onDragend',
	'onMoving',
	'onMoveend',
	'onMovealong',
	'onTouchstart',
	'onTouchmove',
	'onTouchend'
] as const

export const LabelInputProps = ['text', 'position', 'icon', 'angle', 'anchor']

type LabelEventsProps = typeof LabelEvents[number]

export type LabelProps = AMap.Text.Options & OverlayPropsEvents<LabelEventsProps> & PropsWithChildren<any>

export const AMapLabel = forwardRef<AMap.Text, LabelProps>((options, ref) => {
	const { children, map, position, ...others } = options
	const [text, setText] = useState<AMap.Text>()

	useEffect(() => {
		if (!map) return
		let instance: AMap.Text
		if (!text) {
			instance = new AMap.Text({
				position,
				...others
			})
			instance.setMap(map)
			setText(instance)
		}

		return () => {
			if (instance) {
				instance.setMap(null)
				setText(undefined)
			}
		}
	}, [])

	useAMapOverlayInputs<AMap.Marker>(text!, LabelInputProps, options)

	useAMapEvents<AMap.Marker>(text!, options, LabelEvents)

	useImperativeHandle(ref, () => text!)

	return children || null
})
