import { loadMapKey } from '@/services/map-key/map-key.service'
import { useRequest, useSessionStorageState } from 'ahooks'
import { useEffect } from 'react'

export function useMapKey() {
  const [key, setKey] = useSessionStorageState<string>('mapKey')
  const {run} = useRequest(loadMapKey, {
    manual: true,
    onSuccess: (data: string[]) => {
      data[0] && setKey(data[0])
    },
    onError: (e) => {
      setKey('e1dd989275e2ba53b4b294da201506ac')
    }
  })
  useEffect(() => {
    !key && run()
  }, [])

	return key
}
