import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationData } from '@/services/notification/notification.model'

export interface NotificationStore {
	isOpen: boolean
	isNew: boolean
	notifications: NotificationData[]
}

const initialState: NotificationStore = {
	isOpen: false,
	isNew: false,
	notifications: []
}

export const notificationSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		addNotification: (state, action: PayloadAction<NotificationData>) => {
			state.notifications = [action.payload].concat(state.notifications)
			state.isNew = true
		},
		markNotificationToRead: (state, action: PayloadAction<string>) => {
			state.notifications = state.notifications.filter(item => item.eid !== action.payload)
		},
		updateIsNew: (state, action: PayloadAction<boolean>) => {
			state.isNew = action.payload
		},
		updateIsOpen: (state, action: PayloadAction<boolean>) => {
			state.isOpen = action.payload
		}
	},
	extraReducers: builder => {}
})

export const { addNotification, markNotificationToRead, updateIsNew, updateIsOpen } = notificationSlice.actions
