import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getGroupsThunk } from '@/store/vehicle/vehicle.thunks'
import {
	getVehicleByIdThunk,
	updateTracePosition,
	updateVehicleLocations,
	updateVehicleStatusByDevice
} from '@/store/vehicle/vehicle.slice'
import { updateUser } from '@/store/user/user.slice'
import { PositionInfo } from '@/services/modes'
import { sortBy } from 'lodash-es'
import { isZeroPoint } from '@/services/vehicle/vehicle-helper'
import { addNotification } from '@/store/notification/notification.slice'
import { useNavigate } from 'react-router'
import { logoutAction } from '@/store'

export interface Packet {
	packet_type: string
	res: any
}

export const PACKET_TYPES = {
	Login: 'S1',
	Logout: 'S2',
	Notification_Msg: 'S3',
	CorpInfo_Update: 'S4',
	Group_Update: 'S5',
	Vehicle_Update: 'S6',
	TerminalInfo_Update: 'S7',
	UserInfo_Update: 'S8',
	NotificationUnread_Update: 'S9',
	TerminalLogin_Update: 'S10',
	Vehicle_BatchUpdate: 'S11',
	Terminal_BatchAdd: 'S12',
	Location_Update: 'S13',
	Session_Timeout: 'S999'
}

export default function usePushData(socket: SocketIOClient.Socket | null) {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		if (!socket) {
			return
		}
		const onData = (event: string | any) => {
			const packet: Packet = typeof event === 'string' ? JSON.parse(event) : event
			console.log('[wspush get]:', event)
			if (!packet.packet_type) {
				return
			}
			const payload = packet.res

			switch (packet.packet_type) {
				default:
					break
				case PACKET_TYPES.Login:
					break
				case PACKET_TYPES.Logout:
					break
				case PACKET_TYPES.Notification_Msg:
					// 提醒消息
					dispatch(addNotification(payload))
					break
				case PACKET_TYPES.CorpInfo_Update: // S4
				case PACKET_TYPES.Group_Update: // S5
					// 组结构和信息变化
					dispatch(getGroupsThunk())
					break
				case PACKET_TYPES.Vehicle_Update:
					// 车辆结构和信息变化
					switch (payload.opt) {
						default:
							throw TypeError(`Unhandled Vehicle Push Action: ${payload.opt}`)
						// 修改车辆信息
						case 2:
							dispatch(getVehicleByIdThunk(payload.id))
							break
						// 添加车辆
						case 1:
						// 删除车辆
						case 3:
						// 移动车辆
						case 4:
							dispatch(getGroupsThunk())
							break
					}

					break
				case PACKET_TYPES.TerminalLogin_Update: // S10 终端登录离线状态变化
					const tInfo = payload
					tInfo.login = payload.status
					delete tInfo.status
					dispatch(updateVehicleStatusByDevice(tInfo))
					break
				case PACKET_TYPES.TerminalInfo_Update: // S7 终端信息变化推送
					dispatch(updateVehicleStatusByDevice(payload))
					break
				case PACKET_TYPES.UserInfo_Update: // S8 用户信息变化
					dispatch(updateUser(payload))
					break
				case PACKET_TYPES.NotificationUnread_Update:
					// 提醒未读变更
					// do nothing here
					break
				case PACKET_TYPES.Vehicle_BatchUpdate:
					// 批量修改车辆信息
					dispatch(getGroupsThunk())
					break
				case PACKET_TYPES.Terminal_BatchAdd:
					// 批量添加终端信息
					dispatch(getGroupsThunk())
					break
				case PACKET_TYPES.Location_Update: // S13 位置更新
					let locations = payload.location
					const validPoints: PositionInfo[] = []
					// 按时间排序，以获取最新位置点
					locations = sortBy(locations, 'timestamp')
					locations.forEach((pos: PositionInfo) => {
						// 赋值 locate_time 是为了后续处理一致性
						pos.locate_time = pos.timestamp!
						// 新位置信息为零点时不更新位置
						if (!isZeroPoint(pos)) {
							validPoints.push(pos)
						}
					})
					dispatch(updateVehicleLocations(validPoints))
					if (window.location.pathname.includes('/features/trace')) {
						console.log('执行更新tracePosition')
						dispatch(updateTracePosition(validPoints))
					}
					break
				case PACKET_TYPES.Session_Timeout:
					// 将用户踢下线
					dispatch(logoutAction())
					setTimeout(() => {
						navigate('/entrance/login')
					})
					break
			}
		}

		socket.on('api/resp', onData)

		return () => {
			socket.off('api/resp', onData)
		}
	}, [socket])
}
