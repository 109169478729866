export const apiBase = 'api'
export const apiVersion = 'v2.8'
export const apiPrefix = `/${apiBase}/${apiVersion}`

export const ApiAddress: { [index: string]: string } = {
	AUTHENTICATION: '/authentication',
	CAPTCHA_IMAGE: '/captcha/image',
	CAPTCHA_IMAGE_VALIDATION: '/captcha/image/validation',
	CAPTCHA_SMS: '/captcha/sms',
	CAPTCHA_SMS_VALIDATION: '/captcha/sms/validation',
	FUEL_SMS_VALIDATION: '/captcha/sms/validation/fuel_cut',
	REGISTRATION: '/registration',
	CURRENT_USER: '/users/current',
	USER_EXISTENCE: '/users/:id/existence',
	USER_PASSWORD: '/users/current/password',
	USER_PASSWORD_RESET: '/users/password',
	TERMINALS_LIST: '/terminals',
	TERMINAL: '/terminals/:id',
	TERMINAL_ACTIVITY_HISTORY: '/terminals/:id/activity_history',
	TERMINAL_SETTING: '/terminals/:id/setting',
	TERMINAL_POSITIONS: '/terminals/:id/positions',
	TERMINAL_PAIR_LOG: '/terminals/:id/pair_log',
	TERMINAL_COMMUNICATE_TIME: '/terminals/communicate_time',
	TERMINAL_MODE: '/terminals/:id/mode',
	IMPORT_TERMINALS: '/terminals/imports',
	VEHICLE: '/vehicles/:id',
	VEHICLES: '/vehicles',
	VEHICLE_ALL_POSITIONS: '/vehicles/:id/terminal_positions',
	VEHICLES_LIVE_POSITIONS: '/vehicles/live_positions',
	VEHICLES_TERMINAL_IDS: '/vehicles/terminal_ids',
	VEHICLE_TRACK: '/vehicles/:id/track',
	EXPORTS_VEHICLES: '/vehicles/exports',
	UPDATES_VEHICLES: '/vehicles/updates',
	UPDATES_VEHICLES_INFO: '/vehicles/update/info',
	ABNORMAL_MODE_VEHICLES: '/vehicles/abnormal_mode',
	OFFLINE_VEHICLES: '/vehicles/offline',
	CONNECT_OFF_VEHICLES: '/vehicles/connect_off',
	LOW_POWER_VEHICLES: '/vehicles/low_power',
	FUEL_CUT: '/vehicles/fuel_cut',
	CORP: '/corporation',
	CORP_TREE: '/corporation/tree',
	WEBSOCKET_REGISTRATION: '/wspush',
	GROUPS: '/groups',
	GROUP: '/groups/:id',
	GROUP_DETAIL: '/groups/:id/detail',
	SUBUSERS: '/subusers',
	SUBUSER: '/subusers/:id',
	SUBUSER_PROFILE: '/subusers/:id/profile',
	SUBUSER_PASSWORD: '/subusers/:id/password',
	WARNINGS: '/warnings',
	WARNINGS_SETTINGS: '/warnings/settings',
	WARNINGS_MAILS_SIZE: '/warnings/mails/size',
	WARNINGS_MAILS: '/warnings/mails',
	RELEASE_ANNOUNCEMENT: '/announcement/release',
	SEARCH: '/search',
	SEARCH_VEHICLES_WITH_DETAILS: '/search/vehicles_with_details',
	FEEDBACK: '/feedback',
	ANALYTICS: '/analytics',
	EXPORT_INSTALL: '/exports/install',
	FOOTPRINT: '/footprint',
	TERMINAL_FUEL_LOGS: '/terminals/:id/fuel-log',
	TERMINAL_FUEL_SETTING_FUEL: '/terminals/:id/setting/fuel',
	VEHICLES_MILEAGE_ANALYSIS: '/vehicles/mileage/analysis',
	VEHICLES_MILEAGE: '/vehicles/:car_id/mileage',
	WARNINGS_ANALYSIS: '/warnings/analysis',
	EXPORT_VEHICLE_MILEAGE: '/export/vehicles/mileage',
	EXPORT_WARNINGS_FIXED: '/export/warnings/fixed',
	EXPORT_BATCH_EDIT: '/vehicles/update/template/export',
	REGIONS: '/regions',
	FENCES: '/fences',
	FENCE: '/fences/:id',
	VEHICLE_GROUPS: '/vehicle-groups',
	MAP_KEY: '/map/key',
	TERMINAL_EXPIRATION_TIME: '/terminals/expiration_time',
	//定时拉取车辆信息
	VEHICLE_UPDATE_POSITIONS: '/zulinbao/vehicle/info',
	//设备绑定模板
	DEVICE_DOWNLOAD_TEMPLATE: '/terminals/template/export',
	USER_DEVICE_List: '/terminals/info',
	UPLOAD_DEVICE_FILE: '/terminals/imports',
	//设备管理
	DEVICES_OVERVIEW: '/terminal/overview',
	TERMINALS_AMOUNT: '/terminals/amount',
  //云钥匙远程控制
  CONTROL_CLOUD_VEHICLE: '/terminals/:id/setting/iv100',
  //控车日志
  CONTROL_LOG: '/vehicles/control_log',
  //监控视频
  MONITOR_LIST: '/camera/videos/tasks/:id',
  UPLOAD_MONITOR_FILE: '/camera/videos/tasks/:id',
  START_MONITOR_LIVE: '/camera/live_video/:id',
  STOP_MONITOR_LIVE: '/camera/live_video/:id',
  GET_MONITOR_LIVE_AUTO_CLOSE: '/camera/live_video/:id',
  SET_MONITOR_LIVE_AUTO_CLOSE: '/camera/live_video/:id',
  START_MONITOR_REPLAY: '/camera/video_playback/:id',
  STOP_MONITOR_REPLAY: '/camera/video_playback/:id',
  GET_MONITOR_REPLAY_URL: '/camera/videos/tasks/:id/:taskId',
  ALERT_PHOTO: '/camera/photo/',
}

Object.keys(ApiAddress).forEach(key => {
	ApiAddress[key] = apiPrefix + ApiAddress[key]
})

export const PushAddress = '/push/2.0/socket.io/'
export const SocketEvent = 'api/resp'

export const GetMapKey = 'https://key.util.linketech.cn'
export const GetAddress = 'https://geo.util.linketech.cn/api/tianditu/reverse'
