import { RootState } from '@/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type QueryType = 'group' | 'vehicle'

export interface ReportStore {
	queryId: string
	queryType: QueryType
	queryGroup: any
	queryListType: string
}

const initialState: ReportStore = {
	queryId: '',
	queryType: 'group',
	queryGroup: {},
	queryListType: ''
}

export const reportSlice = createSlice({
	name: 'report',
	initialState,
	reducers: {
		setQueryId: (state, action: PayloadAction<string>) => {
			state.queryId = action.payload
		},
		setQueryType: (state, action: PayloadAction<QueryType>) => {
			state.queryType = action.payload
		},
		setQueryGroup: (state, action: PayloadAction<any>) => {
			state.queryGroup = action.payload
		},
		setQueryListType: (state, action: PayloadAction<any>) => {
			state.queryListType = action.payload
		}
	}
})

export const { setQueryId, setQueryType, setQueryGroup, setQueryListType } = reportSlice.actions

export const getReportQuery = (state: RootState) => state.report
