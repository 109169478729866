import { useAMapEvents } from '@/libs/react-amap/components/common/hooks'
import { useEffect, useImperativeHandle, useState, forwardRef } from 'react'
import { OverlayPropsEvents } from '../types'

export const PolylineEvents = [
	'onHide',
	'onShow',
	'onClick',
	'onDblclick',
	'onRightClick',
	'onMousedown',
	'onMouseup',
	'onMouseover',
	'onMouseout',
	'onTouchstart',
	'onTouchmove',
	'onTouchend',
	'onChange'
] as const

type PolylineEventsProps = typeof PolylineEvents[number]

export type PolylineProps = AMap.Polyline.Options &
	OverlayPropsEvents<PolylineEventsProps> & {
		onInit?: (circle: AMap.Polyline) => void
	}

export const AMapPolyline = forwardRef<AMap.Polyline, PolylineProps>((options, ref) => {
	const { map, onInit, ...others } = options
	const [polyline, setPolyline] = useState<AMap.Polyline>()

	useEffect(() => {
		if (!map) return
		let instance: AMap.Polyline
		if (!polyline) {
			instance = new AMap.Polyline(others)
			instance.setMap(map)
			setPolyline(instance)
			onInit?.(instance)
		}

		return () => {
			if (instance) {
				instance.setMap(null)
				setPolyline(undefined)
			}
		}
	}, [])

	useEffect(() => {
		if (polyline) {
			polyline.setPath(others.path!)
		}
	}, [others.path])

	useAMapEvents<AMap.Polyline>(polyline!, options, PolylineEvents)

	useImperativeHandle(ref, () => polyline!)

	return null
})
