import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useAMapEvents } from '@/libs/react-amap/components/common/hooks'
import { OverlayPropsEvents } from '@/libs/react-amap/types'

export const MouseToolEvents = ['onDraw'] as const

export interface MouseToolInput {
	method?: AMap.MouseToolActions
	methodOption?: any
	map?: AMap.Map
}

type AMapMouseToolProps = OverlayPropsEvents<typeof MouseToolEvents[number]> & MouseToolInput

const AMapMouseTool = forwardRef((props: AMapMouseToolProps, ref) => {
	const { map, method, methodOption } = props
	const [mouseTool, setMouseTool] = useState<AMap.MouseTool>()

	useEffect(() => {
		;(async () => {
			if (!AMap.MouseTool) {
				await new Promise<void>(resolve => {
					map?.plugin('AMap.MouseTool', () => resolve())
				})
			}
			setMouseTool(new AMap.MouseTool(map!))
		})()
	}, [])

	useEffect(() => {
		if (mouseTool && method) {
			mouseTool[method](methodOption)
		}
		return () => {
			mouseTool?.close(true)
		}
	}, [mouseTool, method, methodOption])

	useAMapEvents<AMap.MouseTool>(mouseTool!, props, MouseToolEvents)

	useImperativeHandle(ref, () => mouseTool, [mouseTool])

	return null
})

export default AMapMouseTool
