import { useAMapEvents, useAMapOverlayInputs } from '@/libs/react-amap/components/common/hooks'
import { useEffect, useImperativeHandle, useState, forwardRef, PropsWithChildren } from 'react'
import { OverlayPropsEvents } from '../types'

export interface CustomMarker {
	type: string
	[prop: string]: any
}

export const MarkerClusterEvents = ['onClick'] as const

export const MarkerClusterInputProps = ['maxZoom', 'minClusterSize', 'styles', 'averageCenter']

type MarkerClusterEventsProps = typeof MarkerClusterEvents[number]

export type MarkerClusterProps = OverlayPropsEvents<MarkerClusterEventsProps> &
	PropsWithChildren<any> & {
		onInit?: (cluster: AMap.MarkerClusterer) => void
	}

export const AMapMarkerCluster = forwardRef<AMap.MarkerClusterer, MarkerClusterProps>((options, ref) => {
	const { children, onInit, map, markers, position, ...otherOpts } = options
	const [cluster, setCluster] = useState<any>()

	useEffect(() => {
		if (!map) return
		let cluster: any
		const markersInstance = getMarkersInstance(markers)
		if (!cluster) {
			cluster = new AMap.MarkerClusterer(map, { ...otherOpts })
			cluster.addMarkers(markersInstance)
			setCluster(cluster)
			onInit?.(cluster)
		}

		return () => {
			if (cluster) {
				cluster.clearMarkers()
				setCluster(undefined)
			}
		}
	}, [])

	useEffect(() => {
		if (!cluster) return
		cluster.clearMarkers()
		cluster.addMarkers(getMarkersInstance(markers))
	}, [markers])

	const getMarkersInstance = (markers: CustomMarker[]) => {
		return markers.map(item => {
			const { type, iconUrl, iconSetting, onClick, label, ...otherMarkerOpts } = item

			switch (type) {
				case 'marker':
					iconSetting.size = new AMap.Size(iconSetting.size?.[0] || 36, iconSetting.size?.[1] || 36)
					iconSetting.imageSize = new AMap.Size(iconSetting.imageSize?.[0] || 36, iconSetting.imageSize?.[1] || 36)
					otherOpts.position = new AMap.LngLat(otherMarkerOpts.position[0], otherMarkerOpts.position[1])
					otherMarkerOpts.offset = new AMap.Pixel(otherMarkerOpts.offset[0], otherMarkerOpts.offset[1])
					if (label) {
						label.offset = new AMap.Pixel(label.offset[0], label.offset[1])
					}

					const icon = new AMap.Icon({
						image: iconUrl,
						...iconSetting
					})
					const marker = new AMap.Marker({
						icon,
						label,
						...otherMarkerOpts
					})
					if (onClick) {
						marker.on('click', onClick)
					}
					return marker
			}
		})
	}

	useAMapOverlayInputs<any>(cluster!, MarkerClusterInputProps, options)

	useAMapEvents<any>(cluster!, options, MarkerClusterEvents)

	useImperativeHandle(ref, () => cluster!)

	return children || null
})
