import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SiteSore {
	pushConnected: boolean
}

const initialState: SiteSore = {
	pushConnected: false
}

export const siteSlice = createSlice({
	name: 'site',
	initialState,
	reducers: {
		setPushState: (state, action: PayloadAction<boolean>) => {
			state.pushConnected = action.payload
		}
	},
	extraReducers: builder => {}
})

export const { setPushState } = siteSlice.actions
