import { PositionInfo } from '@/services/modes'
import { Terminal } from '@/services/terminal/terminal.model'
import { Vehicle } from '@/services/vehicle/vehicle.model'
import { cloneDeep } from 'lodash-es'

const updateVehicleMap = new Map()
const vehicleKeyMap = new Map()

const setMapVehiclePositions = (location: PositionInfo, rawVehicle: Vehicle[]) => {
	const vehicles = [...rawVehicle]
	const locationPoint = location
	const { car_id, tid } = locationPoint
	const index = vehicleKeyMap.get(car_id) || vehicles.findIndex((t: any) => t.id === car_id)

	if (index < 0) return

	const terminalIndex = vehicles[index].terminals.findIndex((t: any) => t.tid === tid)
	const newTerminal = <Terminal>{
		...vehicles[index].terminals[terminalIndex],
		status_change_time: locationPoint.status_change_time,
		status: locationPoint.status,
		position: locationPoint
	}
	const newVehicle = { ...vehicles[index], position: { ...locationPoint } }

	let newVeh = cloneDeep(newVehicle)
	newVeh.terminals[terminalIndex] = { ...newTerminal }
	updateVehicleMap.set(index, newVeh)
}

const setVehicleKeyMap = (vehicles: Vehicle[]) => {
	vehicles.forEach((item: any, index: number) => {
		vehicleKeyMap.set(item.id, index)
	})
}

const clearUpdateVehicleMap = () => {
	updateVehicleMap.clear()
}

export { vehicleKeyMap, updateVehicleMap, setVehicleKeyMap, setMapVehiclePositions, clearUpdateVehicleMap }
