// @ts-ignore
import coordtransform from 'coordtransform'

export function getAMapProvider() {
	if (AMap) {
		return {
			cAMap: AMap,
			Icon: AMap.Icon,
			Pixel: AMap.Pixel,
			Size: AMap.Size,
			LngLat: AMap.LngLat
		}
	} else {
		throw Error('AMap has not loaded.')
	}
}

export function aMapCoordTransform(lng: number, lat: number) {
	return coordtransform.wgs84togcj02(lng, lat)
}
