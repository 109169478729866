import { updateUser } from '@/services/user/user.service'
import { selectUser } from '@/store/user/user.slice'
import { ModalForm } from '@ant-design/pro-form'
import { Form, Input, Menu } from 'antd'
import { memo, useState } from 'react'
import { useSelector } from 'react-redux'

const UserFormMenu = memo(props => {
	const { userInfo } = useSelector(selectUser)
	const [form] = Form.useForm()
	const [modalVisit, setModalVisit] = useState(false)

	return (
		<>
			<Menu.Item
				{...props}
				onClick={() => {
					setModalVisit(true)
					form.setFieldsValue(userInfo)
				}}
			>
				管理员信息
			</Menu.Item>

			<ModalForm
				form={form}
				visible={modalVisit}
				onVisibleChange={visible => {
					setModalVisit(visible)
				}}
				title='个人资料'
				onFinish={async values => {
					await updateUser(values)
					return true
				}}
				width={500}
				layout={'horizontal'}
				labelCol={{ span: 6 }}
			>
				<Form.Item
					label='用户名'
					name='alias'
					rules={[
						{ required: true, message: '请输入用户名' },
						{ max: 20, message: '用户名最大长度20位' }
					]}
				>
					<Input maxLength={20} />
				</Form.Item>

				<Form.Item label='手机号'>
					<span>{userInfo?.username}</span>
				</Form.Item>
			</ModalForm>
		</>
	)
})

export default UserFormMenu
