import { IconFont } from '@/components'
import { Divider, Spin } from 'antd'
import { memo, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { Content, Footer, Main } from './style'
import logo from '@/assets/entrance/logo.svg'
import QJLogo from '@/assets/entrance/qj_logo.png'
import DBJLogo from '@/assets/entrance/debaiqi-logo.svg'
import recordImg from '@/assets/entrance/record.png'
import QRCode from '@/assets/erweima.png'

export default memo(function EntranceLayout() {
	const year = new Date().getFullYear()

	return (
		<Main>
			<Content>
				<div className='inner'>
					<div className='logo-content'>
            <div className='new-icon-logo-inner'>
              <img height={35} src={logo} />
              <img className='feat-logo' src={QJLogo} />
            </div>
						<Divider style={{ height: '22px', margin: '16px' }} type='vertical' />

						<img height={26} src={DBJLogo} />
					</div>
					<div className='form'>
						<Suspense fallback={<Spin size='large' tip='Loading...' />}>
							<Outlet />
						</Suspense>
					</div>
				</div>
			</Content>

			<Footer>
				<section>
					<ul className='list'>
						<li>
							<IconFont type='icon-fast' /> 快速使用
						</li>
						<li>
							<IconFont type='icon-team' /> 专业化团队
						</li>
						<li>
							<IconFont type='icon-device' /> 专业设备
						</li>
					</ul>

					<section className='desc'>
						<img className='qrcode' src={QRCode} />

						<div className='context'>
							<h1>租赁宝365 让汽车租赁更简单</h1>
							<p>中小汽车租赁企业“设备+云平台”一站式解决方案</p>
						</div>
					</section>

					<section className='copyright'>
						<div>&copy;{year} DBJ Technologies(Zhu Hai) Co.,Ltd. All rights reserved.</div>

						<a href='https://beian.miit.gov.cn' target='_blank'>
							<img src={recordImg} style={{ marginRight: '5px' }} />
							粤ICP备12019898号
						</a>
					</section>
				</section>
			</Footer>
		</Main>
	)
})
