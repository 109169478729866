import { RootState } from '@/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface MonitorStore {
	isLeftPanelOpen: boolean
	isSinglePlay: boolean
	showBox: boolean
	singlePlayChannel: number
	pauseAllVideo: boolean
	monitorId: string | null
	monitorSn: string | null
}

const initialState: MonitorStore = {
	isLeftPanelOpen: true,
	isSinglePlay: false,
	showBox: false,
	singlePlayChannel: 0,
	pauseAllVideo: false,
	monitorId: null,
	monitorSn: null,
}

export const monitorSlice = createSlice({
	name: 'monitor',
	initialState,
	reducers: {
		setLeftPanelOpen: (state, action: PayloadAction<boolean>) => {
			state.isLeftPanelOpen = action.payload
		},
		setShowBox: (state, action: PayloadAction<boolean>) => {
			state.showBox = action.payload
		},
		setPauseAllVideo: (state, action: PayloadAction<boolean>) => {
			state.pauseAllVideo = action.payload
		},
		setPlayMonitor: (state, action: PayloadAction<{ playChannel: number; id: string; sn: string }>) => {
			const { playChannel, id, sn } = action.payload
			state.showBox = true
			state.monitorId = id
			state.monitorSn = sn
			if (playChannel) {
				state.singlePlayChannel = playChannel
				state.isSinglePlay = true
			} else {
				state.isSinglePlay = false
				state.singlePlayChannel = 0
			}
		}
	}
})

export const { setLeftPanelOpen, setShowBox, setPlayMonitor, setPauseAllVideo } = monitorSlice.actions

export const getMonitorState = (state: RootState) => state.monitor
