import AlertIcon from '@/components/AlertIcon'
import { NotificationTypes } from '@/utils/constants'
import { Checkbox, Form } from 'antd'
import { memo, useEffect } from 'react'
import './index.less'

interface TableFormProps {
	value?: any
	onChange?: (value: any) => void
}

export default memo<TableFormProps>(function TableForm(props) {
	const [form] = Form.useForm()
	const { value, onChange } = props

	useEffect(() => {
		if (value) {
			const data: any = {}
			Object.keys(value).forEach(key => {
				data[key] = numberToValue(value[key].on)
			})
			form.setFieldsValue({ ...data })
		}
	}, [props])

	const numberToValue = (num: number) => {
		switch (num) {
			case 0:
				return { message: false, weChat: false }
			case 1:
				return { message: true, weChat: false }
			case 2:
				return { message: false, weChat: true }
			case 3:
				return { message: true, weChat: true }
		}
	}

	const changeToNumber = (value: any) => {
		let sum = 0
		sum += value?.message ? 1 : 0
		sum += value?.weChat ? 2 : 0

		return sum
	}

	return (
		<Form
			form={form}
			component={false}
			onValuesChange={(value, allValue) => {
				Object.values(allValue).forEach((value: any) => {
					value.on = changeToNumber(value)
					value.limit = null
					delete value.message
					delete value.weChat
				})

				onChange?.(allValue)
			}}
		>
			<div className={'table-box'}>
				<table className={'form-table'}>
					<thead>
						<tr>
							<th>告警类型</th>
							<th>消息盒子</th>
							<th>微信公众号</th>
						</tr>
					</thead>
					<tbody>
						{NotificationTypes.slice(1, NotificationTypes.length).map(item => (
							<Form.Item noStyle key={item.id}>
								<tr>
									<td>
										<div className={'text-box'}>
											<AlertIcon type={item.id} />
											{item.text}
										</div>
									</td>
									<td>
										<Form.Item name={[item.name, 'message']} valuePropName={'checked'}>
											<Checkbox />
										</Form.Item>
									</td>
									<td>
										<Form.Item name={[item.name, 'weChat']} valuePropName={'checked'}>
											<Checkbox />
										</Form.Item>
									</td>
								</tr>
							</Form.Item>
						))}
					</tbody>
				</table>
			</div>
		</Form>
	)
})
