import { TraceData } from '@/services/replay/replay.model'
import { loadVehicleTrack } from '@/services/replay/replay.service'
import { Vehicle } from '@/services/vehicle/vehicle.model'
import { getVehicleById } from '@/services/vehicle/vehicle.service'
import { RootState } from '@/store'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

export type PlayStates = 'play' | 'pause' | 'stop' | 'played'

export interface ActiveSection {
	type: 'stop' | 'moving'
	target: number | [number, number]
}

export type PosType = 'satellite' | 'station'

export interface ReplayStore {
	vehicle?: Vehicle
	trackData?: TraceData | undefined
	activeSection?: ActiveSection
	showTimeline: boolean
	showDate: boolean
	showTrackOverview: boolean
	playState: PlayStates
	playIndex: number
	playSpeed: number
  posType: string
}

const initialState: ReplayStore = {
	activeSection: undefined,
	showTimeline: true,
	showDate: true,
	showTrackOverview: true,
	playState: 'stop',
	playIndex: 0,
	playSpeed: 1,
  posType: 'satellite'
}

export const fetchVehicle = createAsyncThunk('user/fetchVehicle', async (id: string) => {
	return await getVehicleById(id)
})

export const fetchVehicleTrack = createAsyncThunk('user/fetchVehicleTrack', async (payload: { id: string; params: any }) => {
	return await loadVehicleTrack(payload.id, payload.params)
})

export const replaySlice = createSlice({
	name: 'replay',
	initialState,
	reducers: {
		setActiveSection: (state, action: PayloadAction<ActiveSection>) => {
			state.activeSection = action.payload
		},
		setPosType: (state, action: PayloadAction<PosType>) => {
			state.posType = action.payload
		},
		setPlayState: (state, action: PayloadAction<PlayStates>) => {
			state.playState = action.payload
		},
		setPlayIndex: (state, action: PayloadAction<number>) => {
			state.playIndex = action.payload
		},
		setPlaySpeed: (state, action: PayloadAction<number>) => {
			state.playSpeed = action.payload
		},
		toggleTimeline: state => {
			state.showTimeline = !state.showTimeline
		},
		toggleDate: state => {
			state.showDate = !state.showDate
		},
		setTrackOverview: (state, action: PayloadAction<boolean>) => {
			state.showTrackOverview = action.payload
		},
    setTrackData: (state, action: PayloadAction<TraceData | undefined>) => {
      state.trackData = action.payload
    }
	},
	extraReducers: builder => {
		builder.addCase(fetchVehicle.fulfilled, (state, action) => {
			state.vehicle = action.payload
		})
		builder.addCase(fetchVehicleTrack.fulfilled, (state, action) => {
			return {
				...state,
				...initialState,
        posType: state.posType,
				trackData: action.payload
			}
		})
	}
})

export const { setTrackData, setPosType, setActiveSection, toggleTimeline, toggleDate, setTrackOverview, setPlayState, setPlayIndex, setPlaySpeed } =
	replaySlice.actions

export const selectReplay = (state: RootState) => state.replay
