import { ApiAddress } from '@/services/apis'
import request from '@/services/request'
import { CorpDataNode, Corporation, CorpTreeItem } from './corporation.modal'

export function loadCorpTree(): Promise<CorpTreeItem[]> {
	return request.get(ApiAddress.CORP_TREE)
}

export function loadCorporation() {
	return request.get<any, Corporation>(ApiAddress.CORP).then(res => {
		return res
	})
}

export function updateCorporation(data: Corporation) {
	return request.put(ApiAddress.CORP, data)
}

export function listToTree(list: CorpTreeItem[] = [], parent: string | number) {
	const result: CorpDataNode[] = []
	list.forEach(item => {
		if (item.pid === parent) {
			result.push({
				...item,
				title: item.name,
				key: item.id,
				children: listToTree(list, item.id)
			})
		}
	})

	return result
}

export function filterTree(tree: CorpDataNode[], text: string | undefined) {
	if (!text) {
		return tree
	}

	const getNodes = (result: CorpDataNode[], object: any) => {
		if (object.name.toLowerCase().includes(text.toLowerCase())) {
			result.push(object)
			return result
		}
		if (Array.isArray(object.children)) {
			const children = object.children.reduce(getNodes, [])
			if (children.length) result.push({ ...object, children })
		}
		return result
	}

	return tree.reduce(getNodes, [])
}
