import styled from '@emotion/styled'
import logoImagePath from '@/assets/logo.svg'

interface LogoProps {
	width?: number
	height?: number
}

export const Logo = styled.div<LogoProps>`
	width: ${props => `${props.width || 140}px`};
	height: ${props => `${props.height || 30}px`};
	background: url(${logoImagePath}) no-repeat;
	background-size: contain;
`

interface FullContainerProps {
	flexDirection?: string
}

export const FullContainer = styled.div<FullContainerProps>`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: ${props => `${props.flexDirection || 'row'}`};
	.empty-box {
		height: inherit;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`

export const FullCenter = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`
