import { ApiAddress } from '@/services/apis'
import { CheckSMSData, LoginData, SMSCodeData } from '@/services/auth/auth.model'
import request from '@/services/request'
import { message } from 'antd'
import md5 from 'blueimp-md5'

export function login(data: LoginData) {
	if (data.auth_method === 0) {
		data.password = md5(data.password)
	}

	return request.post(ApiAddress.AUTHENTICATION, data)
}

export function logout() {
	return request.delete(ApiAddress.AUTHENTICATION)
}

export function register(data: any) {
	data.password = md5(data.password)

	return request.post(ApiAddress.REGISTRATION, data)
}

export function resetPassword(data: any) {
	data.password = md5(data.password)

	return request.post(ApiAddress.USER_PASSWORD_RESET, data)
}

export function sendSMSCode(data: SMSCodeData) {
	return request.post(ApiAddress.CAPTCHA_SMS, data).then(data => {
		message.success('验证码已发送，请查收短信')

		return Promise.resolve(data)
	})
}

export function checkUser(id: number, notShowMessage = true): Promise<{ secret: string }> {
	return request.get(ApiAddress.USER_EXISTENCE, {
		params: { id, notShowMessage }
	})
}

export function checkSMSCode(data: CheckSMSData): Promise<{ secret: string }> {
	return request.post(ApiAddress.CAPTCHA_SMS_VALIDATION, data)
}

export function checkCaptcha(captcha: string): Promise<{}> {
	return request.post(ApiAddress.CAPTCHA_IMAGE_VALIDATION, { captcha })
}

export function getCaptchaSrc(): Promise<string> {
	return new Promise(resolve => {
		resolve(`${ApiAddress.CAPTCHA_IMAGE}?t=${Date.now() * Math.random()}`)
	})
}

export function exportInstallData(params: any) {
	return request.get(ApiAddress.EXPORT_INSTALL, { params })
}
