import zhCN from '@/locales/zh_CN'
import { store } from '@/store'
import { ThemeProvider } from '@emotion/react'
import { ConfigProvider as AntdProvider } from 'antd'
import antdZhCN from 'antd/lib/locale/zh_CN'
import { ReactChild } from 'react'
import { IntlProvider } from 'react-intl'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
// @ts-ignore
import { theme } from './theme'
interface ProviderContextProps {
	children: ReactChild
}

export const ProviderContext = ({ children }: ProviderContextProps) => {
	return (
		<IntlProvider locale={navigator.language} messages={zhCN}>
			<AntdProvider locale={antdZhCN}>
				<ReduxProvider store={store}>
					<BrowserRouter>
						<ThemeProvider theme={theme}>{children}</ThemeProvider>
					</BrowserRouter>
				</ReduxProvider>
			</AntdProvider>
		</IntlProvider>
	)
}
