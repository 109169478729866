import { memo, useEffect, useState } from 'react'
import { DatePicker } from 'antd'
import { activationTime } from '@/components/SelfRangePicker'
import { RangeValue } from 'rc-picker/lib/interface'
import dayjs, { Dayjs } from 'dayjs'
import { selectDays } from '@/utils/utils'
import './index.less'

export interface SelfDatePickerProps {
	value?: [Dayjs, Dayjs]
	showToday?: boolean
	displayTime?: boolean
	allowClear?: boolean
	disabledDate?: any
	onChange?: (value: activationTime) => void
}

export default memo<SelfDatePickerProps>(function SelfDatePicker(props) {
	const { value, showToday = false, displayTime = false, allowClear = true, disabledDate = null, onChange } = props
	const initTime = showToday ? selectDays(0, 'day') : selectDays(1, 'day')
	const [timeValue, setTimeValue] = useState<RangeValue<Dayjs>>(initTime)

	useEffect(() => {
		if (value?.length) {
			setTimeValue(value)
		}
	}, [value])

	useEffect(() => {
		dateSave(timeValue)
	}, [timeValue])

	const dateSave = (values: RangeValue<Dayjs>) => {
		if (values && values.length) {
			let start_time
			let end_time
			if (values[0] === null) {
				end_time = displayTime ? dayjs(values[1]).unix() : dayjs(values[1]).endOf('d').unix()
				onChange?.({ start_time: 0, end_time, isDisable: true })
			} else if (values[1] === null) {
				start_time = displayTime ? dayjs(values[0]).unix() : dayjs(values[0]).startOf('d').unix()
				onChange?.({ start_time, end_time: 0, isDisable: true })
			} else {
				start_time = displayTime ? dayjs(values[0]).unix() : dayjs(values[0]).startOf('d').unix()
				end_time = displayTime ? dayjs(values[1]).unix() : dayjs(values[1]).endOf('d').unix()

				onChange?.({ start_time, end_time, isDisable: false })
			}
		}
	}

	const calendarChange = (date: any) => {
		if (!date) {
			onChange?.({ start_time: 0, end_time: 0, isDisable: true })
		}
	}

	return (
		<DatePicker.RangePicker
			disabledDate={disabledDate}
			allowClear={allowClear}
			dropdownClassName={displayTime ? '' : 'picker'}
			value={timeValue}
			format={displayTime ? 'YYYY年M月D日 HH:mm:ss' : 'YYYY年M月D日'}
			style={{ width: displayTime ? '400px' : '320px' }}
			showTime={true}
			onOk={dateSave}
			onChange={value => {
				setTimeValue(value)
			}}
			onCalendarChange={date => calendarChange(date)}
		></DatePicker.RangePicker>
	)
})
