import TableForm from '@/components/NotificationModal/TableForm'
import { NotificationSetting } from '@/services/notification/notification.model'
import { loadNotificationSetting, updateNotificationSetting } from '@/services/notification/notification.service'
import { fetchUser } from '@/store/user/user.slice'
import { NotificationTypes } from '@/utils/constants'
import { ModalForm } from '@ant-design/pro-form'
import { useRequest } from 'ahooks'
import { Divider, Form, InputNumber, Space, Switch, Typography } from 'antd'
import { cloneDeep } from 'lodash-es'
import { memo, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

type settingKey = keyof NotificationSetting

export default memo<{ openNote: boolean; onChange?: (value: boolean) => void }>(function NotificationModel(props) {
	const { openNote, onChange } = props
	const [form] = Form.useForm()
	const dispatch = useDispatch()
	const [isVisible, setIsVisible] = useState(false)
	const { data, run } = useRequest(loadNotificationSetting, { manual: true })

	useEffect(() => {
		setIsVisible(openNote)
	}, [openNote])

	return (
		<ModalForm
			form={form}
			title='告警设置'
			width={590}
			layout={'vertical'}
			visible={isVisible}
			omitNil={false}
			onClick={e => e.stopPropagation()}
			modalProps={{
				destroyOnClose: true,
				onCancel: () => {
					setIsVisible(false)
					onChange?.(false)
				}
			}}
			onVisibleChange={visible => {
				visible &&
					run().then(setting => {
						let types: any[] = []
						for (const [key, value] of Object.entries(setting)) {
							if (NotificationTypes.find(item => item.name === key)) {
								// @ts-ignore
								types.push([key, value])
							}
						}
						const entries = new Map(types)
						form.setFieldsValue({
							types: Object.fromEntries(entries),
							long_stop: setting.long_stop.limit[0],
							over_speed: setting.over_speed.limit,
							voice: setting.voice.on,
							over_mileage_limit_days: setting.over_mileage_limit.limit.days,
							over_mileage_limit_mileage: setting.over_mileage_limit.limit.mileage
						})
					})
			}}
			onFinish={async values => {
				let setting = cloneDeep(data as NotificationSetting)
				setting = { ...setting, ...values.types }
				setting.self_test_error = { on: 1, limit: null }
				setting.wait4emergency = { on: 1, limit: null }
				setting.voice.on = values.voice ? 1 : 0
				setting.over_speed.limit = values.over_speed
				setting.over_mileage_limit.limit = {
					days: values.over_mileage_limit_days,
					mileage: values.over_mileage_limit_mileage
				}
				setting.long_stop.limit = [values.long_stop, 72]
				await updateNotificationSetting(setting)
				dispatch(fetchUser())

				onChange?.(false)
			}}
		>
			<Form.Item label='请选择需要通知的告警类型' name='types'>
				<TableForm />
			</Form.Item>

			<Divider />

			<Form.Item label={<Typography.Title level={5}>长时间限制</Typography.Title>}>
				<Space>
					<span>72小时内车辆停留时间超过</span>
					<Form.Item name='long_stop' noStyle rules={[{ required: true, message: '必填' }]}>
						<InputNumber min={24} max={71} />
					</Form.Item>
					<span>小时，则提醒长时间停留</span>
				</Space>
			</Form.Item>

			<Form.Item label={<Typography.Title level={5}>超速限制</Typography.Title>}>
				<Space>
					<span>车辆速度超过</span>
					<Form.Item name='over_speed' noStyle rules={[{ required: true, message: '必填' }]}>
						<InputNumber min={80} max={120} />
					</Form.Item>
					<span>km/h时，则提醒超速</span>
				</Space>
			</Form.Item>

			<Form.Item label={<Typography.Title level={5}>行驶限制</Typography.Title>}>
				<Space wrap={true}>
					<span>车辆在</span>
					<Form.Item name='over_mileage_limit_days' noStyle rules={[{ required: true, message: '必填' }]}>
						<InputNumber min={0} max={31} />
					</Form.Item>
					<span>天内，行驶里程超过</span>
					<Form.Item name='over_mileage_limit_mileage' noStyle rules={[{ required: true, message: '必填' }]}>
						<InputNumber min={0} max={10000} />
					</Form.Item>
					<span>公里时，则提醒行驶过久</span>
				</Space>
			</Form.Item>

			<Divider />

			<div>
				<Form.Item name='voice' noStyle valuePropName='checked'>
					<Switch checkedChildren='开启' unCheckedChildren='关闭' />
				</Form.Item>
				<span> 有新通知时播放提示音 </span>
			</div>
		</ModalForm>
	)
})
