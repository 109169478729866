import { Skeleton } from 'antd'
import { memo, Suspense } from 'react'
import { FullContainer } from '@/components'
import { Outlet } from 'react-router-dom'

export default memo(() => {
	return (
		<FullContainer>
			<Suspense fallback={<Skeleton active />}>
				<Outlet />
			</Suspense>
		</FullContainer>
	)
})
