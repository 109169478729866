import { PositionInfo } from '@/services/modes'
import { DeviceStatus, Terminal } from '@/services/terminal/terminal.model'
import { VehicleMoveStatus, VehicleStatus } from '@/utils/constants'

export interface BaseNode {
	id: string
	pid: string
	name: string
	type?: string
}

interface VehicleTips {
	// From Service
	id: string // 车辆数据id
	pid: string // 车队id
	cid: string // 车辆id
	name: string // 车辆显示名称 （根据账号设置显示模式 name_show 显示 车架号/车牌号）
	vin: string // 车架号
	cnum: string // 车牌号
	icon: string // 车标icon  0-客车  1-货车  2-小车  3-摩托车
	plate_color_name: string //车牌颜色名称  例如：普通蓝牌  对应src/utils/constants.ts 下 plateColors
	remark: string // 车辆备注   车辆信息编辑页面 车辆备注 修改
	model_name: string // 车型名称   车辆信息编辑页面 车型 修改
	create_time: number // 车辆数据生成时间
	emergency_status: number // 紧急模式状态 无紧急-1，正常0，待紧急1，紧急2
	position: PositionInfo // 车辆定位信息
	terminals: Terminal[] // 车辆设备信息
	mileage: number // 车辆剩余里程
	total_mileage: number // 车辆总里程
	oil: number // 车辆油量百分比

	// Calc Value
	isEmptyPos: boolean // 是否为空（无效）位置点 根据position字段的经纬度判断是否为空坐标点或无效坐标点
	status: VehicleStatus // 车辆行驶，停留，离线（未知）状态 （根据该车下设备最新上报status_change_time状态点的status确定，空位置点isEmptyPos 为 0 未知状态）
	sportStatus: VehicleMoveStatus // 车辆运动状态
	login: boolean // 车辆是否登陆 该车辆下任意设备login字段为1 则为 true  反之 false
	statusChangeTime: number //  车辆状态更改时间 该车辆下设备最新的status_change_time为准 若设备为isEmptyPos 或 status 为 0 则 直接返回 0
	color: string // 根据车辆下设备login及work_mode判断 orange(待紧急模式) normal(正常)
	shadowColor: string // 根据颜色与车辆通信状态 根据车辆下设备calc字段color（根据设备login及work_mode判断）判断
	exceptions: VehicleException[] // 车辆异常
	fuelDevice: Terminal // 控油设备
	accDevice: Terminal // acc设备
	carStatus: CarStatus // 车身数据（车身数据均为From Service直接给出的封装到这一层）
	lightDevices: Terminal[] // 感光设备
	vehicleStatus: DeviceStatus // 车辆状态 离线-3 普通-2 待紧急-1 紧急-0
	disconnect: boolean // 设备拔出 该车下设备 alarm字段 &8 后是否等于8
	workModeDevice?: Terminal // 该车下设备 match 及 work_mode 判断
	onlyWireless: boolean //该车是否只有无线设备，且未开启紧急模式
	gps: number // gps 信号 该车下设备gps字段最高者
}

export interface Vehicle extends BaseNode, CarStatus {
	alarm: number //判断设备拔出的条件 无
	vin: string // 车架号
	name: string
	cnum: string // 车牌号
	icon: number
	plate_color_name?: number //车牌颜色名称
	remark?: string
	model_name: string
	create_time: number
	emergency_status: number
	position: PositionInfo
	terminals: Terminal[]
	total_mileage: number // 总里程（km）
	oil: number // 油量百分比
	mileage: number

	// extend properties
	status: VehicleStatus // 1
	login: boolean
	isEmptyPos?: boolean
	statusChangeTime?: number
	shadowColor?: string
	color?: string
	mode?: number //无
	exceptions: VehicleException[]
	trackable?: boolean //无
	fuelDevice?: Terminal
	accDevice?: Terminal
	// for new extends
	vehicleStatus: DeviceStatus
	lightDevices: Terminal[]
	carStatus: CarStatus
	sportStatus: VehicleMoveStatus
	labels: VehicleLabel[] //无
	disconnect: boolean
	workModeDevice?: Terminal
	onlyWireless?: boolean //该车是否只有无线设备，且未开启紧急模式
	gps: number
  posSn: string //当前车辆定位设备SN
}

export interface CarStatus {
	driver_front_door: number // 驾驶侧前门开关 0 - 关 1 - 开
	copilot_front_door: number // 副驾驶侧前门开关 0 - 关 1 - 开
	driver_back_door: number // 驾驶侧后门开关0 - 关 1 - 开
	copilot_back_door: number // 副驾驶侧后门开关0 - 关 1 - 开
	door_lock: number | null // 门锁开关 0 - 开(允许开关门) 1 - 关(禁止开关门) 3 - 设备不支持
	door_window_lf: number // 左前车窗状态 0 - 开 1 - 锁 3 - 不支持
	door_window_rf: number // 右前车窗状态 0 - 开 1 - 锁 3 - 不支持
	door_window_lb: number // 左后车窗状态 0 - 开 1 - 锁 3 - 不支持
	door_window_rb: number // 右后车窗状态 0 - 开 1 - 锁 3 - 不支持
	car_key: number // 车钥匙 0: 车钥匙LOCK, 1: 车钥匙ACC, 2: 车钥匙ON, 3: 车钥匙START
	trunk: number // 车尾箱状态 0 - 关闭 1 - 打开 3 - 不支持
	low_beam: number // 近光灯状态 0 - 开 1 - 关 3 - 不支持
	high_beam: number // 远光灯状态 0 - 开 1 - 关 3 - 不支持
	position_lamp: number // 示宽灯状态 0 - 开 1 - 关 3 - 不支持
	emergency_lamp: number // 紧急灯状态 0 - 开 1 - 关 3 - 不支持
	foglight_front: number // 前雾灯状态 0 - 开 1 - 关 3 - 不支持
	foglight_back: number // 后雾灯状态 0 - 开 1 - 关 3 - 不支持
	total_mileage: number // 总里程（km）
	speed: number // OBD 速度 KM/H
	oil: number // 油量百分比
	rmp: number // 转速
	brake: number // 制动踏板 0 - 未刹车 1~9 - 制动踏板角度(部分车支持) 10 - 刹车 255 - 不支持
	parking: number // 驻车制动 0 - 未驻车 1 - 已驻车 255 - 不支持
	mileage: number
}

export interface VehicleException {
	name: string
	status?: number
	priority: number
}

export interface VehicleUpdateRequest {
	cnum?: string
	icon: number
	light_setting?: { [key: string]: boolean }
	remark?: string
	vin: string
	model_name: string
}

export interface VehicleBatchUpdateRequest {
	row_no: string
	status: number
	group_name: string
	icon_name: string
	message: string
	plate_color_name: string
	cnum: string
	remark: string
	vin: string
	model_name: string
}

export interface TerminalPositions {
	tid: string
	base_station?: PositionInfo
	gps?: PositionInfo
	wifi?: PositionInfo
}

export interface VehicleTerminalsPositions {
	id: string
	terminals: TerminalPositions[]
}

export enum VehicleLabel {
	Offline = '离线',
	OverSpeed = '超速',
	Disconnect = '设备拔出',
	CanNotLogin = '不能登录',
	FuelCut = '断油',
	Emergency = '紧急模式',
	ToBeEmergency = '待紧急'
}

export interface Group {
	id: string
	name: string
	vIds: string[]
}

export interface VehicleCloudControlRequest {
	action: string
}
