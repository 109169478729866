import { Checkbox, Col, Row } from 'antd'
import { memo, useEffect, useState } from 'react'

const exportOptions = [
	{ value: 'cnum', label: '车牌号码', disabled: true },
	{ value: 'vin', label: '车架号', disabled: true },
	{ value: 'sn', label: '设备SN', disabled: true },
	{ value: 'terminal_type', label: '设备型号' },
	{ value: 'group_name', label: '车队名' },
	{ value: 'terminal_status', label: '设备状态' },
	{ value: 'bind_time', label: '绑定时间' },
	{ value: 'activate_time', label: '激活时间' },
	{ value: 'install_time', label: '安装时间' },
	{ value: 'positions', label: '当前位置' },
	{ value: 'address', label: '地址' },
	{ value: 'vehicle_type', label: '车型' },
	{ value: 'vehicle_remark', label: '备注' },
	{ value: 'wireless_pbat', label: '无线设备剩余电量' }
]

const defaultOptions = [
	{ value: 'cnum', label: '车牌号码', disabled: true },
	{ value: 'vin', label: '车架号', disabled: true },
	{ value: 'sn', label: '设备SN', disabled: true }
]

interface ContentProps {
	value?: any[]
	onChange?: (value: string[]) => void
}

export default memo<ContentProps>(function ExportContent(props) {
	const { value, onChange } = props
	const [checkedList, setCheckedList] = useState<string[]>([])
	useEffect(() => {
		if (value?.length) {
			setCheckedList(value)
		}
	}, [value])

	const singleOnChange = (options: any) => {
		setCheckedList(options)
		onChange?.(options)
	}

	return (
		<>
			<Checkbox.Group value={checkedList} onChange={options => singleOnChange(options)}>
				<Row gutter={[8, 12]}>
					{exportOptions.map(option => (
						<Col key={option.value} span={['wireless_pbat'].includes(option.value) ? 12 : 6}>
							<Checkbox value={option.value} disabled={option.disabled}>
								{option.label}
							</Checkbox>
						</Col>
					))}
				</Row>
			</Checkbox.Group>
		</>
	)
})
