import { useAMapEvents } from '@/libs/react-amap/components/common/hooks'
import { useEffect, useImperativeHandle, useState, forwardRef } from 'react'
import { OverlayPropsEvents } from '../types'

export const CircleEvents = [
	'onClick',
	'onDblclick',
	'onRightClick',
	'onHide',
	'onShow',
	'onMousedown',
	'onMouseup',
	'onMouseover',
	'onMouseout',
	'onChange',
	'onTouchstart',
	'onTouchmove',
	'onTouchend'
] as const

type CircleEventsProps = typeof CircleEvents[number]

export type CircleProps = AMap.Circle.Options &
	OverlayPropsEvents<CircleEventsProps> & {
		onInit?: (circle: AMap.Circle) => void
		isEditor?: boolean
	}

export const AMapCircle = forwardRef<AMap.Circle, CircleProps>((options, ref) => {
	const { map, isEditor, onInit } = options
	const {
		zIndex,
		center,
		bubble,
		cursor,
		radius,
		strokeColor,
		strokeOpacity,
		strokeWeight,
		fillColor,
		fillOpacity,
		strokeStyle,
		extData,
		strokeDasharray
	} = options
	const [circle, setCircle] = useState<AMap.Circle>()
	const [circleEditor, setCircleEditor] = useState<AMap.CircleEditor>()

	useEffect(() => {
		if (!map) return
		const instance = new AMap.Circle({
			zIndex,
			center,
			bubble,
			cursor,
			radius,
			strokeColor,
			strokeOpacity,
			strokeWeight,
			fillColor,
			fillOpacity,
			strokeStyle,
			extData,
			strokeDasharray
		})
		instance.setMap(map)
		setCircle(instance)
		onInit?.(instance)

		return () => {
			instance.setMap(null)
			circleEditor?.close()
		}
	}, [])

	useEffect(() => {
		circle?.setOptions({
			zIndex,
			center,
			bubble,
			cursor,
			radius,
			strokeColor,
			strokeOpacity,
			strokeWeight,
			fillColor,
			fillOpacity,
			strokeStyle,
			extData,
			strokeDasharray
		})
	}, [
		zIndex,
		center,
		bubble,
		cursor,
		radius,
		strokeColor,
		strokeOpacity,
		strokeWeight,
		fillColor,
		fillOpacity,
		strokeStyle,
		extData,
		strokeDasharray
	])

	useEffect(() => {
		if (!circle) return

		if (isEditor) {
			if (circleEditor) {
				circleEditor.open()
			} else {
				map?.plugin('AMap.CircleEditor', () => {
					const editor = new AMap.CircleEditor(map, circle!)
					setCircleEditor(editor)
					editor.open()
				})
			}
		} else {
			circleEditor?.close()
		}

		return () => {
			circleEditor?.close()
		}
	}, [isEditor, circle, circleEditor])

	useAMapEvents<AMap.Circle>(circle!, options, CircleEvents)

	useImperativeHandle(ref, () => circle!)

	return null
})
