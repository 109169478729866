import {
	ActivityLog,
	ActivityLogReq,
	ControlLog,
	ControlLogRequest,
	DeviceStatus,
	Terminal,
	TerminalCommunicateTime
} from './terminal.model'
import request from '@/services/request'
import { ApiAddress } from '@/services/apis'
import { PositionInfo } from '@/services/modes'
import getAppConfig from '@/env-configs'
import { TerminalType, TerminalWorkMode } from '@/utils/constants'

export const devStatus = ['工作正常', '异常离线', '电量耗尽', '电量低于20%', '设备拔出', '设备未开机', '电量未知']
export function getDevStatusText(dev_status: number) {
	return devStatus[dev_status] || '-'
}

export function getTerminalById(id: string): Promise<Terminal> {
	return request.get(ApiAddress.TERMINAL, { params: { id } })
}

export function getTerminalActivities(params: ActivityLogReq) {
	return request.get(ApiAddress.TERMINAL_ACTIVITY_HISTORY, { params: params }).then((res: any) => {
		return <ActivityLog[]>res
	})
}

export function updateMode(id: string, status: number) {
	return request.put(ApiAddress.TERMINAL_MODE, { status }, { params: { id } })
}

export interface TerminalSettings {
	wifi?: number
	light?: number
	gps?: number
}
export function updateSettings(id: string, settings: TerminalSettings) {
	return request.put(ApiAddress.TERMINAL_SETTING, settings, { params: { id } })
}

export function getTerminalPositions(id: string) {
	return request.get(ApiAddress.TERMINAL_POSITIONS, { params: { id } }).then((positions: any) => {
		const appConfig = getAppConfig()
		return positions.map((position: PositionInfo) => {
			const lat = position.latitude / 3600000
			const lng = position.longitude / 3600000
			const [trueLng, trueLat] = appConfig.coordTransform(lng, lat)
			position.latitude = trueLat
			position.longitude = trueLng

			return position
		})
	})
}

export function getTerminalControlLogs(params: ControlLogRequest) {
	return request
		.get(ApiAddress.TERMINAL_FUEL_LOGS, {
			params
		})
		.then((res: any) => <ControlLog[]>res)
}

export function setFuelControl(id: string, body: any) {
	return request.put(ApiAddress.TERMINAL_FUEL_SETTING_FUEL, body, {
		params: { id }
	})
}

export function getCommunicateTime(ids: string[]): Promise<TerminalCommunicateTime[]> {
	const search = ids.map(id => `ids=${id}&`).join('')

	return request.get(`${ApiAddress.TERMINAL_COMMUNICATE_TIME}?${search}`)
}

export function calcTerminalExtends(terminal: Terminal): Terminal {
	terminal.wireless = isLongStand(terminal.firmware_version)
	terminal.work_mode = calculateWorkMode(terminal)
	terminal.dev_status = calculateDeviceStatus(terminal)
	terminal.deviceStatus = calcTerminalStatus(terminal)
	terminal.full_type = TerminalType[terminal.firmware_version] || 'unsupport'
	terminal.match = calculateMatchStatus(terminal)
	terminal.color = calculateDeviceColor(terminal)
	terminal.GPSEnabled = !!parseInt(terminal.mode.gps_status, 10)
	terminal.deviceType = calcDeviceType(terminal)

	return terminal
}

export function calcDeviceType(terminal: Terminal) {
	if (terminal.wireless) {
		return '无线'
	}
	if (terminal.firmware_version === 'J') {
		return '车机'
	}
	return '有线'
}

/**
 * @name ColorStatus 颜色与车辆通信状态
 * @constant
 */
export const ColorStatus = {
	Normal: 'green',
	Offline: 'grey',
	IsEmergent: 'red',
	ToBeEmergent: 'orange'
}

export function calculateDeviceColor(terminal: Terminal) {
	if (terminal.login === 0) {
		return ColorStatus.Offline
	} else if (terminal.work_mode === 1) {
		return ColorStatus.ToBeEmergent
	} else if (terminal.work_mode === 2 || terminal.dev_status === 4) {
		return ColorStatus.IsEmergent
	}

	return ColorStatus.Normal
}

export function batteryVoltage(input: number, loginTime: number) {
	if (loginTime) {
		if (input === 65535) {
			return '-'
		}

		return (input / 1000).toFixed(1)
	}

	return '-'
}

export function calcTerminalStatus(dev: Terminal) {
	if (dev.login === 0) {
		return DeviceStatus.Offline
	} else if (dev.work_mode === 1) {
		return DeviceStatus.ToBeEmergent
	} else if (dev.work_mode === 2 || dev.dev_status === 4) {
		return DeviceStatus.IsEmergent
	}

	return DeviceStatus.Normal
}

export function generateBatteryStatus(dev: Terminal) {
	if (dev.login_time) {
		if (dev.dev_status === 2) {
			return '电量耗尽'
		} else if (dev.wireless || dev.charge_status === 0) {
			if (dev.pbat) {
				return `电量： ${dev.pbat}% `
			} else {
				return '-'
			}
		} else {
			return '充电中'
		}
	} else {
		return '电量未知'
	}
}

/**
 * 计算设备的所处模式. 无紧急-1，正常0，待紧急1，紧急2
 * 对211W, 300WL才有意义的属性
 *
 * @param {Object} dev
 * @returns {number}
 */
function calculateWorkMode(dev: Terminal): number {
	if (dev.wireless) {
		return dev.login > 0 ? dev.device_mode : 0
	}

	return -1
}

/**
 * 计算设备状态，优先级为： 正常0，异常离线1，电量耗尽2，低电3，设备拔出4, 设备未开机5
 * @param {Object} dev
 * @returns {number}
 */
export function calculateDeviceStatus(dev: Terminal): number {
	if (dev.login_time === 0) {
		return 5
	}

	if (dev.login === 0) {
		return dev.pbat <= 5 ? 2 : 1
	}

	if (dev.alarm) {
		const result = calcTerminalFlagStatus(dev.alarm, 8)
		if (result) {
			return result
		}
	}

	if (dev.pbat >= 0 && dev.pbat < 20) {
		return 3
	}

	if (!dev.pbat) {
		// undefined, null
		return 6
	}

	return 0
}

export function calcTerminalFlagStatus(num: number, flag: any) {
	if ((num & flag) === 8) {
		return 4
	} else {
		return null
	}
}

export function isLongStand(input: string): boolean {
	switch (input) {
		case 'S':
		case 'T':
		case 'U':
		case 'V':
		case 'W':
		case 'X':
		case 'Y':
		case 'H':
		case 'Z':
		case 'R':
			return true
		default:
			return false
	}
}

function calculateMatchStatus(dev: Terminal): boolean {
	return dev.mode.terminal_mode === TerminalWorkMode.MATCH
}
