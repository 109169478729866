import { Point } from '@/services/geo-fence/geo-fence.modal'
import { Group, Vehicle } from '@/services/vehicle/vehicle.model'
import { LocateType, NotificationTypes, plateColors, TerminalType } from '@/utils/constants'
import md5 from 'blueimp-md5'
import dayjs, { Dayjs, OpUnitType } from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { cloneDeep, isNil, isNumber, omitBy } from 'lodash-es'
// @ts-ignore
import XLSX from 'xlsx'
import { Track } from '@/services/replay/replay.model'
import { activationTime } from '@/components/SelfRangePicker'
// @ts-ignore
import coordtransform from 'coordtransform'
import { getBatchTripAddress } from '@/services/geo-address/geo-address.service'

dayjs.extend(duration)

type PeriodType = 'latestOneHour' | 'today' | 'yesterday' | 'thisWeek' | 'lastWeek' | 'thisMonth' | 'lastMonth'

export function parsePeriod(type: PeriodType): [Dayjs, Dayjs] {
	switch (type) {
		case 'latestOneHour':
			return [dayjs().subtract(1, 'h'), dayjs()]
		case 'today':
			return [dayjs().startOf('d'), dayjs().endOf('d')]
		case 'yesterday':
			return [dayjs().subtract(1, 'd').startOf('d'), dayjs().subtract(1, 'd').endOf('d')]
		case 'thisWeek':
			return [dayjs().startOf('w'), dayjs()]
		case 'lastWeek':
			return [dayjs().subtract(1, 'w').startOf('w'), dayjs().subtract(1, 'w').endOf('w')]
		case 'thisMonth':
			return [dayjs().startOf('m'), dayjs()]
		case 'lastMonth':
			return [dayjs().subtract(1, 'm').startOf('m'), dayjs().subtract(1, 'm').endOf('m')]
	}
}

export function resolveTimeRange(timeRange: [Dayjs, Dayjs]) {
	return {
		start_time: timeRange[0].unix(),
		end_time: timeRange[1].unix()
	}
}

export function lastDays(days = 0): [Dayjs, Dayjs] {
	return [
		dayjs()
			.subtract(days - 1, 'day')
			.startOf('day'),
		dayjs().endOf('day')
	]
}

export function selectDays(days: number, type: OpUnitType): [Dayjs, Dayjs] {
	if (days === 0 && type === 'month') {
		return [dayjs().subtract(days, type).startOf(type), dayjs().subtract(days, type).endOf('day')]
	}
	return [dayjs().subtract(days, type).startOf(type), dayjs().subtract(days, type).endOf(type)]
}

export function dateFormat(unixTime: number, format = 'YYYY-MM-DD HH:mm:ss') {
	if (!unixTime) return '-'
	return dayjs.unix(unixTime).format(format)
}

export function escapeDateFormat(unixTime: number, format = 'YYYY-MM-DD HH:mm:ss') {
	if (unixTime == -1) return '一次性事件无恢复条件'
	if (!unixTime) return '-'
	return dayjs.unix(unixTime).format(format)
}

export function getRandomString() {
	const ts = dayjs().unix().toString(32)
	const random = (Math.random() * 10000).toFixed(0)

	return md5(ts + random)
}

export function arrayLastVal<T>(arr: T[] = []): T {
	return arr[arr.length - 1]
}

export function points2path(points: Point[]): AMap.LocationValue[] {
	return points.map((item: Point) => [item.longitude, item.latitude])
}

export function points2lines(points: Track[], padding = 5000): Point[][] {
	const lines: Point[][] = []

	return points.reduce((lines, point) => {
		const lineLen = lines.length
		const lastLine = lines[lineLen - 1]
		if (!lastLine) {
			lines.push([point])
		} else {
			const lastPoint = <Track>lastLine[lastLine.length - 1]
			if (point.mileage! - lastPoint.mileage! > padding) {
				lines.push([point])
			} else {
				lastLine.push(point)
			}
		}

		return lines
	}, lines)
}

export function calcPolygonMinDistance(polygon: Point[]) {
	const allDistance = polygon.reduce((acc: number[], val: Point, index: number, array: Point[]) => {
		const distances = array
			.map(item => AMap.GeometryUtil.distance([item.longitude, item.latitude], [val.longitude, val.latitude]))
			.filter(item => item > 0)

		return [...acc, ...distances]
	}, [])

	return Math.min(...allDistance)
}

export function distance(value: number) {
	if (!isNumber(value)) {
		return '-'
	}

	return value >= 1000 ? `${(value / 1000).toFixed(2)}公里` : `${value.toFixed(0)}米`
}

export function dayDuration(value: number, format?: string) {
	const d = dayjs.duration(value, 'seconds')

	if (format) {
		return d.format(format)
	}

	if (value === 0) return '已过期'

	if (!value) return '-'

	if (value > dayjs(new Date()).unix()) {
		const now = dayjs(new Date()).format('YYYY-MM-DD')
		const future = dayjs.unix(value).format('YYYY-MM-DD')
		return `${dayjs(future).diff(now, 'day')}天后过期`
	}

	return '已过期'
}

export function timeDuration(value: number, format?: string) {
	const d = dayjs.duration(value, 'seconds')

	if (format) {
		return d.format(format)
	}

	if (d.years() > 0) {
		return d.format('Y[年]M[月]D[天]H[小时]m[分钟]')
	}

	if (d.months() > 0) {
		return d.format('M[月]D[天]H[小时]m[分钟]')
	}

	if (value > 24 * 60 * 60) {
		if (value % (24 * 60 * 60) === 0) {
			return d.format('D[天]')
		}
		return d.format('D[天]H[小时]m[分钟]')
	}

	if (value > 60 * 60) {
		if (value % (60 * 60) === 0) {
			return d.format('H[小时]')
		}
		return d.format('H[小时]m[分钟]')
	}

	if (value > 60) {
		return d.format('m[分钟]')
	}

	return '小于1分钟'
}

export function timeDurationSimple(value: number) {
	const years = dayjs.duration(value, 'seconds').years()
	const months = dayjs.duration(value, 'seconds').months()
	const days = dayjs.duration(value, 'seconds').days()
	const hours = dayjs.duration(value, 'seconds').hours()

	if (years > 0 || months > 0) {
		return '超过7天'
	}

	if (days === 0 && hours >= 3) {
		return '超过3小时'
	}

	if (days === 0 && hours >= 1) {
		return '超过1小时'
	}

	if (days >= 7) {
		return '超过7天'
	}

	if (days >= 3) {
		return `超过3天`
	}

	if (days >= 1) {
		return '超过1天'
	}

	return ''
}

export function calTotal(accumulator: number, currentValue: number): number {
	return accumulator + currentValue
}

export function searchVehicles(vehicles: Vehicle[], text = '') {
	text = text.toLowerCase()

	return vehicles.filter(vehicle => {
		return (
			vehicle.cnum.toLowerCase().includes(text) ||
			vehicle.vin.toLowerCase().includes(text) ||
			vehicle.terminals.filter(t => t.sn.toLowerCase().includes(text)).length > 0
		)
	})
}

export function mileage(value: number) {
	let mileage = '0'
	if (value < 1000) {
		mileage = value.toFixed(0) + ' 米'
	} else {
		mileage = (value / 1000).toFixed(2) + ' 公里'
	}

	return mileage
}

export const ANGLE_NAMES = ['正北', '东北', '正东', '东南', '正南', '西南', '正西', '西北']

export function angleHumanize(angle: number) {
	return ANGLE_NAMES[Math.round(parseFloat(String(angle)) / 45) % 8] || '-'
}

export function exportExcel(data: any[] = [], fileName: string) {
	const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data)
	const wb: XLSX.WorkBook = XLSX.utils.book_new()

	XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
	XLSX.writeFile(wb, `${fileName}.xlsx`)
}

export function exportExcelWithSettings(data: any[] = [], settings: any = {}, fileName: string) {
	const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data)
	const wb: XLSX.WorkBook = XLSX.utils.book_new()

	settings.merges && (ws['!merges'] = settings.merges) //设置导出表格单元格merges

	XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
	XLSX.writeFile(wb, `${fileName}.xlsx`)
}

export function locType(value: LocateType) {
	switch (value) {
		case LocateType.BaseStation:
			return '基站定位'
		case LocateType.GPS:
			return '卫星定位'
		case LocateType.WIFI:
			return 'WIFI定位'
		default:
			return '-'
	}
}

export function staticMapUrl(lng: number, lat: number, name?: string, timestamp?: number, w_content?: string) {
	const origin = window.location.origin
	const params = <{ [key: string]: string }>omitBy(
		{
			lng,
			lat,
			name,
			timestamp,
			w_content
		},
		value => isNil(value) || value === ''
	)
	const query = new URLSearchParams(params).toString()

	return `${origin}/static-pages/static-map?${query}`
}

export function riskVehicleLink(id: string, start_time: number, end_time: number, adCode?: number) {
	const origin = window.location.origin
	const params = <{ [key: string]: string }>omitBy(
		{
			id,
			start_time,
			end_time,
			adCode
		},
		value => isNil(value) || value === ''
	)
	const query = new URLSearchParams(params).toString()

	return `${origin}/features/risk-replay?${query}`
}

export function deviceType(value: string) {
	return TerminalType[value] || 'unsupport'
}

export function getVehicleIdsFromGroup(groups: Group[]) {
	return groups.reduce((ids: string[], g) => {
		return ids.concat(g.vIds)
	}, [])
}

export function notificationType(type: number) {
	return NotificationTypes.find(item => item.id === type)
}

export function notificationText(type: number) {
	const item = notificationType(type)

	return item?.text || 'unknown'
}

export const timeRangeRule = (maxRange: number, text: string = '查询') => ({
	validator: (_: any, value: activationTime) => {
		const errMessage = `只允许${text}${maxRange}天时间范围内的数据`
		if (!value.start_time || !value.end_time) {
			return Promise.reject(new Error('请选择时间'))
		} else if (value.end_time - value.start_time > maxRange * 24 * 3600) {
			return Promise.reject(new Error(errMessage))
		} else {
			return Promise.resolve()
		}
	}
})

export function convertObjectToSearchParams(obj: any): URLSearchParams {
	const params: URLSearchParams = new URLSearchParams()
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			params.set(key, obj[key])
		}
	}

	return params
}

export function calRestBattery(pbat: number, gps: number | boolean, wifi: number | boolean) {
	return ((pbat / 100) * 4800) / (20 + 45 * (gps ? 1 : 0) + 75 * (wifi ? 1 : 0))
}

export function changeToName(value: number) {
	return plateColors.find(item => item.value === value)!.name
}

export function handleAreaCode(areaCode: number) {
	let province
	let city
	let district
	const proPrefix = String(areaCode).slice(0, 2)
	const cityPrefix = String(areaCode).slice(0, 4)
	if (areaCode % 10000 !== 0) {
		province = Number(proPrefix + '0000')
		if (areaCode % 100 !== 0) {
			city = Number(cityPrefix + '00')
			district = areaCode
		} else {
			city = areaCode
			district = ''
		}
	} else {
		province = areaCode
		city = ''
		district = ''
	}

	return {
		province: province,
		city: city,
		district: district
	}
}

export function compare(data: any[], sortKey: string) {
	//sort 排序
	return data.sort((a, b) => {
		let val1 = a[sortKey]
		let val2 = b[sortKey]
		if (val1 < val2) {
			return 1
		} else if (val1 > val2) {
			return -1
		} else {
			return 0
		}
	})
}

export function toPercent(point: number) {
	var percent = Number(point * 100).toFixed(1)
	percent += '%'
	return percent
}

export async function handleBatchAddress(lonLatArr: any[]) {
	const newArr: any = cloneDeep(lonLatArr)
	let promiseArr: any = []
	let locationArr: any = []
	let locationArrNum: number = 0

	newArr.forEach((item: any, index: number) => {
		const gcj02 = coordtransform.gcj02towgs84(item.longitude, item.latitude)
		const location = gcj02[1] + ',' + gcj02[0]

		if (locationArr.length == 100) {
			//若坐标存储数组超过规定数字，则发送一条地址请求并清空坐标存储数组
			promiseArr.push(
				(async (locationArr, locationArrNum) => {
					const geoAddress = await getBatchTripAddress(locationArr)
					geoAddress.forEach((ads, index) => {
						const exportItem = newArr.find((item: any) => item.address_index == locationArrNum * 100 + index)
						exportItem && (exportItem.address = ads.address)
					})
				})(locationArr, locationArrNum)
			)

			locationArrNum += 1
			locationArr = []
		}

		locationArr.push(location)
		item.address_index = locationArrNum * 100 + locationArr.length - 1

		if (index == newArr.length - 1) {
			//若为最后一个点，直接发送当前坐标存储数组并清空
			promiseArr.push(
				(async (locationArr, locationArrNum) => {
					const geoAddress = await getBatchTripAddress(locationArr)
					geoAddress.forEach((ads, index) => {
						const exportItem = newArr.find((item: any) => item.address_index == locationArrNum * 100 + index)
						exportItem && (exportItem.address = ads.address)
					})
				})(locationArr, locationArrNum)
			)
			locationArrNum = 0
			locationArr = []
		}
	})
	await Promise.all(promiseArr)

	return newArr
}

export function hideMoveStatusInfo(vehicle: Vehicle) {
	const onlyWirelessCar = vehicle.onlyWireless //是否只有无线设备且未开启紧急模式
	return onlyWirelessCar
}

export function regExpEscape(literal_string: string) {
	return literal_string.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&')
}

export function getCanControlVehicles(vehicles: Vehicle[]) {
	return vehicles.filter(
		v => (v.fuelDevice && v.fuelDevice.full_type === 'IV100') || (v.fuelDevice && v.fuelDevice.fuel_control_switch)
	)
}

export function canControlVehicle(vehicle: Vehicle) {
	return (
		(vehicle.fuelDevice && vehicle.fuelDevice.full_type === 'IV100') ||
		(vehicle.fuelDevice && vehicle.fuelDevice.fuel_control_switch)
	)
}

export function hasMonitorDevice(vehicle: Vehicle) {
	return vehicle.terminals.find(t => t.full_type === 'CJ001')
}

export function downloadFile(url: any, fileName: string) {
	fetch(url)
	.then((res) => res.blob())
	.then(blob => {
	    const blobUrl = URL.createObjectURL(blob)
	    const a = document.createElement('a')
	    a.download = fileName
	    a.href = blobUrl
	    // For Firefox https://stackoverflow.com/a/32226068
	    document.body.appendChild(a)
	    a.click()
	    a.remove()
	})
	.catch(e => console.error(e))
}
