import { useAMapEvents, useAMapOverlayInputs } from '@/libs/react-amap/components/common/hooks'
import { useEffect, useImperativeHandle, useState, PropsWithChildren, forwardRef } from 'react'
import { OverlayPropsEvents } from '../types'

export const MarkerEvents = [
	'onClick',
	'onDblclick',
	'onRightclick',
	'onMousemove',
	'onMouseover',
	'onMouseout',
	'onMousedown',
	'onMouseup',
	'onDragstart',
	'onDragging',
	'onDragend',
	'onMoving',
	'onMoveend',
	'onMovealong',
	'onTouchstart',
	'onTouchmove',
	'onTouchend'
] as const

export const MarkerInputProps = ['position', 'icon', 'angle', 'label']

type MarkerEventsProps = typeof MarkerEvents[number]

export type MarkerProps = AMap.Marker.Options &
	OverlayPropsEvents<MarkerEventsProps> &
	PropsWithChildren<any> & {
		onInit?: (marker: AMap.Marker) => void
	}

export const AMapMarker = forwardRef<AMap.Marker, MarkerProps>((options, ref) => {
	const { children, onInit, map, position, icon, angle, ...others } = options
	const [marker, setMarker] = useState<AMap.Marker>()

	useEffect(() => {
		if (!map) return
		let instance: AMap.Marker
		if (!marker) {
			instance = new AMap.Marker({
				position,
				...others
			})
			instance.setMap(map)
			setMarker(instance)
			onInit?.(instance)
		}

		return () => {
			if (instance) {
				instance.setMap(null)
				setMarker(undefined)
			}
		}
	}, [])

	useEffect(() => {
		marker?.setzIndex(others.zIndex)
	}, [others.zIndex])

	useAMapOverlayInputs<AMap.Marker>(marker!, MarkerInputProps, options)

	useAMapEvents<AMap.Marker>(marker!, options, MarkerEvents)

	useImperativeHandle(ref, () => marker!)

	return children || null
})
