import request from '@/services/request'
import { ApiAddress } from '@/services/apis'
import { Notification, NotificationSetting } from './notification.model'
// @ts-ignore
import coordtransform from 'coordtransform'

export async function loadNotification(body: any): Promise<Notification> {
	const data: Notification = await request.post(ApiAddress.WARNINGS, body)
	data.list = data.list.map(item => {
		const gcj02 = coordtransform.wgs84togcj02(item.longitude / 3600000, item.latitude / 3600000)
		return {
			...item,
			longitude: gcj02[0],
			latitude: gcj02[1]
		}
	})
	return data
}

export function loadNotificationSetting(): Promise<NotificationSetting> {
	return request.get(ApiAddress.WARNINGS_SETTINGS)
}

export function updateNotificationSetting(body: NotificationSetting) {
	return request.put(ApiAddress.WARNINGS_SETTINGS, body)
}
