import { usePermission } from '@/hooks/usePermission'
import { memo } from 'react'

interface RestrictedProps {
	permission: string
	fallback?: JSX.Element | string
	children?: any
}

export default memo<RestrictedProps>(function Restricted(props) {
	const { permission, fallback, children } = props
	const isAllowedTo = usePermission(permission)

	if (isAllowedTo) {
		return <>{children}</>
	}

	return <>{fallback}</>
})
