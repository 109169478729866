import { IconFont } from '@/components/IconFont'
import { notificationType } from '@/utils/utils'
import styled from '@emotion/styled'
import { memo, useMemo } from 'react'

interface AlertIconProps {
	type: number
}

export default memo<AlertIconProps>(function AlertIcon({ type }) {
	const typeItem = useMemo(() => notificationType(type), [type])

	if (!typeItem) return null

	return (
		<IconContainer bgColor={typeItem.bgColor!}>
			<IconFont type={typeItem.icon} />
		</IconContainer>
	)
})

const IconContainer = styled.span<{ bgColor: string }>`
	display: inline-block;
	border-radius: 5px;
	width: 25px;
	height: 25px;
	line-height: 25px;
	text-align: center;
	background: ${props => props.bgColor};
	color: #fff;
	font-size: 20px;
	margin: 0 2px;
`
