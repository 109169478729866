const zhCN = {
	'pages.login.login': '登录',
	'pages.login.username': '账号',
	'pages.login.username.placeholder': '请输入用户名',
	'pages.login.username.required': '用户名是必填项！',
	'pages.login.password': '密码',
	'pages.login.password.placeholder': '请输入密码',
	'pages.login.password.required': '密码是必填项！'
}

export default zhCN
