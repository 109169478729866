import { memo, useEffect } from 'react'

export interface AMapSatelliteLayerProps {
	map?: AMap.Map
}
const AMapSatelliteLayer = memo((props: AMapSatelliteLayerProps) => {
	const { map } = props
	useEffect(() => {
		const satelliteLayer = new AMap.TileLayer.Satellite({ map })

		return () => {
			satelliteLayer.hide()
			satelliteLayer.setMap(null)
		}
	}, [])
	return null
})

export default AMapSatelliteLayer
