import { PositionInfo } from '@/services/modes'

export interface Terminal {
	tid: string
	sn: string
	mode: TerminalMode
	type: string
	activate_code: string
	device_mode: number
	activate_time: number
	endtime: number //过期时间
	emergency_time: number
	emergency_reason: number
	status_change_time: number
	gsm: number
	gps: number
	pbat: number
	login: number // 登录状态,1 = 登录,0 = 未登录, 2 = 待机
	login_time: number
	status: number // 0 = 未知状态 1 = 停留,2 = 行驶
	battery_voltage: number // 外接电源电压
	charge_status: number
	alarm: number // 几种告警模式的标志位  1.ZJ210长时间停留状态  2. ZJ211检测电瓶低电状态  4.设备超速状态 8.设备拔出
	firmware_version: string
	position: PositionInfo
	light: number
	light_status: number
	update_time: number
	wifi: number
	wifi_status: number
	// 引擎状态
	// 0 – 不支持该参数
	// 1 – 发动机点火
	// 2 – 发动机熄火
	engine?: number
	fuel_cut: FuelAction[]
	endurance: number // 续航里程（km）0 ~ 65535
	// extend property
	wifiable?: boolean
	matchable?: boolean
	lightSensible?: boolean
	wireless?: boolean
	full_type?: string
	name?: string
	GPSEnabled?: boolean
	dev_status?: number
	work_mode?: number
	match?: boolean
	trackable?: boolean
	color?: string
	fuel_status: number // 油电断通状态 0 - 供油/电  1 - 断油/电
	acc: number
	acc_change_time: number
	acc_switch: number
	deviceStatus: DeviceStatus
	begintime?: number
	last_pkt_time?: number
	hb_time?: number
	deviceType: string
	fuel_control_switch: number // 油路控制显示开关 1 开  0 关
  cloud_key_status: 1 | 0 //当前车辆云钥匙状态  -1-不支持  0-未分享 1-待领取 2-使用中 3-已过期
  camera_channels?: string[] //显示视频设备可用channel通道 ['1','2','3','4'] 暂无此功能
}

export interface FuelAction {
	type: 'dormant' | 'wired' // dormant: 暗锁断油, wired: 有线断油
	status: number // 当前油电断通状态（实际值）0 - 供油/电 1 - 断油/电
	exp_status: number // 下发油电断通状态（期望值）0 - 供油/电 1 - 断油/电
	exe_status: number // -1:指令下发中 0:未执行 1:已执行-成功 2: 已执行-失败
}

export interface TerminalMode {
	terminal_mode: string
	param_interval: string
	gps_status: string
}

export enum FuelStatus {
	FuelDisconnect = 1,
	FuelConnect = 0
}

export enum DeviceStatus {
	IsEmergent = 0,
	ToBeEmergent = 1,
	Normal = 2,
	Offline = 3
}

export interface ActivityLogReq {
	id: string
	start_time: number
	end_time: number
}

export interface ActivityLog {
	create_at: number
	event_type: number
	name: string
	remark: string
}

export interface ControlLogRequest {
	start_time: number
	end_time: number
	id: string
}

export interface ControlLog {
	action: {
		type: 'dormant' | 'wired'
		payload: number // 0: 通油 1: 安全断油 2: 强制断油
	}
	car_id: string
	cnum: string
	create_time: number
	remark: string
	status: number // -1 指令下发中 0 未执行 1 执行成功 2 执行失败
	update_time: number
	vin: string
}

export interface TerminalCommunicateTime {
	tid: string
	last_time: number
	next_time: number
}

export interface TerminalPushStatus extends Terminal {
	car_id: string
}
