import { useEffect, useState } from 'react'
import io from 'socket.io-client'
import { PushAddress } from '@/services/apis'
import { getOrCreateDevId, WSPush } from '@/layouts/HomeLayout/DataPush/index'
import { useDispatch } from 'react-redux'
import { setPushState } from '@/store/site.slice'

export default function useWSConnect(props: WSPush | null) {
	const [socket, setSocket] = useState<SocketIOClient.Socket | null>(null)
	const [connected, setConnected] = useState(false)
	const dispatch = useDispatch()

	useEffect(() => {
		if (!props) {
			return
		}
		const { id, key } = props

		const socket = io('/', {
			path: PushAddress,
			query: {
				packet_type: 'C1',
				push_id: id,
				psd: key,
				devid: getOrCreateDevId(),
				from: 0
			}
		})

		socket.on('connect', function () {
			console.log('[Push] connected')
			setConnected(true)
			dispatch(setPushState(true))
		})

		socket.on('disconnect', (event: any) => {
			setConnected(false)
			console.log(event, 'Reconnecting...')
			socket.connect()
			dispatch(setPushState(false))
		})

		socket.on('error', (e: any) => {
			console.warn(e)
		})
		setSocket(socket)

		return () => {
			console.log('[Socket] closed')
			socket.off('disconnect')
			socket.close()
			setSocket(null)
		}
	}, [props])

	return {
		socket,
		connected
	}
}
