import { IconFont } from '@/components'
import AlertIcon from '@/components/AlertIcon'
import NotificationModal from '@/components/NotificationModal'
import Restricted from '@/components/Restricted'
import { BoxContainer, BoxContent, BoxHeader } from './style'
import { NotificationData } from '@/services/notification/notification.model'
import { BorderOutlined, CopyOutlined, LineOutlined } from '@ant-design/icons/lib'
import { message, Space, Table, Tooltip } from 'antd'
import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import { dateFormat, notificationText } from '@/utils/utils'
import { markNotificationToRead, updateIsNew, updateIsOpen } from '@/store/notification/notification.slice'
import { useNavigate } from 'react-router'
import dayjs from 'dayjs'
import alertMp3 from '@/assets/alert.mp3'
import { Link } from 'react-router-dom'
import '@/components/NotificationModal'
import { getAddress } from '@/services/geo-address/geo-address.service'

const { Column } = Table

export default memo(function NotificationBox() {
	const [audio] = useState(new Audio(alertMp3))
	const [isOpenModal, setIsOpenModal] = useState(false)
	const { notifications, isNew, isOpen } = useSelector((state: RootState) => state.notification)
	const userInfo = useSelector((state: RootState) => state.user.userInfo)
	const groups = useSelector((state: RootState) => state.vehicleState.groups)
	const [isVisible, setIsVisible] = useState(false)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		if (userInfo?.voice && isNew) {
			audio.play().then(() => {
				dispatch(updateIsNew(false))
			})
		}
	}, [userInfo, isNew])

	const markToRead = (id: string) => {
		dispatch(markNotificationToRead(id))
	}

	const toQuery = (record: NotificationData) => {
		const search = {
			car_id: record.car_id,
			begintime: dayjs.unix(record.timestamp).startOf('d').unix(),
			endtime: dayjs.unix(record.timestamp).endOf('d').unix()
		}
		const notificationSearch = new URLSearchParams(search as any).toString()

		navigate(`/reports/alerts?${notificationSearch}`)
	}

	const triggerContent = () => {
		setIsVisible(false)
		dispatch(updateIsOpen(!isOpen))
	}

	const copyInfo = (data: any) => {
		console.log('告警信息：', data)
		getAddress(data.latitude / 3600000 + ',' + data.longitude / 3600000)
			.then(res => {
				const address = res[0].address || '位置信息解析失败'
				const copyTemplate = `组织名称：${groups.find(item => item.vIds.some(groupid => groupid == data.car_id))?.name || '-'}
车辆：${data.name}
告警时间：${dayjs.unix(data.timestamp).format('YYYY-MM-DD HH:mm:ss')}
告警事件：${[10, 63, 64].includes(data.w_type) ? data.w_content : notificationText(data.w_type)}
详细位置：${address}`
				navigator.clipboard.writeText(copyTemplate).then(() => message.success('复制成功'))
			})
			.catch(() => {
				const address = '位置信息解析失败'
				const copyTemplate = `组织名称：${groups.find(item => item.vIds.some(groupid => groupid == data.car_id))?.name || '-'}
车辆：${data.name}
告警时间：${dayjs.unix(data.timestamp).format('YYYY-MM-DD HH:mm:ss')}
告警事件：${[10, 63, 64].includes(data.w_type) ? data.w_content : notificationText(data.w_type)}
详细位置：${address}`
				navigator.clipboard.writeText(copyTemplate).then(() => message.success('复制成功'))
			})
	}

	return (
		<Restricted permission={'canQueryAlert'}>
			<BoxContainer open={isOpen}>
				<BoxHeader onClick={triggerContent}>
					<Space>
						<span>告警信息</span>
					</Space>

					<Space>
						<Restricted permission={'canQueryAlert'}>
							<Restricted permission={'canReports'}>
								<Link to='/reports/alerts' onClick={event => event.stopPropagation()}>
									<Tooltip title='告警信息'>
										<IconFont type='icon-alerts' />
									</Tooltip>
								</Link>
							</Restricted>
							<Restricted permission={'MainUser'}>
								<Tooltip title='告警设置'>
									<IconFont
										style={{ marginLeft: '5px' }}
										type={'icon-setting'}
										onClick={e => {
											e.stopPropagation()
											setIsOpenModal(true)
										}}
									/>
								</Tooltip>
							</Restricted>
						</Restricted>

						<Tooltip visible={isVisible} title={isOpen ? '关闭' : '打开'}>
							{isOpen ? (
								<LineOutlined
									onMouseOver={() => {
										setIsVisible(true)
									}}
									onMouseLeave={() => {
										setIsVisible(false)
									}}
								/>
							) : (
								<BorderOutlined
									onMouseOver={() => {
										setIsVisible(true)
									}}
									onMouseLeave={() => {
										setIsVisible(false)
									}}
								/>
							)}
						</Tooltip>
					</Space>
				</BoxHeader>

				<Restricted permission='MainUser'>
					<NotificationModal openNote={isOpenModal} onChange={(val: boolean) => setIsOpenModal(val)} />
				</Restricted>

				<BoxContent>
					<Table
						dataSource={notifications}
						bordered
						size='small'
						pagination={{ pageSize: 5, showSizeChanger: false }}
						rowKey='eid'
					>
						<Column title='车辆' dataIndex='name' key='name' align='center' />
						<Column title='设备' dataIndex='dev_type' align='center' />
						<Column
							title='告警事件'
							align='center'
							render={data => {
								return (
									<>
										<AlertIcon type={data.w_type} />
										{[10, 63, 64].includes(data.w_type) ? data.w_content : notificationText(data.w_type)}
										<CopyOutlined
											onClick={() => {
												copyInfo(data)
											}}
										/>
									</>
								)
							}}
						/>
						<Column title='告警时间' align='center' render={data => dateFormat(data.timestamp)} />
						<Column
							title='操作'
							align='center'
							render={data => (
								<>
									<Restricted permission={'canReports'}>
										<a onClick={() => toQuery(data)}>查看更多</a>
									</Restricted>
									<a onClick={() => markToRead(data.eid)} className='ml-2'>
										已读
									</a>
								</>
							)}
						/>
					</Table>
				</BoxContent>
			</BoxContainer>
		</Restricted>
	)
})
