import {
	forwardRef,
	isValidElement,
	cloneElement,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
	RefObject,
	ComponentProps,
	Children
} from 'react'
import { useAMapEvents } from '../common/hooks'

const mapEvents = [
	'onResize',
	'onComplete',
	'onClick',
	'onDblclick',
	'onMapMove',
	'onHotspotClick',
	'onHotSpotOver',
	'onHotSpotOut',
	'onMoveStart',
	'onMoveEnd',
	'onZoomChange',
	'onZoomStart',
	'onZoomEnd',
	'onRotateChange',
	'onRotateStart',
	'onRotateEnd',
	'onMousemove',
	'onMousewheel',
	'onMouseover',
	'onMouseout',
	'onMouseup',
	'onMousedown',
	'onRightClick',
	'onDragstart',
	'onDragging',
	'onDragend',
	'onTouchstart',
	'onTouchmove',
	'onTouchend'
]

export interface AMapMapBindings {
	aMap?: AMap.Map
}

export interface AMapMapProps extends AMap.Map.Options, ComponentProps<any> {
	onInit?: (map: AMap.Map) => void
}

const AMapMap = forwardRef((props: AMapMapProps, ref) => {
	const containerRef = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>
	const { children, onInit, ...others } = props
	const [aMap, setAMap] = useState<AMap.Map>()

	useEffect(() => {
		const aMapInstance = new AMap.Map(containerRef.current!, others)
		setAMap(aMapInstance)
		onInit?.(aMapInstance)
		return () => {
			aMapInstance.destroy()
		}
	}, [])

	useMemo(() => {
		if (aMap) {
			aMap.setZoom(props.zoom!)
		}
	}, [props.zoom])

	useEffect(() => {
		aMap?.setCenter(props.center as [number, number])
	}, [props.center])

	useImperativeHandle(
		ref,
		() => ({
			map: aMap,
			setCenter: (center: AMap.LngLat) => {
				aMap?.setCenter(center)
			},
			setZoom: (zoom: number) => {
				aMap?.setZoom(zoom)
			},
			autoBounds: () => {
				if (!aMap) {
					return
				}
				const overlays = aMap.getAllOverlays('marker')
				aMap.setFitView(overlays)
			},
			getZoom: () => {
				return aMap?.getZoom()
			}
		}),
		[aMap]
	)

	useAMapEvents<AMap.Map>(aMap!, others, mapEvents)

	const childElems = Children.toArray(children)

	return (
		<>
			<div style={{ width: '100%', height: '100%' }} ref={containerRef}></div>
			<div>
				{aMap &&
					childElems.map((child, key) => {
						if (typeof child === 'string') {
							return cloneElement(<>{child}</>, { key })
						}
						if (!isValidElement(child)) return null
						if (child.type && typeof child.type === 'string') {
							return cloneElement(child, { key })
						}
						return cloneElement(child, {
							...child.props,
							map: aMap,
							key
						})
					})}
			</div>
		</>
	)
})

export default AMapMap
