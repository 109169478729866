import { changePassword } from '@/services/user/user.service'
import { ModalForm } from '@ant-design/pro-form'
import { Form, Input, Menu } from 'antd'
import { memo, useState } from 'react'

const ChangePasswordMenu = memo(props => {
	const [form] = Form.useForm()
	const [modalVisit, setModalVisit] = useState(false)

	return (
		<>
			<Menu.Item
				{...props}
				onClick={() => {
					setModalVisit(true)
					form.resetFields()
				}}
			>
				修改密码
			</Menu.Item>

			<ModalForm
				form={form}
				visible={modalVisit}
				onVisibleChange={setModalVisit}
				title='修改密码'
				onFinish={async values => {
					await changePassword({
						old: values.oldPassword,
						new: values.newPassword
					})
					return true
				}}
				width={500}
				layout={'horizontal'}
				labelCol={{ span: 6 }}
			>
				<Form.Item
					label='当前密码'
					name='oldPassword'
					rules={[
						{ required: true, message: '请输入密码' },
						{ min: 6, message: '请输入6-20位密码' },
						{ max: 20, message: '请输入6-20位密码' }
					]}
				>
					<Input type='password' autoComplete='new-password' minLength={6} maxLength={20} />
				</Form.Item>

				<Form.Item
					label='新密码'
					name='newPassword'
					rules={[
						{ required: true, message: '请输入密码' },
						{ min: 6, message: '请输入6-20位密码' },
						{ max: 20, message: '请输入6-20位密码' }
					]}
				>
					<Input type='password' minLength={6} maxLength={20} />
				</Form.Item>

				<Form.Item
					label='确认新密码'
					name='confirmPassword'
					dependencies={['newPassword']}
					rules={[
						{ required: true, message: '请输入密码' },
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue('newPassword') === value) {
									return Promise.resolve()
								}
								return Promise.reject(new Error('两次输入密码不一致，请重新输入'))
							}
						})
					]}
				>
					<Input type='password' minLength={6} maxLength={20} />
				</Form.Item>
			</ModalForm>
		</>
	)
})

export default ChangePasswordMenu
