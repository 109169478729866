import { GetMapKey } from '@/services/apis'
import request from '@/services/request'

export function loadMapKey(): Promise<string[]> {
  return request.get(GetMapKey, {params: {
    provider: '高德',
    project: '租赁宝',
    agent: 'web',
    env: 'offcial'
  },timeout: 3000})
}
