import { useEffect } from 'react'

export function useAMapEvents<T extends AMap.EventEmitter>(
	instance: T,
	props: { [key: string]: any } = {},
	events: any,
	isUIEvents?: boolean
) {
	events.forEach((name: string) => {
		const eventName = name || ''
		const handle = props[eventName]
		const realEventName = isUIEvents ? eventName : eventName.replace(/^on/, '').toLowerCase()

		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			if (!instance) return
			if (handle && realEventName) {
				instance.on(realEventName, handle)
			}

			return () => {
				if (handle && realEventName) {
					instance.off(realEventName, handle)
				}
			}
		}, [instance, handle])
	})
}

export function useAMapOverlayInputs<T extends AMap.Overlay>(instance: T, props: string[], values: any) {
	props.forEach((name: string) => {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			if (!instance || !values[name]) return

			const key = name.replace(/^[a-z]/, c => c.toUpperCase())
			const methodName = `set${key}`

			// @ts-ignore
			if (instance[methodName]) {
				// @ts-ignore
				instance[methodName](values[name])
			} else {
				console.warn(`method name [${methodName}] can't be found in ${typeof instance}`)
			}
		}, [instance, values[name]])
	})
}
