import { RootState } from '@/store'
import { createAsyncThunk, createSlice, Draft, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { Group, Vehicle } from '@/services/vehicle/vehicle.model'
import {
	calcVehicleExtendProps,
	getVehicleById,
	getVehiclesByIds,
	updateVehicle,
	vehiclePipe
} from '@/services/vehicle/vehicle.service'
import { calculateDeviceStatus, getTerminalById } from '@/services/terminal/terminal.service'
import { Terminal, TerminalPushStatus } from '@/services/terminal/terminal.model'
import { getGroupsThunk } from '@/store/vehicle/vehicle.thunks'
import { PositionInfo } from '@/services/modes'
import { vehicleKeyMap, updateVehicleMap, setVehicleKeyMap, clearUpdateVehicleMap } from '@/layouts/HomeLayout/DataPush/updateMap'
import getAppConfig from '@/env-configs'

export interface VehicleStore {
	vehicles: Vehicle[]
	selectedVehicle: Vehicle | null
	groups: Group[]
	updateVehicleArr: Vehicle[]
	tracePosition: PositionInfo | null
	radioVehicle: Vehicle[],
  filterArr: string[][]
}

const initialState: VehicleStore = {
	vehicles: [],
	selectedVehicle: null,
	groups: [],
	updateVehicleArr: [],
	tracePosition: null,
	radioVehicle: [], /*单选车辆*/
  filterArr: []
}

export const getVehiclesByIdsThunk = createAsyncThunk('vehicles/getVehiclesByIds', async (ids: string[]) => {
	return getVehiclesByIds(ids)
})

export const updateVehicleById = createAsyncThunk('vehicles/updateVehicleById', async (payload: any) => {
	const { id, body } = payload

	return updateVehicle(id, body)
})

export const getVehicleByIdThunk = createAsyncThunk('vehicles/getVehicleById', async (id: string) => {
	return getVehicleById(id)
})

export const getTerminalInfoThunk = createAsyncThunk('Terminals/getTerminalById', async (id: string) => {
	return getTerminalById(id).then(terminal => {
		return { ...terminal, tid: id }
	})
})

export const vehicleSlice = createSlice({
	name: 'vehicleState',
	initialState,
	reducers: {
		setVehicles: (state, action: PayloadAction<Vehicle[]>) => {
			state.vehicles = action.payload
		},
		setSelectedVehicle: (state, action: PayloadAction<string | null>) => {
			const payload = action.payload
			let vehicle = null

			if (payload) {
				vehicle = state.vehicles.find(item => item.id === payload) || null
			}

			state.selectedVehicle = vehicle
		},
		updateVehicleStatusByDevice: (state, action: PayloadAction<TerminalPushStatus>) => {
			const terminal = action.payload
			const { car_id } = terminal
			updateVehicleByDevice(state, terminal, car_id)
		},
		updateVehicleLocations: (state, action: PayloadAction<PositionInfo[]>) => {
			const vehicles = [...state.vehicles]
			const locations = action.payload

			if (locations.length <= 0) return

			const locationPoint = locations[locations.length - 1]
			const { car_id, tid } = locationPoint
			const index = vehicleKeyMap.get(car_id) || vehicles.findIndex((t: any) => t.id === car_id)

			if (index < 0) return

			const terminalIndex = vehicles[index].terminals.findIndex((t: any) => t.tid === tid)
			const newTerminal = <Terminal>{ ...vehicles[index].terminals[terminalIndex], position: locationPoint }
			const newVehicle = { ...vehicles[index], position: { ...locationPoint } }
			newVehicle.terminals[terminalIndex] = newTerminal
			vehicles[index] = vehiclePipe(newVehicle)
			state.updateVehicleArr = [...state.updateVehicleArr, { ...vehicles[index] }]
			state.vehicles = vehicles
		},
		updateTracePosition: (state, action: PayloadAction<PositionInfo[]>) => {
			const locations = action.payload

			if (locations.length <= 0) return

			locations.forEach((location: PositionInfo) => {
				const position = { ...location }
				const lat = position.latitude / 3600000
				const lng = position.longitude / 3600000
				const appConfig = getAppConfig()
				const [trueLng, trueLat] = appConfig.coordTransform(lng, lat)
				position.latitude = trueLat
				position.longitude = trueLng

				state.tracePosition = position
			})
		},
		addOrUpdateVehicle: (state, action: PayloadAction<Vehicle>) => {
			const vehicles = state.vehicles
			const vehicle = action.payload
			const target = vehicles.find(item => item.id === vehicle.id)

			if (!target) {
				state.vehicles = [...vehicles, vehicle]
			} else {
				updateStoreVehicle(state, vehicle)
			}
		},
		updateVehicleByUdMap: state => {
			let vehicles = state.vehicles
			let updateVehicles: Vehicle[] = []

			updateVehicleMap.forEach((value, key) => {
				vehicles[key] = vehiclePipe(value)
				updateVehicles.push({ ...vehicles[key] })
			})

			state.vehicles = vehicles
			state.updateVehicleArr = [...state.updateVehicleArr, ...updateVehicles]
			clearUpdateVehicleMap()
		},
		clearUpdateVehicleArr: state => {
			state.updateVehicleArr = []
		},
		addRadioVehicle: (state, action: PayloadAction<Vehicle | null>) => {
			if (state.radioVehicle.length || !action.payload) {
				state.radioVehicle.shift()
			}
			if (action.payload) {
				state.radioVehicle.push(action.payload)
			}
		},
    setFilterArr: (state, action: PayloadAction<string[][]>) => {
			state.filterArr = action.payload || []
		},
	},
	extraReducers: builder => {
		builder.addCase(getGroupsThunk.fulfilled, (state, action) => {
			state.groups = action.payload
		})
		builder.addCase(getVehiclesByIdsThunk.fulfilled, (state, action) => {
			state.vehicles = action.payload
			setVehicleKeyMap(action.payload)
		})
		builder.addCase(getTerminalInfoThunk.fulfilled, (state, action) => {
			const terminal = <Terminal>action.payload
			const vehicles = [...state.vehicles]
			const index = state.vehicles.findIndex(item => !!item.terminals.find(t => t.tid === terminal.tid))
			if (index < 0) {
				return
			}
			updateVehicleByDevice(state, terminal, vehicles[index].id)
		})
		builder.addMatcher(isAnyOf(updateVehicleById.fulfilled, getVehicleByIdThunk.fulfilled), (state, action) => {
			const vehicle = <Vehicle>action.payload

			updateStoreVehicle(state, vehicle)
		})
	}
})

function updateStoreVehicle(state: Draft<any>, vehicle: Vehicle) {
	const vehicles = [...state.vehicles]
	const index = vehicles.findIndex(v => v.id === vehicle.id)
	if (index < 0) return
	vehicles[index] = vehicle
	state.vehicles = vehicles
	state.updateVehicleArr = [...state.updateVehicleArr, { ...vehicles[index] }]
}

function updateVehicleByDevice(state: Draft<any>, terminal: Terminal, vId: string) {
	const vehicles = [...state.vehicles]
	const index = vehicleKeyMap.get(vId) || vehicles.findIndex((t: any) => t.id === vId)

	if (index === -1) return

	const newVehicle = { ...vehicles[index] }
	const terminals = [...newVehicle.terminals]
	const tIndex = terminals.findIndex((item: any) => item.tid === terminal.tid)
	const newDevice = { ...terminals[tIndex], ...terminal, dev_status: calculateDeviceStatus(terminal) }

	terminals[tIndex] = newDevice
	newVehicle.terminals = terminals
	vehicles[index] = calcVehicleExtendProps(newVehicle)
	state.updateVehicleArr = [...state.updateVehicleArr, { ...vehicles[index] }]
	state.vehicles = vehicles
}

export const {
	setVehicles,
	setSelectedVehicle,
	updateVehicleStatusByDevice,
	updateVehicleLocations,
	updateTracePosition,
	addOrUpdateVehicle,
	updateVehicleByUdMap,
	clearUpdateVehicleArr,
	addRadioVehicle,
  setFilterArr
} = vehicleSlice.actions

export const selectVehicle = (state: RootState) => state.vehicleState
