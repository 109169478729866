import { useAMapEvents, useAMapOverlayInputs } from '@/libs/react-amap/components/common/hooks'
import { useEffect, useImperativeHandle, useState, PropsWithChildren, forwardRef } from 'react'
import { OverlayPropsEvents } from '../types'

export const distClusterEvents = [
	'clusterMarkerClick',
	'clusterMarkerRightclick',
	'clusterMarkerMouseover',
	'clusterMarkerMouseout',
	'featureClick',
	'featureMouseover',
	'featureMouseout'
] as const

export const DistClusterInputProps = []

type distClusterEventsProps = typeof distClusterEvents[number]

export type DistrictClusterProps = OverlayPropsEvents<distClusterEventsProps> &
	PropsWithChildren<any> & {
		onInit?: (distCluster: any) => void
	}

export const AMapDistrictCluster = forwardRef<any, DistrictClusterProps>((options, ref) => {
	const { children, onInit, map, getPosition, data, renderOptions, ...others } = options
	const [distCluster, setDistCluster] = useState<any>()

	useEffect(() => {
		if (!map) return // map 不存在数据 返回 undefined
		let distClusterInstance: any
		if (!distCluster) {
			// distCluster 不存在数据时执行以下操作
			window.AMapUI.loadUI(['geo/DistrictCluster'], function (DistrictCluster: any) {
				distClusterInstance = new DistrictCluster({
					map,
					getPosition: getPosition || getPositionDefault,
					data,
					renderOptions,
					...others
				})
				setDistCluster(distClusterInstance)
				onInit?.(distClusterInstance)
			})
		}

		return () => {
			if (distClusterInstance) {
				setDistCluster(undefined)
				distClusterInstance.setData([])
			}
		}
	}, [])

	const getPositionDefault = (item: any) => {
		return item.position
	}

	useAMapOverlayInputs<any>(distCluster!, DistClusterInputProps, options)

	useAMapEvents<any>(distCluster!, options, distClusterEvents, true)

	useImperativeHandle(ref, () => distCluster!) // 将 res 数据挂载到react框架中的函数组件中

	return children || null
})
