import { memo, useEffect } from 'react'

export interface AMapTrafficLayerProps {
	map?: AMap.Map
}
const AMapTrafficLayer = memo((props: AMapTrafficLayerProps) => {
	const { map } = props
	useEffect(() => {
		const trafficLayer = new AMap.TileLayer.Traffic({ map })

		return () => {
			trafficLayer.hide()
			trafficLayer.setMap(null)
		}
	}, [])
	return null
})

export default AMapTrafficLayer
