import { RootState } from '@/store'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { InstallDataExport } from './InstallDataExport'
import { AccountDataExport } from './AccountDataExport'
import { MENUS } from './menu'
import { Menu } from 'antd'
import { memo, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { MenuItem } from './menu'
import NewIcon from '@/assets/new.png'

const NbMenuItem = memo<{ item: MenuItem }>(({ item, ...antdProps }) => {
	const renderCustom = (item: MenuItem) => {
		switch (item.custom) {
			case 'export-account':
				return <AccountDataExport navInfo={item} />
			case 'export-install':
				return <InstallDataExport navInfo={item} />
			default:
				return (
					<CusSpan>
						{item.title} {item.new && <ImgIcon src={`${NewIcon}`} />}
					</CusSpan>
				)
		}
	}

	return (
		<Menu.Item {...antdProps}>
			{item.path && (
				<CusLink to={item.path}>
					{item.title}
					{item.new && <ImgIcon src={`${NewIcon}`} />}
				</CusLink>
			)}
			{item.custom && renderCustom(item)}
		</Menu.Item>
	)
})

export default memo(function HeaderMenu() {
	const permissions = useSelector((state: RootState) => state.user.permissions)
	const path = useLocation().pathname.split('/')
	const [selectMenu, setSelectMenu] = useState<string>('spylist')

	useEffect(() => {
		setSelectMenu(path[1])
	}, [path])

	const menuItems = useMemo(() => {
		const menus: MenuItem[] = []
		MENUS.forEach(item => {
			if (!item.permission || permissions.includes(item.permission)) {
				if (item.children) {
					const children = item.children.filter(child => !child.permission || permissions.includes(child.permission))
					if (!!children.length) {
						menus.push({ ...item, children })
					}
				} else {
					menus.push(item)
				}
			}
		})
		return menus
	}, [permissions])

	return (
		<MenuList mode='horizontal' selectedKeys={[selectMenu]}>
			{menuItems.map(item => {
				if (item.children) {
					return (
						<Menu.SubMenu title={item.title} key={item.path}>
							{item.children.map(childItem => (
								<NbMenuItem item={childItem} key={childItem.path || childItem.custom} />
							))}
						</Menu.SubMenu>
					)
				}

				return <NbMenuItem item={item} key={item.path || item.custom} />
			})}
		</MenuList>
	)
})

const MenuList = styled(Menu)`
	background: ${props => props.theme['primary-color']}!important;
	border-bottom: 1px solid ${props => props.theme['primary-color']}!important;
	color: #fff !important;

	.ant-menu-item {
		a {
			color: #fff !important;

			&:hover {
				color: #fff !important;
			}
		}
	}

	.ant-menu-submenu-title:hover,
	&.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
	&.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
	&.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
	&.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
		color: #fff !important;
	}

	.ant-menu-item-selected,
	.ant-menu-item:hover,
	.ant-menu-submenu:hover,
	&.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
	&.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
		background: #1767bb;
		user-select: none;
	}
`

const CusLink = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: flex-start;
`

const CusSpan = styled.span`
	display: flex;
	align-items: center;
	justify-content: flex-start;
`

const ImgIcon = styled.img`
  width: 30px;
  margin-left: 5px;
`
