export interface MenuItem {
	path?: string
	title: string
	icon?: string
	custom?: string
	permission?: string
  new?: boolean
	children?: MenuItem[]
}

export const MENUS: MenuItem[] = [
	{
		path: 'spylist',
		title: '实时信息'
	},
	{
		path: 'assets-manager',
		title: '车辆管理',
		children: [
			{
				path: 'assets-manager/fleet-management',
				title: '车队管理'
			}
		]
	},
	{
		path: 'devices-manager',
		title: '设备管理'
	},
	{
		path: 'reports',
		title: '统计报表',
		permission: 'canReports',
		children: [
			{
				path: 'reports/mileages',
				title: '里程统计',
			},
			{
				path: 'reports/alerts',
				title: '告警统计'
			},
			{
				path: 'reports/controlLogs',
				title: '控车日志'
			}
		]
	},
  {
		path: 'monitor-playback',
		title: '监控回放',
	},
	{
		path: 'geo-fence',
		title: '围栏'
	},
	{
		path: 'more',
		title: '更多',
		children: [
			{
				path: 'work-mode',
				title: '工作模式',
				permission: 'canSetEmergencyMode'
			},
			{
				path: 'sub-account',
				title: '子账号管理',
				permission: 'MainUser'
			},
			{
				custom: 'export-account',
				title: '账户数据导出',
				permission: 'MainUser'
			},
			{
				custom: 'export-install',
				title: '安装数据导出',
				permission: 'canExportInstallData'
			}
		]
	}
]
