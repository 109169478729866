import { lazy } from 'react'
import EntranceLayout from './layouts/EntranceLayout'
import HomeLayout from './layouts/HomeLayout'
import ErrorPage from './pages/404'
import FeaturesLayout from './layouts/FeaturesLayout'
import StaticLayout from './layouts/StaticLayout'
import { Navigate, Outlet } from 'react-router'

const Register = lazy(() => import('./pages/Register'))
const Recover = lazy(() => import('./pages/Recover'))
const Login = lazy(() => import('./pages/Login'))
const MessageLogin = lazy(() => import('./pages/Login/Message'))
const PasswordLogin = lazy(() => import('./pages/Login/Password'))
const SpyList = lazy(() => import('./pages/SpyList'))
const GeoFence = lazy(() => import('./pages/GeoFence'))
const GeoFenceList = lazy(() => import('./pages/GeoFence/List'))
const GeoFenceAction = lazy(() => import('./pages/GeoFence/Action'))
const VehicleTrack = lazy(() => import('./pages/VehicleTrack'))
const TripReplay = lazy(() => import('./pages/TripReplay'))
const Reports = lazy(() => import('@/pages/Reports'))
const AlertReport = lazy(() => import('@/pages/Reports/Alert'))
const MileReport = lazy(() => import('@/pages/Reports/Mileage'))
const ControlLog = lazy(() => import('@/pages/Reports/ControlLog'))
const StaticMap = lazy(() => import('@/pages/StaticMap'))
const Export = lazy(() => import('@/pages/Export'))
const WorkMode = lazy(() => import('@/pages/WorkMode'))
const SubAccount = lazy(() => import('@/pages/SubAccount'))
const SubAccountAction = lazy(() => import('@/pages/SubAccount/Action'))
const VehicleGroup = lazy(() => import('@/pages/AssetsManager/VehicleGroup'))
const DevicesManager = lazy(() => import('./pages/DeviceManager'))
const DevicesExpired = lazy(() => import('./pages/DeviceManager/DevicesExpired'))
const DeviceBinding = lazy(() => import('@/pages/DeviceManager/DeviceBinding'))
const DeviceOverView = lazy(() => import('@/pages/DeviceManager/DevicesOverview'))
const DevicesNotActivated = lazy(() => import('@/pages/DeviceManager/DevicesNotActivated'))
const DevicesOffline = lazy(() => import('@/pages/DeviceManager/DevicesOffline'))
const MonitorPlayback = lazy(() => import('@/pages/MonitorPlayback'))

const routes = () => [
	{
		path: '/',
		element: <HomeLayout />,
		children: [
			{
				path: '',
				element: <Navigate to='spylist' />
			},
			{
				path: 'spylist',
				element: <SpyList />
			},
			{
				path: 'geo-fence',
				element: <GeoFence />,
				children: [
					{
						path: '',
						element: <GeoFenceList />
					},
					{
						path: ':id',
						element: <GeoFenceAction />
					}
				]
			},
			{
				path: 'reports',
				element: <Reports />,
				children: [
					{
						path: '/alerts',
						element: <AlertReport />
					},
					{
						path: '/mileages',
						element: <MileReport />
					},
					{
						path: '/controlLogs',
						element: <ControlLog />
					},
				]
			},
			{
				path: 'work-mode',
				element: <WorkMode />
			},
			{
				path: 'sub-account',
				element: <Outlet />,
				children: [
					{
						path: '',
						element: <SubAccount />
					},
					{
						path: ':id',
						element: <SubAccountAction />
					}
				]
			},
			{
				path: 'assets-manager',
				element: <Outlet />,
				children: [
					{
						path: 'fleet-management',
						element: <VehicleGroup />
					}
				]
			},
			{
				path: 'devices-manager',
				element: <DevicesManager />,
				children: [
					{
						path: '',
						element: <Navigate to='device-overview' />
					},
					{
						path: 'device-overview',
						element: <DeviceOverView />
					},
					{
						path: 'device-binding',
						element: <DeviceBinding />
					},
					{
						path: 'expired-devices',
						element: <DevicesExpired />
					},
					{
						path: 'notActivated-devices',
						element: <DevicesNotActivated />
					},
					{
						path: 'offline-devices',
						element: <DevicesOffline />
					}
				]
			},
      {
				path: 'monitor-playback',
				element: <MonitorPlayback />
			},
		]
	},
	{
		path: '/login',
		element: <Navigate to='/entrance/login' />
	},
	{
		path: '/register',
		element: <Navigate to='/entrance/register' />
	},
	{
		path: '/recover',
		element: <Navigate to='/entrance/recover' />
	},
	{
		path: '/entrance',
		element: <EntranceLayout />,
		children: [
			{
				path: '',
				element: <Navigate to='login' />
			},
			{
				path: 'login',
				element: <Login />,
				children: [
					{
						path: '',
						element: <Navigate to='password' />
					},
					{
						path: 'password',
						element: <PasswordLogin />
					},
					{
						path: 'message',
						element: <MessageLogin />
					}
				]
			},
			{
				path: 'register',
				element: <Register />
			},
			{
				path: 'recover',
				element: <Recover />
			}
		]
	},
	{
		path: '/features',
		element: <FeaturesLayout />,
		children: [
			{
				path: 'trace/:id',
				element: <VehicleTrack />
			},
			{
				path: 'replay/:id',
				element: <TripReplay />
			},
			{
				path: 'static-map',
				element: <StaticMap />
			},
			{
				path: 'exports',
				element: <Export />
			},
		]
	},
	{
		path: '/static-pages',
		element: <StaticLayout />,
		children: [
			{
				path: 'static-map',
				element: <StaticMap />
			}
		]
	},
	{
		path: '*',
		element: <ErrorPage />
	}
]

export default routes
