import styled from '@emotion/styled'

const headerHeight = '36px'

export const BoxContainer = styled.div<{ open: boolean }>`
	position: fixed;
	z-index: 1000;
	bottom: 0;
	right: 0;
	width: ${props => (props.open ? '800px' : '480px')};
	height: ${props => (props.open ? 'auto' : headerHeight)};
	max-height: 450px;
	display: flex;
	flex-direction: column;
`

export const BoxHeader = styled.div`
	flex: 0 0 ${headerHeight};
	line-height: ${headerHeight};
	padding: 0 8px;
	color: #fff;
	background: ${props => props.theme['primary-color']};
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	font-size: 16px;

	.anticon {
		color: #fff;
	}
`

export const BoxContent = styled.div`
	background: #fff;
	flex: auto;
	overflow-y: auto;
`
