import { AMapPolygon, PolygonProps } from '@/libs/react-amap/components/Polygon'
import { cloneDeep } from 'lodash-es'
import { memo, useEffect, useState } from 'react'

export interface AdCodeBoundsInput {
	map?: AMap.Map
	adcode: string
}

type AMapAdCodeBoundsProps = AdCodeBoundsInput &
	PolygonProps & {
		overlaysInit?: (overlays: AMap.Polygon[]) => void
	}

const AMapAdCodeBounds = memo<AMapAdCodeBoundsProps>(props => {
	const { map, adcode, overlaysInit, onInit, ...other } = props
	const [districtSearch, setDistrictSearch] = useState<AMap.DistrictSearch>()
	const [bounds, setBounds] = useState<Array<AMap.LngLat[]>>()
	let overlays: any = []

	useEffect(() => {
		;(async () => {
			if (!AMap.DistrictSearch) {
				await new Promise<void>(resolve => {
					map?.plugin('AMap.DistrictSearch', () => resolve())
				})
			}
			setDistrictSearch(
				new AMap.DistrictSearch({
					level: 'province',
					showbiz: true,
					extensions: 'all',
					subdistrict: 0
				})
			)
		})()
	}, [])

	useEffect(() => {
		if (districtSearch) {
			districtSearch.search(adcode, (status, result) => {
				if (status !== 'complete') {
					console.log(status, result)
					return
				}
				setBounds(result.districtList[0].boundaries)
			})
		}
	}, [adcode, districtSearch])

	const searchOnInit = (polygon: AMap.Polygon) => {
		overlays.push(polygon)
		onInit?.(polygon)
	}

	useEffect(() => {
		if (overlays.length > 0) {
			overlaysInit?.(overlays)
		}
	}, [overlays])

	return (
		<>
			{bounds?.map(item => (
				<AMapPolygon
					map={map}
					key={`${adcode}${item.length}${item[0].toString()}`}
					path={item}
					onInit={searchOnInit}
					{...other}
				/>
			))}
		</>
	)
})

export default AMapAdCodeBounds
