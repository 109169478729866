import { memo, useEffect, useState } from 'react'
import request from '@/services/request'
import { ApiAddress } from '@/services/apis'
import { getRandomString } from '@/utils/utils'
import useWSConnect from '@/layouts/HomeLayout/DataPush/useWSConnect'
import usePushData from '@/layouts/HomeLayout/DataPush/usePushData'
import useUpdateVehicleData from './useUpdateVehicleData'

export interface WSPush {
	id: string
	key: string
}

export interface PushResponse {
	wspush: WSPush
}

export interface PushParams {
	from: number
	devid: string
}

export interface DataPushProps {
	tIds?: string[]
}

export function getPushKey(body: PushParams): Promise<PushResponse> {
	return request.post(ApiAddress.WEBSOCKET_REGISTRATION, body)
}

export function getOrCreateDevId() {
	const devId = getRandomString()

	return devId
}

const DataPush = memo((props: DataPushProps) => {
	const [config, setConfig] = useState<WSPush | null>(null)
	const { connected, socket } = useWSConnect(config)
	const [devId] = useState(getOrCreateDevId())

	useEffect(() => {
		getPushKey({ from: 0, devid: devId }).then(wsRes => {
			setConfig(wsRes.wspush)
		})
	}, [])

	usePushData(socket)

	useUpdateVehicleData()

	useEffect(() => {
		if (Array.isArray(props.tIds) && props.tIds.length > 0 && socket && connected) {
			const watchIds = props.tIds.reduce((ids: string[], tid: string) => {
				return ids.concat([`L:${tid}`, `S:${tid}`])
			}, [])

			watchIds.unshift('other')

			socket.emit('api', {
				url: '/push/subscribe',
				data: {
					subscribe: watchIds
				}
			})
		}
	}, [socket, props.tIds, connected])

	return null
})

export default DataPush
