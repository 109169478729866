import { fetchUser } from '@/store/user/user.slice'
import { Skeleton } from 'antd'
import { memo, Suspense, useEffect } from 'react'
import { FullContainer } from '@/components'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'

export default memo(() => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(fetchUser())
	})

	return (
		<FullContainer>
			<Suspense fallback={<Skeleton active />}>
				<Outlet />
			</Suspense>
		</FullContainer>
	)
})
