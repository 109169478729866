export const APP_NAME = '租赁宝365'
export const CAN_CONTROL_FUEL_KEY = 999999
/**
 * @name NodeType 树节点的类型，分为集团节点(根节点)，车队节点，车辆节点，终端节点
 * @constant
 */
export const NodeType = {
	CORP: 'corp',
	GROUP: 'group',
	VEHICLE: 'vehicle',
	TERMINAL: 'terminal'
}

/**
 * 时间选择器最大天数
 * */
export const Max1Day = 1
export const Max1Week = 7
export const Max30Days = 30
export const Max6Month = 180

/**
 * @name TerminalWorkMode 终端工作模式
 * @constant
 * 0:0 --待机 ZJ211
 * 1:60 --紧急 ZJ211
 * 2:0 --配对 ZJ210，ZJ211
 * 3:0 --单独 ZJ210
 */
export const TerminalWorkMode = {
	SUSPEND: '0',
	EMERGENCY: '1',
	MATCH: '2',
	SINGLE: '3'
}

/**
 * @name TerminalType 终端类型
 * @constant
 */
export const TerminalType: {[key: string]: string} = {
  F: 'ZJ210W', // 接线全功能，可配对
  E: 'ZJ210',  // 接线简易版，无配对
  H: 'ZJ220', // 祺迹车管ZJ220, 带WiFi
  I: 'ZJ220S', // 祺迹车管ZJ220S，220的简易版本，无Wifi，功能与ZJ210相似
  L: 'ZJ220R', // ZJ220（断油）
  M: 'ZJ220F', // ZJ220（udp不支持断供油版本）
  N: 'ZJ220F(R)', // ZJ220（mqtt支持断供油版本）
  J: 'IV100', // OBD 设备
  K: 'ZJ210B', // 祺迹车管ZJ210S，210的北斗简易版本,支持BDS定位
  S: 'ZJ210L', // 车管一期长待机（相当于ZJ211），无配对
  T: 'ZJ211W', // 祺迹车管长待机，可配对
  U: 'ZJ211', // 祺迹车管长待机，无配对
  V: 'ZJ300WL', // 祺迹车管ZJ300，带WiFi及光感
  W: 'ZJ300L', // 祺迹车管ZJ300，带WiFi及光感，无配对
  X: 'ZJ300W', // 祺迹车管ZJ300，带WiFi，无光感
  Y: 'ZJ300', // 祺迹车管ZJ300，带WiFi，无配对及光感
  Z: 'ZJ300M', // ZJ300(无2.4G，带433)
  R: 'ZJ300F', // 4G 带443
  CJ01: 'CJ001', // 视频设备
  // Unknown: 'ZJXX'
}

/**
 * @name CarStatus 车辆运动状态
 * @constant
 */
export const CarStatus = {
	Unknown: 0,
	Stop: 1,
	Run: 2,
	FlameOut: 3,
	Idle: 4
}

/**
 * @name CarStatus 设备运转状态
 * @constant
 */
export const TerminalStatus = {
	Normal: 0,
	Offline: 1,
	PowerOff: 2,
	LowPower: 3,
	ConnectOff: 4
}

/**
 * @name ColorStatus 颜色与车辆通信状态
 * @constant
 */
export const ColorStatus = {
	Normal: 'green',
	Offline: 'grey',
	IsEmergent: 'red',
	ToBeEmergent: 'orange'
}

export const PermissionMap: { [key: string]: string } = {
	1: 'canLocateCar',
	2: 'canReplay',
	3: 'canTrack',
	4: 'canQueryAlert',
	5: 'canSetEmergencyMode',
	6: 'canReadDevice',
	7: 'canReadLocateType',
	8: 'canSetGPS',
	9: 'canSetWiFi',
	18: 'canUpdateVehicle',
	19: 'canExportInstallData',
	11: 'canControl',
	21: 'canReports'
}

export const LocateTypeToPositionTypeMap = {
	0: 'base_station',
	1: 'gps',
	2: 'wifi'
}

export enum LocateType {
	BaseStation = 0,
	GPS = 1,
	WIFI = 2
}

export const vehicleTypes = {
	offline: '设备离线',
	connect_off: '设备拔出',
	low_power: '低电状态',
	abnormalMode: '紧急模式',
	fuel_cut: '车辆断油'
}

export interface NotificationType {
	id: number
	name: string
	text: string
	icon: string
	bgColor?: string
	messageBox?: number
	weChatBox?: number
}

export type NotificationCountItem =
	| 'offline'
	| 'low_power'
	| 'pull_out'
	| 'emergency'
	| 'low_voltage'
	| 'over_speed'
	| 'light_sensed'
	| 'acc_on'
	| 'acc_off'
	| 'fence_in'
	| 'fence_out'
	| 'over_mileage_limit'
	| 'device_remove_risk'
	| 'light_remove_risk'

export const AlertReportColumns: any = [
	{
		title: '超速告警(次数)',
		key: 'over_speed'
	},
	{
		title: '亏电告警(次数)',
		key: 'low_voltage'
	},
	{
		title: '离线告警(次数)',
		key: 'offline'
	},
	{
		title: '拔出告警(次数)',
		key: 'pull_out'
	},
	{
		title: '长距离行驶(次数)',
		key: 'over_mileage_limit'
	},
	{
		title: '设备拆除风险(次数)',
		key: 'device_remove_risk'
	},
	{
		title: '感光拆除风险(次数)',
		key: 'light_remove_risk'
	},
	{
		title: 'ACC 开启(次数)',
		key: 'acc_on'
	},
	{
		title: 'ACC 熄火(次数)',
		key: 'acc_off'
	},
	{
		title: '驶入围栏(次数)',
		key: 'fence_in'
	},
	{
		title: '驶出围栏(次数)',
		key: 'fence_out'
	},
	{
		title: '设备低电告警(次数)',
		key: 'low_power'
	},
	{
		title: '紧急模式(次数)',
		key: 'emergency'
	},
	{
		title: '长时间停留(次数)',
		key: 'long_stop'
	},
	{
		title: '光感设备拔除(次数)',
		key: 'light_sensed'
	},
  {
		key: 'adas_rapid_acceleration',
		title: '急加速',
	},
	{
		key: 'adas_rapid_deceleration',
		title: '急减速',
	},
	{
		key: 'adas_rapid_turn',
		title: '急转弯',
	},
	{
		key: 'adas_lane_departure_warning',
		title: '车道偏离',
	},
	{
		key: 'adas_distance_closer_warning',
		title: '前车近距',
	},
	{
		key: 'adas_forward_collision_warning',
		title: '前车碰撞预警',
	},
	{
		key: 'dsm_fatigue_driving',
		title: '疲劳驾驶',
	},
	{
		key: 'dsm_phone_call',
		title: '打电话',
	},
	{
		key: 'dsm_smoking',
		title: '吸烟',
	},
	{
		key: 'dsm_emergency',
		title: '劫警',
	},
	{
		key: 'dsm_distraction',
		title: '分神驾驶',
	},
	{
		key: 'dsm_driver_abnormal',
		title: '驾驶员异常',
	},
	{
		key: 'storage_malfunction',
		title: '存储单元故障',
	},
	{
		key: 'gnss_malfunction',
		title: 'GNSS故障',
	},
]

export const NotificationTypes: NotificationType[] = [
	{
		id: 0,
		name: 'all',
		text: '全部',
		icon: ''
	},
	{
		id: 1,
		name: 'offline',
		text: '离线',
		icon: 'icon-offline',
		bgColor: '#fcb222',
		messageBox: 1,
		weChatBox: 2
	},
	{
		id: 2,
		name: 'low_power',
		text: '设备低电',
		icon: 'icon-low-power',
		bgColor: '#fcb222'
	},
	{
		id: 3,
		name: 'pull_out',
		text: '设备拔出',
		icon: 'icon-pull-out',
		bgColor: '#fcb222'
	},
	{
		id: 4,
		name: 'emergency',
		text: '紧急模式',
		icon: 'icon-alarm-lamp',
		bgColor: '#fcb222'
	},
	{
		id: 8,
		name: 'low_voltage',
		text: '车辆电瓶耗尽',
		icon: 'icon-low-battery',
		bgColor: '#fcb222'
	},
	{
		id: 9,
		name: 'long_stop',
		text: '长时间停留',
		icon: 'icon-park-time',
		bgColor: '#fcb222'
	},
	{
		id: 10,
		name: 'over_speed',
		text: '超速',
		icon: 'icon-over-speed',
		bgColor: '#fcb222'
	},
	{
		id: 11,
		name: 'light_sensed',
		text: '光感设备拔除',
		icon: 'icon-light',
		bgColor: '#fcb222'
	},
	{
		id: 61,
		name: 'acc_on',
		text: 'ACC 开启',
		icon: 'icon-acc-on',
		bgColor: '#fcb222'
	},
	{
		id: 62,
		name: 'acc_off',
		text: 'ACC 熄火',
		icon: 'icon-acc-off',
		bgColor: '#fcb222'
	},
	{
		id: 63,
		name: 'fence_in',
		text: '驶入围栏',
		icon: 'icon-fence-in',
		bgColor: '#fcb222'
	},
	{
		id: 64,
		name: 'fence_out',
		text: '驶出围栏',
		icon: 'icon-fence-out',
		bgColor: '#fcb222'
	},
	{
		id: 65,
		name: 'over_mileage_limit',
		text: '长距离行驶',
		icon: 'icon-mileage-limit',
		bgColor: '#fcb222'
	},
	{
		id: 66,
		name: 'device_remove_risk',
		text: '设备拆除风险',
		icon: 'icon-device-remove-risk',
		bgColor: '#fcb222'
	},
	{
		id: 67,
		name: 'light_remove_risk',
		text: '感光拆除风险',
		icon: 'icon-light-remove-risk',
		bgColor: '#fcb222'
	},
  //v1.3.5新增视频设备告警
	{
		id: 68,
		name: 'adas_rapid_acceleration',
		text: '急加速',
		icon: 'icon-kuaishangjiasudu',
		bgColor: '#fcb222'
	},
	{
		id: 69,
		name: 'adas_rapid_deceleration',
		text: '急减速',
		icon: 'icon-manxiajiansudu',
		bgColor: '#fcb222'
	},
	{
		id: 70,
		name: 'adas_rapid_turn',
		text: '急转弯',
		icon: 'icon-zhuanwan',
		bgColor: '#fcb222'
	},
  {
		id: 71,
		name: 'adas_forward_collision_warning',
		text: '前车碰撞预警',
		icon: 'icon-jiaotonglukuang_huaban',
		bgColor: '#fcb222'
	},
	{
		id: 72,
		name: 'adas_lane_departure_warning',
		text: '车道偏离',
		icon: 'icon-icon_chedaopianliyujingxitong',
		bgColor: '#fcb222'
	},
	{
		id: 73,
		name: 'adas_distance_closer_warning',
		text: '前车近距',
		icon: 'icon-front-car-close-rang',
		bgColor: '#fcb222'
	},
	{
		id: 74,
		name: 'dsm_fatigue_driving',
		text: '疲劳驾驶',
		icon: 'icon-fatigue-driving',
		bgColor: '#fcb222'
	},
	{
		id: 75,
		name: 'dsm_phone_call',
		text: '打电话',
		icon: 'icon-jiedianhua',
		bgColor: '#fcb222'
	},
	{
		id: 76,
		name: 'dsm_smoking',
		text: '吸烟',
		icon: 'icon-yan',
		bgColor: '#fcb222'
	},
  {
		id: 77,
		name: 'dsm_distraction',
		text: '分神驾驶',
		icon: 'icon-distracted-driving',
		bgColor: '#fcb222'
	},
  {
		id: 78,
		name: 'dsm_driver_abnormal',
		text: '驾驶员异常',
		icon: 'icon-jiashiyuan',
		bgColor: '#fcb222'
	},
	{
		id: 81,
		name: 'dsm_emergency',
		text: '劫警',
		icon: 'icon-rob-alarm_line',
		bgColor: '#fcb222'
	},
  {
		id: 82,
		name: 'gnss_malfunction',
		text: 'GNSS故障',
		icon: 'icon-cgbj_icon_gnsstxdl',
		bgColor: '#fcb222'
	},
	{
		id: 83,
		name: 'storage_malfunction',
		text: '存储单元故障',
		icon: 'icon-guzhangshebei',
		bgColor: '#fcb222'
	},
]

export const WorkModeColor = {
	0: 'green',
	1: 'orange',
	2: 'red'
}

/**
 * devStatus 设备运转状态
 */
export const devStatus = ['工作正常', '异常离线', '电量耗尽', '电量低于20%', '设备拔出', '设备未开机', '电量未知']

export const markerType = {
	Offline: 0,
	Normal: 1,
	ToBeEmergent: 2,
	IsEmergent: 3
}

export const StorageLabels = {
	DevID: 'wcg_devid',
	isLoggedIn: 'wcg_isLoggedIn',
	AppSession: 'wcg_session',
	RememberMe: 'wcg_remember_me',
	ShouldRememberMe: 'wcg_should_remember_me',
	UID: 'wcg_uid',
	upgradeSlideViewed: 'wcg_upgrade_slide_viewed',
	LoginFailed: 'wcg_login_failed'
}

export const MaxSpyLimit = 500

export const AddressRequestingSymbol = null

// 百度统计事件汇总
export const AnalyticsEvents = {
	// 全局搜索
	GlobalSearch: 'GlobalSearch',
	// 实时信息：定位更新
	Spy_RefreshLocation: 'Spy_RefreshLocation',
	// 实时信息：远程跟踪
	Spy_Trace: 'Spy_Trace',
	// 实时信息：车辆轨迹回放
	Spy_TrackReplay: 'Spy_TrackReplay',
	// 实时信息：车辆信息
	Spy_VehicleInfo: 'Spy_VehicleInfo',
	// 实时信息：设置工作模式
	Spy_SetWorkMode: 'Spy_SetWorkMode',
	// 实时信息：辅助定位
	Spy_AuxiliaryLocation: 'Spy_AuxiliaryLocation',
	// 实时信息：清空关注列表
	Spy_CleanAll: 'Spy_CleanAll',
	// 实时信息：清空单个关注车辆
	Spy_CleanSingle: 'Spy_CleanSingle',
	// 回到旧版
	Back_OldVersion: 'Back_OldVersion'
}

export const FootprintType = {
	spy: '关注车辆',
	tracing: '单车跟踪'
}

/**
 * @name CarStatus 车辆运动状态
 * @constant
 */
export enum VehicleStatus {
	Unknown = 0,
	Stop = 1,
	Run = 2
}

export enum VehicleMoveStatus {
	Unknown = -1,
	Offline = 0,
	Stop = 1,
	SlowSpeed = 2,
	Normal = 3,
	OverSpeed = 4
}

export const plateColors = [
	{ name: '普通蓝牌', value: 1 },
	{ name: '普通黄牌', value: 2 },
	{ name: '新能源黄', value: 22 },
	{ name: '其他黄牌', value: 29 },
	{ name: '普通黑牌', value: 3 },
	{ name: '港澳黑牌', value: 32 },
	{ name: '其他黑牌', value: 39 },
	{ name: '军警车牌', value: 4 },
	{ name: '新能源绿', value: 5 },
	{ name: '农用车牌', value: 51 },
	{ name: '未知类型', value: 9 },
	{ name: '残疾人车', value: 91 },
	{ name: '普通摩托', value: 97 }
]

export const SpyListOnlineCascaderOptions = [
	{
		label: '功能类型',
		value: 'funcType',
		children: [
			{
				label: '远程开关锁、寻车',
				value: 'carControl'
			},
			{
				label: '远程断/供油',
				value: 'fuelControl'
			},
      {
        label: '视频监控',
        value: 'monitor'
      },
		]
	},
	{
		label: '车辆状态',
		value: 'carStatus',
		children: [
			{
				label: '行驶状态',
				value: 'moveStatus',
				children: [
					{
						label: '正常行驶',
						value: 'normalMove'
					},
					{
						label: '超速行驶',
						value: 'overSpeedMove'
					}
				]
			},
			{
				label: '停留状态',
				value: 'stopStatus',
				children: [
					{
						label: '停留超过1小时',
						value: 'stopOverOneHour'
					},
					{
						label: '停留超过3小时',
						value: 'stopOverThreeHour'
					},
					{
						label: '停留超过1天',
						value: 'stopOverOneDay'
					},
					{
						label: '停留超过3天',
						value: 'stopOverThreeDays'
					},
					{
						label: '停留超过7天',
						value: 'stopOverSevenDays'
					}
				]
			},
			{
				label: '油路状态',
				value: 'fuelStatus',
				children: [
					{
						label: '断油',
						value: 'fuelCutOff'
					},
					{
						label: '供油',
						value: 'fuelSupply'
					}
				]
			}
		]
	},
	{
		label: '设备状态',
		value: 'deviceType',
		children: [
			{
				label: '正常状态',
				value: 'deviceNormal'
			},
			{
				label: '低电状态',
				value: 'deviceLowPower'
			},
			{
				label: '待紧急模式',
				value: 'toBeEmergent'
			},
			{
				label: '紧急模式',
				value: 'isEmergent'
			}
		]
	}
]

export const SpyListOfflineCascaderOptions = [
  {
		label: '功能类型',
		value: 'funcType',
		children: [
			{
				label: '远程开关锁、寻车',
				value: 'carControl'
			},
			{
				label: '远程断/供油',
				value: 'fuelControl'
			},
      {
        label: '视频监控',
        value: 'monitor'
      },
		]
	},
	{
		label: '车辆状态',
		value: 'carStatusOffline',
		children: [
			{
				label: '离线天数',
				value: 'offlineStatus',
				children: [
					{
						label: '离线超过1天',
						value: 'offlineOverOneDay'
					},
					{
						label: '离线超过3天',
						value: 'offlineOverThreeDays'
					},
					{
						label: '离线超过7天',
						value: 'offlineOverSevenDays'
					}
				]
			},
			{
				label: '油路状态',
				value: 'fuelStatus',
				children: [
					{
						label: '断油',
						value: 'fuelCutOff'
					},
					{
						label: '供油',
						value: 'fuelSupply'
					}
				]
			}
		]
	},
	{
		label: '设备状态',
		value: 'deviceTypeOffline',
		children: [
			{
				label: '异常离线',
				value: 'deviceErrOffline'
			},
			{
				label: '设备拔出',
				value: 'deviceUnplugging'
			},
			{
				label: '设备未开机',
				value: 'deviceOff'
			},
			{
				label: '电量耗尽',
				value: 'deviceNoPbat'
			}
		]
	}
]

export const CarControlType = {
  'C2':'开锁',
  'C3':'关锁',
  'C6':'闪灯寻车',
  'C7':'喇叭寻车',
  'C9': '油路立即闭合（强制）',
  'C10': '油路立即断开',
  'C15': '油路安全断开',
  'C16': '暗锁控制油路闭合',
  'C17': '暗锁控制油路安全断开',
  'C18': '暗锁控制油路立即断开（强制）',
}
