import request from '@/services/request'
import { ApiAddress } from '@/services/apis'
import { getOverSpeed } from '@/services/user/user.service'
import {
	Group,
	Vehicle,
	VehicleCloudControlRequest,
	VehicleException,
	VehicleLabel,
	VehicleTerminalsPositions,
	VehicleUpdateRequest
} from '@/services/vehicle/vehicle.model'
import { DeviceStatus, FuelStatus, Terminal } from '@/services/terminal/terminal.model'
import { calcTerminalExtends } from '@/services/terminal/terminal.service'
import getAppConfig from '@/env-configs'
import { PositionInfo } from '@/services/modes'
import { isZeroPoint } from '@/services/vehicle/vehicle-helper'
import { countBy, isNumber } from 'lodash-es'
import { ColorStatus, VehicleMoveStatus, VehicleStatus } from '@/utils/constants'
import { deviceType } from '@/utils/utils'

export function getGroups() {
	return request.get(ApiAddress.VEHICLE_GROUPS).then((data: any) => {
		if (!Array.isArray(data)) {
			data = [data]
		}

		return <Group[]>data
	})
}

export function addGroup(name: string, pid = '0') {
	return request.post(ApiAddress.GROUPS, { pid, group_name: name })
}

export function updateGroup(id: string, name: string) {
	return request.put(ApiAddress.GROUP, { group_name: name }, { params: { id } })
}

export function moveVehicles(ids: string[], pid: string) {
	return request.put(ApiAddress.CORP_TREE, { ids, pid, type: 1 })
}

export function deleteGroup(id: string) {
	return request.delete(ApiAddress.GROUP, { params: { id } })
}

export function getVehiclesByIds(ids: string[]): Promise<Vehicle[]> {
	return request.post<Vehicle[]>(ApiAddress.VEHICLES, { ids }).then((vehicles: any) => {
		return vehicles.map((vehicle: Vehicle) => {
			return vehiclePipe(vehicle)
		})
	})
}

export function getVehicleById(id: string): Promise<Vehicle> {
	return request.get<Vehicle>(ApiAddress.VEHICLE, { params: { id } }).then((vehicle: any) => {
		return vehiclePipe(vehicle)
	})
}

export function getVehiclePositions(datetime: string | undefined): Promise<PositionInfo> {
	return request.get<PositionInfo[]>(ApiAddress.VEHICLE_UPDATE_POSITIONS, { params: { datetime } }).then((position: any) => {
		return position
	})
}

export function updateVehicle(id: string, body: VehicleUpdateRequest) {
	return request.put<Vehicle>(ApiAddress.VEHICLE, body, { params: { id } })
}

export function controlCloudVehicle(id: string, body: VehicleCloudControlRequest) {
	return request.put(ApiAddress.CONTROL_CLOUD_VEHICLE, body, { params: { id } })
}

export function updateVehiclesInfo() {
	return request.put(ApiAddress.UPDATES_VEHICLES_INFO)
}

export function batchUpdateVehicles(upload_file: File[], gid: string) {
	let formData = new FormData()
	formData.append('gid', gid)
	formData.append('upload_file', upload_file[0])

	return request.post(ApiAddress.UPDATES_VEHICLES_INFO, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export function exportBatchEdit(gid?: string) {
	return request.get(ApiAddress.EXPORT_BATCH_EDIT, { params: { gid } })
}

export function getVehicleTerminalsPositions(id: string) {
	return request
		.get(ApiAddress.VEHICLE_ALL_POSITIONS, { params: { id } })
		.then((res: any) => {
			return <VehicleTerminalsPositions>res
		})
		.then(res => {
			const terminals = res.terminals.map(t => {
				if (t.base_station) {
					t.base_station = decodePosition(t.base_station)
				}
				if (t.wifi) {
					t.wifi = decodePosition(t.wifi)
				}
				if (t.gps) {
					t.gps = decodePosition(t.gps)
				}

				return t
			})
			res.terminals = terminals

			return res
		})
}

export function getFuelCutPermission() {
	return request.get(ApiAddress.FUEL_SMS_VALIDATION)
}

export function decodePosition(position: PositionInfo) {
	const lat = position.latitude / 3600000
	const lng = position.longitude / 3600000
	const appConfig = getAppConfig()
	const [trueLng, trueLat] = appConfig.coordTransform(lng, lat)
	position.latitude = trueLat
	position.longitude = trueLng

	return position
}

export function vehiclePipe(vehicle: Vehicle) {
	const { position } = vehicle
	const lat = position.latitude / 3600000
	const lng = position.longitude / 3600000
	const appConfig = getAppConfig()
	const [trueLng, trueLat] = appConfig.coordTransform(lng, lat)
	position.latitude = trueLat
	position.longitude = trueLng

	return calcVehicleExtendProps(vehicle)
}

export function calcVehicleExtendProps(vehicle: Vehicle) {
	vehicle.icon = vehicle.icon || 4
	vehicle.isEmptyPos = isZeroPoint(vehicle.position)
	vehicle.terminals = vehicle.terminals.map((t: Terminal) => calcTerminalExtends(t))
	vehicle.vehicleStatus = calcVehicleStatus(vehicle)
	vehicle.lightDevices = calcLightDevices(vehicle)
	vehicle.carStatus = calcCarStatus(vehicle)
	vehicle.fuelDevice = calcFuelDevice(vehicle)
	if (vehicle.fuelDevice) {
		vehicle.fuelDevice.fuel_status = setFuelStatus(vehicle.fuelDevice)
	}
	const [status, statusChangeTime] = calcStatus(vehicle)
	// 停留，行驶
	vehicle.status = status
	vehicle.statusChangeTime = statusChangeTime
	vehicle.accDevice = findAccDevice(vehicle.terminals)
	vehicle.color = calculateCarColor(vehicle.terminals)
	vehicle.shadowColor = calculateCarShadowColor(vehicle.terminals)
	vehicle.login = calcCarLogin(vehicle.terminals)
	vehicle.disconnect = vehicle.terminals.some(t => (t.alarm & 8) === 8)
	vehicle.exceptions = calcCarException(vehicle)
	vehicle.sportStatus = calcVehicleSportStatus(vehicle)
	vehicle.workModeDevice = vehicle.terminals.find(dev => (dev.match ? dev.work_mode === 2 : dev.work_mode! >= 0))
	vehicle.gps = calcVehicleGps(vehicle.terminals)
	vehicle.onlyWireless = calcCarWirelessDevices(vehicle.terminals)
  vehicle.posSn = calcVehiclePosSn(vehicle)
	return vehicle
}

function calcVehiclePosSn(vehicle: Vehicle) {
  let sn = '-'
  let type = '-'
  const tid = vehicle.position.tid || ''
  if(tid) {
    const t = vehicle.terminals.find(t => t.tid == tid)
    sn = t?.sn || '-'
    type = t?.firmware_version ? deviceType(t.firmware_version) : '-'
  }
	return `${sn}(${type})`
}

function calcCarWirelessDevices(devices: Terminal[]) {
	return devices.every(t => t.wireless && [0, 1].includes(t.device_mode))
}

function calcVehicleGps(devices: Terminal[]) {
	return devices.reduce((prev, next) => (prev > next.gps ? prev : next.gps), 0)
}

function calcVehicleStatus(vehicle: Vehicle): DeviceStatus {
	const tStatus = vehicle.terminals.map(item => item.deviceStatus)
	if (tStatus.includes(DeviceStatus.IsEmergent)) {
		return DeviceStatus.IsEmergent
	} else if (tStatus.includes(DeviceStatus.ToBeEmergent)) {
		return DeviceStatus.ToBeEmergent
	} else if (tStatus.includes(DeviceStatus.Normal)) {
		return DeviceStatus.Normal
	}

	return DeviceStatus.Offline
}

function calcLightDevices(vehicle: Vehicle) {
	return vehicle.terminals.filter(dev => ['V', 'W', 'Z', 'R'].includes(dev.firmware_version))
}

function calcCarStatus(vehicle: Vehicle) {
	if (vehicle.carStatus) {
		return vehicle.carStatus
	}
	const {
		driver_front_door,
		copilot_front_door,
		driver_back_door,
		copilot_back_door,
		door_lock,
		door_window_lf,
		door_window_rf,
		door_window_lb,
		door_window_rb,
		car_key,
		trunk,
		low_beam,
		high_beam,
		position_lamp,
		emergency_lamp,
		foglight_front,
		foglight_back,
		total_mileage,
		speed,
		oil,
		rmp,
		brake,
		parking,
		mileage
	} = vehicle

	return {
		driver_front_door,
		copilot_front_door,
		driver_back_door,
		copilot_back_door,
		door_lock,
		door_window_lf,
		door_window_rf,
		door_window_lb,
		door_window_rb,
		car_key,
		trunk,
		low_beam,
		high_beam,
		position_lamp,
		emergency_lamp,
		foglight_front,
		foglight_back,
		total_mileage,
		speed,
		oil,
		rmp,
		brake,
		parking,
		mileage
	}
}

function calcFuelDevice(vehicle: Vehicle) {
	const devices = vehicle.terminals

  return devices.find(item => ['ZJ220R', 'IV100', 'ZJ220F(R)', 'ZJ300M', 'ZJ300F'].includes(item.full_type!))
}

function calcStatus(vehicle: Vehicle) {
  // 无位置返回未知
  const terminals = vehicle.terminals
  const deviceList = terminals.sort((a, b) =>
    b.status_change_time - a.status_change_time
  )
  if (vehicle.isEmptyPos) {
    return [VehicleStatus.Unknown, 0]
  }

  for (const device of deviceList) {
    if (device.status !== 0) {
      return [ device.status, device.status_change_time ]
    }
  }

  return [VehicleStatus.Unknown, 0]
}

function setFuelStatus(device: Terminal) {
	if (!device.fuel_cut) {
		return 0 // 通油
	}

	const cuted = device.fuel_cut.filter(item => item.status === 1)

	return cuted.length > 0 ? 1 : 0
}

function findAccDevice(devices: Terminal[]) {
	return devices.filter(item => item.acc_switch === 1).find(item => isNumber(item.acc))
}

/**
 * 根据车辆的设备的状态来判断,只有警示和普通两种状态
 * 车辆内有设备处于待紧急、紧急、离线、设备拔出四种状态之一，车辆为警示
 *
 * @param {Array} devices
 * @returns {string}
 */
function calculateCarColor(devices: Terminal[]) {
	const isWarning = devices.some(device => device.device_mode > 0 || device.login === 0 || device.status === 4)

	if (isWarning) {
		return ColorStatus.ToBeEmergent
	}

	return ColorStatus.Normal
}

function calcCarLogin(devices: Terminal[]) {
	return devices.some(device => device.login > 0)
}

/**
 * 计算车辆影子颜色，根据其设备的颜色来归类，优先级为：红 -> 橙 -> 灰 -> 绿
 * 用来标记汇总车辆下设备的总体状态
 *
 * @param {Array} devices
 * @returns {String}
 */
function calculateCarShadowColor(devices: Terminal[]) {
	const counts = countBy(devices, 'color')
	if (counts[ColorStatus.IsEmergent]) {
		return ColorStatus.IsEmergent
	} else if (counts[ColorStatus.ToBeEmergent]) {
		return ColorStatus.ToBeEmergent
	} else if (counts[ColorStatus.Normal]) {
		return ColorStatus.Normal
	}

	return ColorStatus.Offline
}

/**
 * 异常情况包括
 *  超速
 *  断油
 *  紧急模式
 *  待紧急模式
 *  设备拔出
 *  能否登陆 （新设备一直不能上线。上线时间为0， 废弃）
 * @param vehicle
 */

function calcCarException(vehicle: Vehicle) {
	const exceptions: VehicleException[] = []
	if (!vehicle.login) {
		return exceptions
	}
	if (vehicle.position.speed > Number(getOverSpeed())) {
		exceptions.push({
			priority: 0,
			name: VehicleLabel.OverSpeed
		})
	}

	if (
		vehicle.fuelDevice &&
		vehicle.fuelDevice.fuel_control_switch > 0 &&
		vehicle.fuelDevice.fuel_status === FuelStatus.FuelDisconnect
	) {
		exceptions.push({
			priority: 0,
			name: VehicleLabel.FuelCut
		})
	}

	if (vehicle.terminals.some(t => t.device_mode === 2)) {
		exceptions.push({
			priority: 0,
			name: VehicleLabel.Emergency
		})
	}

	if (vehicle.terminals.some(t => t.device_mode === 1)) {
		exceptions.push({
			priority: 0,
			name: VehicleLabel.ToBeEmergency
		})
	}

	if (vehicle.disconnect) {
		exceptions.push({
			priority: 0,
			name: VehicleLabel.Disconnect
		})
	}

	return exceptions
}

function calcVehicleSportStatus(vehicle: Vehicle) {
	// 停留
	if (!vehicle.login) {
		return VehicleMoveStatus.Offline
	}
	if (vehicle.status === 1) {
		return VehicleMoveStatus.Stop
	}

	// 移动
	if (vehicle.status === 2) {
		const { position } = vehicle
		const speed = position.speed

		if (speed <= Number(getOverSpeed())) {
			return VehicleMoveStatus.Normal
		}
		if (speed > Number(getOverSpeed())) {
			return VehicleMoveStatus.OverSpeed
		}
	}

	return VehicleMoveStatus.Normal
}
