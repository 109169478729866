import ExportContent from '@/layouts/HomeLayout/HeaderMenu/ExportContent'
import { ModalForm } from '@ant-design/pro-form'
import { Form, Radio } from 'antd'
import { memo } from 'react'
import './ExportContent'
import { MenuItem } from '@/layouts/HomeLayout/HeaderMenu/menu'

export interface AccountDataExportProps {
	navInfo: MenuItem
}

export const AccountDataExport = memo((props: AccountDataExportProps) => {
	const { navInfo } = props
	const [form] = Form.useForm()

	return (
		<ModalForm
			form={form}
			onVisibleChange={v => {
				form.resetFields()
			}}
			initialValues={{ type: 'abnormal', export_fields: ['sn', 'cnum', 'vin'] }}
			trigger={<span>{navInfo.title}</span>}
			title='账户数据导出'
			onFinish={async values => {
				values = { ...values, link_to: 'zlb' }
				const searchParams = new URLSearchParams(values)
				window.open(`/features/exports?${searchParams}`)

				return true
			}}
			width={550}
			layout={'horizontal'}
			labelCol={{ span: 4 }}
		>
			<Form.Item label='数据类型' name='type'>
				<Radio.Group>
					<Radio.Button value='abnormal'>异常</Radio.Button>
					<Radio.Button value='normal'>正常</Radio.Button>
					<Radio.Button value='all'>全部</Radio.Button>
				</Radio.Group>
			</Form.Item>

			<Form.Item label='导出内容' name='export_fields'>
				<ExportContent />
			</Form.Item>
		</ModalForm>
	)
})
