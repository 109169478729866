import { updateCorporation } from '@/services/corporation/corporation.service'
import { selectCorp, setCorp } from '@/store/corporation/corporation.slice'
import { ModalForm } from '@ant-design/pro-form'
import { Form, Menu, Radio } from 'antd'
import { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const AccountSetting = memo<{}>(props => {
	const [form] = Form.useForm()
	const [isOpenModal, setIsOpenModal] = useState(false)
	const { corpInfo } = useSelector(selectCorp)
	const dispatch = useDispatch()

	return (
		<>
			<Menu.Item
				{...props}
				onClick={() => {
					setIsOpenModal(true)
					form.setFieldsValue({ name_show: corpInfo?.name_show, car_show: corpInfo?.car_show })
				}}
			>
				账号设置
			</Menu.Item>

			<ModalForm
				form={form}
				visible={isOpenModal}
				onVisibleChange={visible => {
					setIsOpenModal(visible)
				}}
				title='账号设置'
				onFinish={async value => {
					const params = { ...value, corp_name: corpInfo!.corp_name, car_show: value.car_show }
					await updateCorporation(params)
					dispatch(setCorp(params))
					window.location.reload()

					return true
				}}
				width={500}
				layout={'horizontal'}
				labelCol={{ span: 6 }}
			>
				<Form.Item label='车辆优先显示' style={{ marginBottom: 0 }} name='name_show'>
					<Radio.Group>
						<Radio value={2}>车牌号</Radio>
						<Radio value={1}>车架号</Radio>
					</Radio.Group>
				</Form.Item>

				<Form.Item label='车辆展示方式' style={{ marginBottom: 0 }} name='car_show'>
					<Radio.Group>
						<Radio value={1}>全部展示</Radio>
						<Radio value={2}>按选中展示</Radio>
					</Radio.Group>
				</Form.Item>
			</ModalForm>
		</>
	)
})

export default AccountSetting
