import { getAddress } from '@/services/geo-address/geo-address.service'
import { useRequest } from 'ahooks'
import { memo, useEffect, useState } from 'react'
// @ts-ignore
import coordtransform from 'coordtransform'

export interface AMapAddressProps {
	latitude: number
	longitude: number
	auto?: boolean
	onChange?: (address: string) => void
}
const AMapAddress = memo((props: AMapAddressProps) => {
	const { latitude, longitude, auto = true } = props
	const [address, setAddress] = useState('正在获取地址...')
	const { run } = useRequest(getAddress, {
		manual: true,
		onSuccess: data => {
			setAddress(data?.[0].address || '未获取到位置信息')
			if (props.onChange) {
				props.onChange(data[0].address)
			}
		}
	})

	useEffect(() => {
		const wgs84 = coordtransform.gcj02towgs84(longitude, latitude)
		run(wgs84[1] + ',' + wgs84[0])
	}, [props])

	return <span>{address}</span>
})

export default AMapAddress
