import { replaySlice } from '@/pages/TripReplay/replay.slice'
import { corpSlice } from '@/store/corporation/corporation.slice'
import { AnyAction, CombinedState, combineReducers, configureStore } from '@reduxjs/toolkit'
import { userSlice } from './user/user.slice'
import { vehicleSlice } from '@/store/vehicle/vehicle.slice'
import { notificationSlice } from '@/store/notification/notification.slice'
import { siteSlice } from '@/store/site.slice'
import { reportSlice } from './reports/reports.slice'
import { monitorSlice } from './monitor/monitor.slice'

export const allReducers = {
	user: userSlice.reducer,
	vehicleState: vehicleSlice.reducer,
	replay: replaySlice.reducer,
	notification: notificationSlice.reducer,
	site: siteSlice.reducer,
	corp: corpSlice.reducer,
	report: reportSlice.reducer,
	monitor: monitorSlice.reducer,
}

export function logoutAction() {
	return {
		type: 'LOGOUT'
	}
}

const combinedReducer = combineReducers(allReducers)

const rootReducer = (state: CombinedState<any>, action: AnyAction) => {
	if (action.type === 'LOGOUT') {
		return combinedReducer({} as any, action)
	}

	return combinedReducer(state, action)
}

export const store = configureStore({
	reducer: rootReducer
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
