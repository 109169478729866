import { ProviderContext } from '@/ProviderContext'
import dayjs from 'dayjs'
import ReactDOM from 'react-dom'
import App from './App'
import 'dayjs/locale/zh'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)
dayjs.locale('zh')
ReactDOM.render(
	<ProviderContext>
		<App />
	</ProviderContext>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
