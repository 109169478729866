import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import { getVehiclePositions } from '@/services/vehicle/vehicle.service'
import { updateVehicleByUdMap } from '@/store/vehicle/vehicle.slice'
import { setMapVehiclePositions } from './updateMap'
import { useRequest } from 'ahooks'
import { PositionInfo } from '@/services/modes'
import { isZeroPoint } from '@/services/vehicle/vehicle-helper'

export default function useUpdateVehicleData() {
	const dispatch = useDispatch()
	const vehicles = useSelector((state: RootState) => state.vehicleState.vehicles)
	const [datetime, setDatetime] = useState<number>()
	const [initRequest, setInitRequest] = useState<boolean>(false)
	const { run, cancel } = useRequest(() => getVehiclePositions(datetime ? String(datetime) : undefined), {
		manual: true,
		pollingInterval: 3000,
		pollingWhenHidden: false,
		onSuccess: (data: PositionInfo[]) => {
			const date = datetime ? datetime + 3 : parseInt((new Date().getTime() / 1000) as any)
			setDatetime(date)
			data.forEach((position: PositionInfo, index: number) => {
				position.locate_time = position.timestamp!
				if (isZeroPoint(position)) return
				setMapVehiclePositions(position, vehicles)
				if (index === data.length - 1) {
					dispatch(updateVehicleByUdMap())
				}
			})
		},
		onError: (err: any) => {
			console.log('定时拉取定位信息失败，失败信息：', err)
		}
	})

	useEffect(() => {
		if (vehicles.length > 0 && !initRequest && !window.location.pathname.includes('/features/trace')) {
			setInitRequest(true)
			run()
		}
	}, [vehicles])
}
