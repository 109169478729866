import { GetAddress } from '@/services/apis'
import request from '@/services/request'

export function getAddress(location: string): Promise<any> {
	return request
		.get(GetAddress, {
			params: {
				coordtype: 'wgs84',
				location
			},
			timeout: 3000
		})
		.catch(err => {
			console.log('请求超时', err)
		})
}

export function getBatchTripAddress(locations: string[]): Promise<any[]> {
	let baseParams = `coordtype=wgs84`

	locations.forEach(lct => {
		baseParams += `&location=${lct}`
	})

	const params = new URLSearchParams(baseParams)
	return request
		.get(GetAddress, {
			params: params,
			timeout: 3000
		})
		.then((res: any) => {
			return res
		})
		.catch(err => {
			console.log('请求超时', err)
		})
}
