import styled from '@emotion/styled'
import { Layout } from 'antd'

const headerHeight = 48

export const HomeHeader = styled(Layout.Header)`
	height: ${headerHeight}px;
	line-height: ${headerHeight}px;
	width: 100%;
	background: ${props => props.theme['primary-color']};
	color: #fff;
	position: relative;
	z-index: 10;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 16px;
	box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
`

export const HeaderLeft = styled.div`
	flex: auto;
	display: flex;
	align-items: center;

	.ant-menu {
		flex: auto;
	}
`

export const UserInfo = styled.span`
	display: flex;
	align-items: center;
	height: 100%;
	padding: 0 8px;
	cursor: pointer;
	transition: all 0.3s;
	min-width: 120px;

	.username {
		margin-left: 10px;
	}

	&:hover {
		background: rgba(0, 0, 0, 0.025);
	}
`

export const LayoutContent = styled(Layout.Content)`
	height: calc(100vh - ${headerHeight}px);
`
