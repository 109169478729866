import { exportInstallData } from '@/services/auth/auth.service'
import { timeRangeRule } from '@/utils/utils'
import { ModalForm } from '@ant-design/pro-form'
import { Form } from 'antd'
import { memo, useState } from 'react'
import { MenuItem } from '@/layouts/HomeLayout/HeaderMenu/menu'
import SelfDatePicker from '@/components/SelfDatePicker'
import { Max6Month } from '@/utils/constants'

export interface InstallDataExportProps {
	navInfo: MenuItem
}

export const InstallDataExport = memo<InstallDataExportProps>(props => {
	const { navInfo } = props
	const [form] = Form.useForm()
	const [isDisable, setIsDisable] = useState(false)

	return (
		<ModalForm
			form={form}
			title='安装数据导出'
			width={500}
			dateFormatter={false}
			layout={'horizontal'}
			labelCol={{ span: 4 }}
			modalProps={{
				destroyOnClose: true
			}}
			trigger={<span>{navInfo.title}</span>}
			submitter={{
				submitButtonProps: { disabled: isDisable }
			}}
			onFinish={async values => {
				const { start_time, end_time } = values.timeRange
				const times = { start_time, end_time }
				exportInstallData({ ...times, link_to: 'zlb' }).then()

				return true
			}}
		>
			<Form.Item label='安装时间' name='timeRange' rules={[timeRangeRule(Max6Month, '导出')]}>
				<SelfDatePicker
					displayTime={true}
					showToday={true}
					onChange={value => {
						setIsDisable(value.isDisable!)
						if (value.end_time - value.start_time > Max6Month * 24 * 3600) {
							setIsDisable(true)
						}
					}}
				/>
			</Form.Item>
		</ModalForm>
	)
})
