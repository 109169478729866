import { useAMapEvents } from '@/libs/react-amap/components/common/hooks'
import { OverlayPropsEvents } from '@/libs/react-amap/types'
import { useEffect, useImperativeHandle, useState, ReactElement, forwardRef } from 'react'
import ReactDOM from 'react-dom'

export const InfoWindowEvents = ['onChange', 'onOpen', 'onClose'] as const

type InfoWindowEventsProps = typeof InfoWindowEvents[number]

export type InfoWindowProps = AMap.InfoWindow.Options &
	OverlayPropsEvents<InfoWindowEventsProps> & {
		map?: AMap.Map
		element: ReactElement | null
		minWidth?: string
		autoMove?: boolean
		toggleWindow?: boolean
	}

export const AMapInfoWindow = forwardRef<AMap.InfoWindow, InfoWindowProps>((options, ref) => {
	const { map, element, position, toggleWindow, ...others } = options
	const [infoWindow, setInfoWindow] = useState<AMap.InfoWindow | null>(null)
	const [container] = useState(document.createElement('div'))

	const resolveContent = (element: ReactElement | null) => {
		container.style.padding = '5px 0px'
		container.style.minWidth = others.minWidth || '300px'
		ReactDOM.render(element ? element : <></>, container)

		return container
	}

	useEffect(() => {
		if (!map) return
		let instance: AMap.InfoWindow
		if (!infoWindow) {
			instance = new AMap.InfoWindow({ ...others, content: resolveContent(element) })
			instance.open(map, position)
			setInfoWindow(instance)
		}

		return () => {
			if (instance) {
				instance.setMap(null)
				setInfoWindow(null)
			}
		}
	}, [])

	useEffect(() => {
		infoWindow?.setContent(resolveContent(element))
	}, [element])

	useEffect(() => {
		infoWindow?.setPosition(position!)
		if (toggleWindow && position) {
			const poi: [number, number] = position as [number, number]
			const windowCenter = new AMap.LngLat(poi[0], poi[1])
			infoWindow?.open(map!)
			map?.setCenter(windowCenter)
		}
	}, [position])

	useAMapEvents<AMap.InfoWindow>(infoWindow!, options, InfoWindowEvents)

	useImperativeHandle(ref, () => infoWindow!)

	return null
})
