import Restricted from '@/components/Restricted'
import AccountSetting from '@/layouts/HomeLayout/UserAction/AccountSetting'
import ChangePasswordMenu from '@/layouts/HomeLayout/UserAction/ChangePasswordMenu'
import { UserInfo } from '@/layouts/HomeLayout/style'
import UserFormMenu from '@/layouts/HomeLayout/UserAction/UserFormMenu'
import { logout } from '@/services/auth/auth.service'
import { selectUser } from '@/store/user/user.slice'
import styled from '@emotion/styled'
import { Avatar, Dropdown, Menu, Modal } from 'antd'
import { UserOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import erweima from '@/assets/erweima-home.png'
import { Version } from './style'

export default memo(function UserAction() {
	const { userInfo } = useSelector(selectUser)
	const navigate = useNavigate()
	const showLogoutConfirm = useCallback(() => {
		Modal.confirm({
			title: '退出登录',
			icon: <ExclamationCircleOutlined />,
			content: '确认退出？',
			onOk() {
				logout().then(() => {
					navigate('/entrance/login')
				})
			}
		})
	}, [])

	return (
		<>
			<Dropdown
				overlay={
					<Menu>
						<UserFormMenu key='userInfo' />
						<Restricted permission={'MainUser'}>
							<AccountSetting key='accountSetting' />
						</Restricted>
						<ChangePasswordMenu key='changePassword' />
						<Menu.SubMenu title={'APP下载'} key='download'>
							<Menu.Item key='QRCode'>
								<QRCode />
							</Menu.Item>
						</Menu.SubMenu>
						<Menu.Item key='docs'>
							<a href='https://doc.weixin.qq.com/doc/w3_ASUAPQYBALg2sq5m8yYTpSRtbVtmT?scode=AJUAPAcvAAkOVZMPTbASUAPQYBALg' target='_blank'>
								使用手册
							</a>
						</Menu.Item>
						<Menu.Item key='logout' onClick={showLogoutConfirm}>
							退出
						</Menu.Item>
						<Version>当前版本 V1.3.6</Version>
					</Menu>
				}
			>
				<UserInfo>
					<Avatar icon={<UserOutlined />} />
					<span className='username'>{userInfo?.alias || userInfo?.username}</span>
				</UserInfo>
			</Dropdown>
		</>
	)
})

export const QRCode = styled.div`
	width: 200px;
	height: 200px;
	background: url(${erweima}) no-repeat;
	background-size: 200px;
`
